import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "components/Common/TableContainer";
import { Phone } from "pages/Contacts/ContactList/contactlistCol";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";

import { getCompany, editMyCompany } from "helpers/API_hendlers";
import Breadcrumb from "components/Common/Breadcrumb";

const Campaign_Registration = (props) => {
  const [state, setState] = useState({
    isExistCompanyProfile: true,
    modalCreateNewCampaign: {
      isOpen: false,
      activeCampaingEdit: null,
    },
    company: {},
    loading: false,
    showAlert: {
      isShow: false,
      title: "Updated successfully",
      message: "",
      type: "success",
      icon: "mdi-information-outline",
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setState((pS) => ({ ...pS, loading: true }));
    getCompany(props.router.params.id)
      .then((r) => {
        console.log("getCompany", r);

        if (!r.company?.a2p?.brand?.legal_business_name) {
          setState((pS) => ({
            ...pS,
            isExistCompanyProfile: false,
            modalCreateNewCampaign: { isOpen: false, activeCampaingEdit: null },
            loading: false,
            showAlert: {
              isShow: true,
              message: "Please create Brand first",
              type: "warning",
              icon: "mdi-information-outline",
            },
          }));
          return;
        }

        setState((pS) => {
          const newState = {
            ...pS,
            modalCreateNewCampaign: { isOpen: false, activeCampaingEdit: null },
            company: r.company,
            isExistCompanyProfile: true,
            loading: false,
          };
          if (r?.company?.applied === 1) {
            newState.showAlert = {
              isShow: true,
              message:
                "The A2P profile form is being reviewed and you cannot change any details until the review is complete",
              type: "success",
              icon: "mdi-information-outline",
            };
          }
          return newState;
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.msg === "Cannot find company") {
          setState((pS) => ({
            ...pS,
            isExistCompanyProfile: false,
            loading: false,
          }));
        }
      });
  };

  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      A2P_brand: "",
      A2P_campaign_use_case: "Customer Care",
      messaging_service: "create_new",
      choose_existing_messaging_service: "",
      campaign_description: "",
      sample_message_1: "",
      sample_message_2: "",
      is_include_embedded_links: false,
      is_include_phone_numbers: false,
    },
    validationSchema: Yup.object({
      // A2P_brand: Yup.string().required("Required"),
      // A2P_campaign_use_case: Yup.object().required("Required"),
      // messaging_service: Yup.string().required("Required"),
      campaign_description: Yup.string().required("Required"),
      sample_message_1: Yup.string().required("Required"),
      sample_message_2: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit Campaign_registration", values);
      try {
        const payload = {
          a2p: {
            ...state.company.a2p,
            campaings: [...(state.company.a2p.campaings || [])],
          },
        };

        if (!state.modalCreateNewCampaign.activeCampaingEdit) {
          payload.a2p.campaings.push({
            ...values,
            id: values.A2P_brand + "_" + Date.now(),
          });
        } else {
          payload.a2p.campaings = payload.a2p.campaings.map((campaign) => {
            if (
              campaign.id === state.modalCreateNewCampaign.activeCampaingEdit
            ) {
              return { ...campaign, ...values };
            }
            return campaign;
          });
        }

        if (state.isExistCompanyProfile) {
          editMyCompany(payload, props.router.params.id)
            .then((r) => {
              console.log("EDITED campaign", r);

              getData();
              val.resetForm();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  });

  const submitToReview = () => {
    val
      .validateForm()
      .then((r) => {
        console.log(r);

        if (JSON.stringify(r) !== "{}") {
          let MS_ER = "";
          for (const key in r) {
            MS_ER += `${key}: "${r[key]}",  `;
          }
          setState((pS) => ({
            ...pS,
            showAlert: {
              isShow: true,
              title: "Some fields are not valide",
              message: MS_ER,
              type: "danger",
              icon: "mdi-block-helper",
            },
          }));
        } else {
          val.handleSubmit();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Campaign description",
        accessor: "campaign_description",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Sample message 1",
        accessor: "sample_message_1",

        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Sample message 2",
        accessor: "sample_message_2",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },

      {
        Header: "Include Links",
        accessor: "is_include_embedded_links",
        // filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Include Phone",
        accessor: "is_include_phone_numbers",
        // filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  console.log("cellProps", cellProps);

                  const valuesForSet = { ...val.values };
                  valuesForSet.A2P_brand =
                    state.company?.a2p?.brand?.legal_business_name;

                  const campaignDataById = { ...cellProps.row.original };

                  if (campaignDataById) {
                    for (const key in campaignDataById) {
                      valuesForSet[key] = campaignDataById[key];
                    }
                  }
                  val.setValues(valuesForSet);

                  setState((ps) => ({
                    ...ps,
                    modalCreateNewCampaign: {
                      ...ps.modalCreateNewCampaign,
                      isOpen: !ps.modalCreateNewCampaign.isOpen,
                      activeCampaingEdit: cellProps.row.original.id,
                    },
                  }));
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="editcamp" />
                <UncontrolledTooltip placement="top" target="editcamp">
                  Edit Campaign Registration form
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const onToggle = () => {
    setState((ps) => {
      if (ps.modalCreateNewCampaign.isOpen) {
        val.resetForm();
      }

      return {
        ...ps,
        modalCreateNewCampaign: {
          ...ps.modalCreateNewCampaign,
          isOpen: !ps.modalCreateNewCampaign.isOpen,
        },
      };
    });
  };

  return (
    <div
      className={`page-content ${
        state.showAlert.isShow &&
        state.showAlert.message === "Please create Brand first"
          ? "overlay_block"
          : ""
      }`}
    >
      <Container fluid>
        <Breadcrumb
          title="Registration Campaign"
          breadcrumbItems={props?.router?.location?.pathname}
        />
        {state.showAlert.isShow && (
          <Alert
            style={{ zIndex: 10000, display: "flex", position: "fixed" }}
            isOpen={state.showAlert.isShow}
            color={state.showAlert.type}
            className="alert-dismissible fade show"
            role="alert"
            toggle={() => {
              setState((pS) => ({
                ...pS,
                showAlert: {
                  ...state.showAlert,
                  isShow: !state.showAlert.isShow,
                },
              }));
            }}
          >
            <i className={`mdi ${state.showAlert.icon} me-2 font-size-20`}></i>
            <div>
              <h5>{state.showAlert.title}</h5>{" "}
              {state.showAlert.message && (
                <p className="mb-0">{state.showAlert.message}</p>
              )}
            </div>
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={state.company?.a2p?.campaings || []}
                  isGlobalFilter={true}
                  isAddOptions={"Register new campaign"}
                  handleOrderClicks={() => {
                    setState((ps) => ({
                      ...ps,
                      modalCreateNewCampaign: {
                        ...ps.modalCreateNewCampaign,
                        isOpen: !ps.modalCreateNewCampaign.isOpen,
                        activeCampaingEdit: null,
                      },
                    }));

                    const valuesForSet = { ...val.values };
                    valuesForSet.A2P_brand =
                      state.company?.a2p?.brand?.legal_business_name;
                    val.setValues(valuesForSet);
                  }}
                  customPageSize={10}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={state.modalCreateNewCampaign.isOpen} toggle={onToggle}>
          <ModalHeader toggle={onToggle} tag="h4">
            {!state.modalCreateNewCampaign.activeCampaingEdit
              ? "Create new "
              : "Edit "}{" "}
            A2P Campaign
          </ModalHeader>
          <ModalBody>
            <div className="mb-3 ">
              <Label className="form-label">
                <span className="red_star">*</span> A2P Brand
              </Label>
              <Input
                name="A2P_brand"
                className="form-control mb-3"
                placeholder="*"
                disabled
                type="text"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={val.values.A2P_brand || ""}
                invalid={
                  val.touched.A2P_brand && val.errors.A2P_brand ? true : false
                }
              />
              {val.touched.A2P_brand && val.errors.A2P_brand ? (
                <FormFeedback type="invalid">
                  {val.errors.A2P_brand}
                </FormFeedback>
              ) : null}
            </div>
            {/* <div className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Available A2P Campaign use
                cases
              </Label>
              <Select
                options={CAMPAIGN_REGISTRATION.available_A2P_campaign_use_case}
                onChange={(s) => val.setFieldValue("A2P_campaign_use_case", s)}
                value={val.values.A2P_campaign_use_case || ""}
                className="select2-selection"
                styles={{
                  menuList: (style) => ({
                    ...style,
                    backgroundColor: "white",
                  }),
                }}
              />
            </div> */}

            {/* <div className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Messaging Service
              </Label>
              <p className="font-size-11">
                Campaign will be linked to selected Messaging Service. 10DLC
                Phone numbers can be added to the Messaging Service after
                Campaign has been created.
              </p>
              <div className="">
                {CAMPAIGN_REGISTRATION.messaging_services.map((d) => (
                  <div key={d.label} className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="messaging_service"
                      checked={val.values.messaging_service === d.value}
                      id={d.value}
                      onChange={(s) =>
                        val.setFieldValue("messaging_service", d.value)
                      }
                    />
                    <label className="form-check-label" htmlFor={d.value}>
                      {d.label}
                    </label>
                    <p className="font-size-11">{d.description}</p>
                  </div>
                ))}
              </div>

              {val.values.messaging_service === "select_exist" && (
                <>
                  <Label className="form-label">
                    <span className="red_star">*</span> Existing Messaging
                    Service
                  </Label>
                  <Select
                    options={CAMPAIGN_REGISTRATION.existing_messaging_services}
                    onChange={(s) =>
                      val.setFieldValue("choose_existing_messaging_service", s)
                    }
                    value={val.values.choose_existing_messaging_service || ""}
                    className="select2-selection"
                    styles={{
                      menuList: (style) => ({
                        ...style,
                        backgroundColor: "white",
                      }),
                    }}
                  />
                </>
              )}
            </div> */}

            <div className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Campaign description
              </Label>
              <Input
                type="textarea"
                className="form-control"
                rows="3"
                placeholder="Example: This campaign sends one-time passcode to the end users when they try to log into our company's website"
                name="campaign_description"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={val.values.campaign_description || ""}
                invalid={
                  val.touched.campaign_description &&
                  val.errors.campaign_description
                    ? true
                    : false
                }
              />
              {val.touched.campaign_description &&
              val.errors.campaign_description ? (
                <FormFeedback type="invalid">
                  {val.errors.campaign_description}
                </FormFeedback>
              ) : null}
              <p className="font-size-11">
                Describe what you will be using this campaign for.{" "}
                <a href="#"> Learn more</a>
              </p>
            </div>

            <div className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Sample message #1
              </Label>
              <Input
                type="textarea"
                className="form-control"
                rows="3"
                placeholder="Example: Your one-time passcode is 123456"
                name="sample_message_1"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={val.values.sample_message_1 || ""}
                invalid={
                  val.touched.sample_message_1 && val.errors.sample_message_1
                    ? true
                    : false
                }
              />
              {val.touched.sample_message_1 && val.errors.sample_message_1 ? (
                <FormFeedback type="invalid">
                  {val.errors.sample_message_1}
                </FormFeedback>
              ) : null}
              <p className="font-size-11">
                Provide an example of a message that you will be sending with
                this campaign. This message sample will be used by carriers to
                help identify your traffic. <a href="#"> Learn more</a>
              </p>
            </div>

            <div className="mb-3 form-group">
              <Label className="form-label">
                <span className="red_star">*</span> Sample message #2
              </Label>
              <Input
                type="textarea"
                className="form-control"
                rows="3"
                placeholder="Example: Your one-time passcode is 123456"
                name="sample_message_2"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={val.values.sample_message_2 || ""}
                invalid={
                  val.touched.sample_message_2 && val.errors.sample_message_2
                    ? true
                    : false
                }
              />
              {val.touched.sample_message_2 && val.errors.sample_message_2 ? (
                <FormFeedback type="invalid">
                  {val.errors.sample_message_2}
                </FormFeedback>
              ) : null}
              <p className="font-size-11">
                Provide an example of a message that you will be sending with
                this campaign. This message sample will be used by carriers to
                help identify your traffic. <a href="#"> Learn more</a>
              </p>
            </div>

            <div className="mb-3 form-group">
              <Label className="form-label">Message contents</Label>
              <div className="form-check form-check-primary mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_include_embedded_links"
                  checked={val.values.is_include_embedded_links}
                  onChange={(s) =>
                    val.setFieldValue(
                      "is_include_embedded_links",
                      !val.values.is_include_embedded_links
                    )
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="is_include_embedded_links"
                >
                  Does the message include embedded links
                </label>
              </div>
              <div className="form-check form-check-primary">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_include_phone_numbers"
                  checked={val.values.is_include_phone_numbers}
                  onChange={(s) =>
                    val.setFieldValue(
                      "is_include_phone_numbers",
                      !val.values.is_include_phone_numbers
                    )
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="is_include_phone_numbers"
                >
                  Does the message include phone numbers
                </label>
              </div>
            </div>
            <Button
              type="button"
              onClick={submitToReview}
              color="primary"
              style={{ marginRight: 20 }}
            >
              {!state.modalCreateNewCampaign.activeCampaingEdit
                ? "Create"
                : "Edit"}
            </Button>
            <Button
              type="button"
              onClick={() => {
                console.log(props);
                // props.router.navigate(-1);
                onToggle();
              }}
              outline
            >
              Cancel
            </Button>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default withRouter(Campaign_Registration);
