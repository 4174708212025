import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Row,
  Input,
  InputGroup,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import Flatpickr from "react-flatpickr";

import "draft-js/dist/Draft.css";

import {
  createImageLink,
  formatBytes,
  formatDate,
  searchVanilla,
  formatDateFromUTC,
} from "helpers/utility";
import withRouter from "components/Common/withRouter";
import LinkPreviewComponent from "components/Common/LinkPreview";

import { createCampaingAction, editCampaingAction } from "store/actions";
import { getCampaingByID, startCampaingID } from "helpers/API_hendlers";

let names = {
  buttonSaveCreate: "",
};

const whom = [
  { name: "All Reps", value: "allReps" },
  { name: "Selected Reps", value: "selectedReps" },
];

const when = [
  { name: "Immediately", value: "immediate" },
  { name: "Select a time", value: "selectedTime" },
];

const EditCreateCampaing = ({ router }) => {
  const dispatch = useDispatch();

  const TYPE_PAGE = useRef(
    router.location.pathname === "/campaings/create" ? "create" : "edit"
  );

  const { errorCampaing, salesReps, editCampaing } = useSelector(
    (state) => ({
      editCampaing: state.campaings.editCampaing,
      salesReps: state.contacts.users,
      errorCampaing: state.campaings.error,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    image: { selectedFile: null, value: "", src: "" },
    repsFirstName: "",
    whomSend: "allReps",
    whomSendList: [],

    whenSend: "immediate",
    whenSendTime: { date: "", time: "" },

    body: "",
    messageContent: {},

    isOpenPreviewModal: false,
    phoneTest: "",
    campaing_title: "",
    isLaunched: false,
  });

  const [toast, setToast] = useState(null);

  useEffect(() => {
    console.log("EditCreateCampaing", router);

    const whomSendList = salesReps.map((it) => {
      return {
        checked: false,
        name: `${it.first_name} ${it.last_name}`,
        id: it.id,
      };
    });

    if (TYPE_PAGE.current === "create") {
      names.buttonSaveCreate = "Create new Campaing";
      const timeForMount = new Date();
      setState((pS) => ({
        ...pS,
        whenSendTime: { date: timeForMount, time: timeForMount },
        whomSendList: [...whomSendList],
      }));
    }

    if (TYPE_PAGE.current === "edit") {
      names.buttonSaveCreate = "Save Changes";

      getCampaingByID(router.params.id)
        .then((r) => {
          console.log("getCampaingByID", r);
          const usedData = r.campaign;

          const start = formatDateFromUTC(new Date(usedData.start));

          setState((pS) => ({
            ...pS,
            whomSend: "selectedReps",
            whomSendList: [...whomSendList].map((f) => {
              if (usedData.user_list.includes(+f.id)) {
                return { ...f, checked: true };
              }
              return f;
            }),
            campaing_title: usedData.title,
            image: { ...pS.image, src: usedData.icon || "" },
            body: usedData.body,
            whenSend: "selectedTime",
            whenSendTime: {
              date: start,
              time: start,
            },
            isLaunched: usedData.launched,
          }));
        })
        .catch((e) => console.log(e));
    }
  }, [editCampaing]);

  let preview = { src: "", name: "", size: 0 };
  if (state.image.selectedFile) {
    preview.src = URL.createObjectURL(state.image.selectedFile);
    preview.name = state.image.selectedFile.name;
    preview.size = formatBytes(state.image.selectedFile.size);
  }

  const onSaveConfigure = async () => {
    console.log("onSaveConfigure", state);

    const payload = {
      title: state.campaing_title,
      body: state.body,
      user_list: [],
      start: "",
    };

    if (state.whenSend === "immediate") {
      payload.start = formatDate(new Date());
    }
    if (state.whenSend === "selectedTime") {
      payload.start = formatDate(
        state.whenSendTime.date,
        state.whenSendTime.time
      );
    }
    if (state.whomSend === "allReps") {
      payload.user_list = salesReps.map((sr) => sr.id);
    }
    if (state.whomSend === "selectedReps") {
      payload.user_list = state.whomSendList
        .filter((sr) => sr.checked)
        .map((srm) => srm.id);
    }

    const image = await createImageLink(state.image.selectedFile);
    if (image && image.link) {
      payload["icon"] = image.link;
      payload.body = `${image.link}\n${state.body}`;
    }
    // const actID = router?.location?.state?.id;

    console.log("payload", payload);

    if (TYPE_PAGE.current === "create") {
      dispatch(createCampaingAction(payload, router.navigate));
    }

    if (TYPE_PAGE.current === "edit") {
      dispatch(editCampaingAction(router.params.id, payload));
    }
  };

  const handleChange = (e) => {
    const parName = e.target.name;
    const parvalue = e.target.value;
    setState((pS) => {
      if (parName === "whomSend" && parvalue === "selectedReps") {
        return { ...pS, [parName]: parvalue };
      }
      return { ...pS, [parName]: parvalue };
    });
  };
  const onSendTestMessage = () => {
    console.log("SEND TEST MESSAGE", state.phoneTest);
  };
  const onOpenPreview = () => {
    setState({ ...state, isOpenPreviewModal: true });
  };
  const startCampaing = async () => {
    if (state.isLaunched) {
      return;
    }
    console.log("START CAMPAING");

    // await onSaveConfigure();

    startCampaingID(router.params?.id)
      .then((r) => {
        console.log("startCampaingID", r);
        setToast({
          type: "success",
          msg: "Campaign has started successfully!!!",
        });

        router.navigate("/campaings")
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div
        className={`page-content ${state.isLaunched ? "overlay_block" : ""}`}
        style={{ padding: "70px 0 50px 0" }}
      >
        {toast && (
          <UncontrolledAlert
            color={toast.type}
            className="alert-dismissible fade show"
            role="alert"
          >
            <i className="mdi mdi-block-helper me-2"></i>
            {toast?.msg || "Success"}
          </UncontrolledAlert>
        )}

        {errorCampaing && (
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            <i className="mdi mdi-block-helper me-2"></i>
            {errorCampaing?.msg || "Something went wrong"}
          </UncontrolledAlert>
        )}

        <Row className="flow_container">
          <Col xs={5} className="flow_leftBlock mainScreen">
            <div>
              <Row className="mb-3 section_config_campaing ">
                <div>
                  <Label className="form-label">Campaing Title</Label>
                  <Input
                    name="campaing_title"
                    autoComplete=""
                    type="text"
                    placeholder="Insert Campaing Name"
                    onChange={handleChange}
                    value={state.campaing_title}
                  />
                </div>
              </Row>

              <Row className="mb-3 section_config_campaing">
                <Col xs={5}>
                  <Label className="form-label">When to send: </Label>
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="whenSend"
                    type="select"
                    style={{ fontSize: 13 }}
                    value={state.whenSend}
                    onChange={handleChange}
                  >
                    {when.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.name}
                      </option>
                    ))}
                  </Input>
                </Col>

                {state.whenSend === "selectedTime" && (
                  <InputGroup className="mt-3">
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="mm/dd/yyyy"
                      value={state.whenSendTime.date}
                      options={{
                        // minDate: new Date(),
                        dateFormat: "m/j/Y",
                      }}
                      onChange={(e) => {
                        const d = new Date(e);
                        setState({
                          ...state,
                          whenSendTime: { ...state.whenSendTime, date: d },
                        });
                      }}
                    />
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Select time"
                      value={state.whenSendTime.time}
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "h:i K",
                      }}
                      onChange={(e) => {
                        const t = new Date(e);
                        setState({
                          ...state,
                          whenSendTime: { ...state.whenSendTime, time: t },
                        });
                      }}
                    />
                  </InputGroup>
                )}
              </Row>

              <Row className="section_config_campaing mb-3">
                <Col xs={5}>
                  <Label className="form-label">Send to:</Label>
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="whomSend"
                    type="select"
                    style={{ fontSize: 13 }}
                    value={state.whomSend}
                    onChange={handleChange}
                  >
                    {whom.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                {state.whomSend === "selectedReps" &&
                  state.whomSendList.length > 0 && (
                    <Row className="mt-3">
                      <Col xs={1}></Col>
                      <Col>
                        <div className="search-box chat-search-box py-1 mb-3">
                          <div className="position-relative">
                            <Input
                              onKeyUp={() =>
                                searchVanilla(
                                  "search_sal_rep",
                                  "whom_selr_list",
                                  "li",
                                  "p"
                                )
                              }
                              id="search_sal_rep"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                        <ul id="whom_selr_list">
                          {state.whomSendList.map((item) => (
                            <li
                              key={item.name}
                              onClick={() => {
                                setState((pS) => ({
                                  ...pS,
                                  whomSendList: pS.whomSendList.map((i) => {
                                    if (i.name === item.name) {
                                      return {
                                        ...i,
                                        checked: !i.checked,
                                      };
                                    }
                                    return i;
                                  }),
                                }));
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor3"
                                  readOnly
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: 0,
                                  }}
                                  checked={item.checked}
                                />
                              </div>

                              <p>{item.name}</p>
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  )}
              </Row>

              <Row className="section_config_campaing ">
                <h4 className="position-relative">
                  Message Content{" "}
                  <span className="estimated">
                    Estimated: {state.body.length}
                  </span>
                </h4>

                <FormGroup>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    value={state.body}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setState({ ...state, body: e.target.value });
                    }}
                  />

                  <Label style={{ marginTop: 15 }} for="exampleFile">
                    Add photo
                  </Label>
                  <Input
                    id="exampleFile"
                    name="image"
                    type="file"
                    onChange={(e) => {
                      const selectedImage = e.target.files[0];
                      console.log("selectedImage", selectedImage);
                      setState((pS) => ({
                        ...pS,
                        image: {
                          ...pS.image,
                          value: e.target.value,
                          selectedFile: selectedImage,
                        },
                      }));
                    }}
                    value={state.image.value}
                  />
                </FormGroup>

                <div className="form-group">
                  {state.image?.selectedFile && (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="150"
                              style={{ maxWidth: "100%" }}
                              alt={preview.name}
                              src={preview.src}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {preview.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{preview.size}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {!state.image.selectedFile && !!state.image.src && (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              style={{ maxWidth: "100%" }}
                              data-dz-thumbnail=""
                              height="150"
                              alt={state.image.src}
                              src={state.image.src}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                </div>
              </Row>
            </div>

            <Button outline onClick={onSaveConfigure} style={{ marginTop: 10 }}>
              {names.buttonSaveCreate}
            </Button>
          </Col>
          <Col className="flow_rightBlock">
            <div className="phone_template">
              <header>
                <p>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    name="phoneTest"
                    value={state.phoneTest}
                    className="form-control input-color"
                    style={{
                      padding: 0,
                      fontSize: 16,
                      border: "none",
                      textAlign: "center",
                    }}
                    onChange={handleChange}
                  ></InputMask>
                </p>
              </header>
              <main>
                <div>
                  <div className="chat-conversation p-3">
                    <div
                      className="conversation-list"
                      style={{ width: "100%" }}
                    >
                      <div className="ctext-wrap">
                        {state.image?.selectedFile && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    style={{ maxWidth: "100%" }}
                                    data-dz-thumbnail=""
                                    height="150"
                                    alt={preview.name}
                                    src={preview.src}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                        {!state.image.selectedFile && !!state.image.src && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="150"
                                    style={{ maxWidth: "100%" }}
                                    alt={state.image.src}
                                    src={state.image.src}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                        {state.body.split("\n").map((p, i) => {
                          if (p.includes(" ")) {
                            const partsP = p.split(" ");

                            const modString = partsP.map((span, y) => {
                              if (span.includes("https://")) {
                                const startLink = span.indexOf("https://");
                                const endLink = span.indexOf(" ", startLink);
                                const link = span.slice(startLink, endLink);
                                if (
                                  span.includes("api.coolcloud.com/storage")
                                ) {
                                  return;
                                }
                                return (
                                  <LinkPreviewComponent
                                    key={`${span}_${i}_${y}_prev`}
                                    link={link}
                                  />
                                );
                              }

                              return (
                                <span key={`${span}_${i}_${y}`}>{span} </span>
                              );
                            });

                            return modString;
                          }

                          if (p.includes("https://")) {
                            const startLink = p.indexOf("https://");
                            const endLink = p.indexOf(" ", startLink);
                            const link = p.slice(startLink, endLink);
                            if (p.includes("api.coolcloud.com/storage")) {
                              return;
                            }

                            return (
                              <LinkPreviewComponent
                                key={`${p} + ${i}`}
                                link={link}
                              />
                            );
                          }

                          return (
                            <p
                              key={`${p} + ${i}`}
                              style={{
                                margin: 0,
                                textAlign: "left",
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                              }}
                            >
                              {p}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttons_test">
                  <Button
                    onClick={onSendTestMessage}
                    style={{ marginBottom: 10 }}
                  >
                    Send Test message
                  </Button>
                  <Button outline onClick={onOpenPreview}>
                    Preview
                  </Button>
                </div>
              </main>
              <footer>
                <div />
                <div />
                <div />
              </footer>
            </div>

            {TYPE_PAGE.current !== "create" && (
              <div
                className={`send_campaing ${
                  state.isLaunched ? "launched" : ""
                }`}
                onClick={startCampaing}
              >
                <p>{state.isLaunched ? "Launched" : "Send Campaign"}</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={state.isOpenPreviewModal}
        style={{ width: 400 }}
        toggle={() =>
          setState({ ...state, isOpenPreviewModal: !state.isOpenPreviewModal })
        }
      >
        <ModalHeader tag="h5">Preview </ModalHeader>
        <ModalBody>
          <div className="chat-conversation p-3">
            <div className="conversation-list" style={{ width: "100%" }}>
              <div className="ctext-wrap">
                {state.image?.selectedFile && (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            style={{ maxWidth: "100%" }}
                            data-dz-thumbnail=""
                            height="150"
                            alt={preview.name}
                            src={preview.src}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
                {!state.image.selectedFile && !!state.image.src && (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="150"
                            style={{ maxWidth: "100%" }}
                            alt={state.image.src}
                            src={state.image.src}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}

                {state.body.split("\n").map((p, i) => {
                  if (p.includes("https://")) {
                    const link = p.slice(p.indexOf("https://"), p.indexOf(" "));

                    return <LinkPreviewComponent key={link} link={link} />;
                  }

                  return (
                    <p
                      key={p + i}
                      style={{
                        margin: 0,
                        textAlign: "left",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {p}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <Row>
            <Row className="align-items-center">
              <Col xs={1}>
                <i
                  className="mdi mdi-email-send font-size-18"
                  id="edittooltip"
                />
              </Col>
              <Col>
                Send to:{" "}
                {state.whomSend === "allReps"
                  ? "All Sales Reps"
                  : `${state.whomSendList
                      .filter((i) => i.checked)
                      .map((y) => y.name)
                      .join(", ")}`}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={1}>
                <i
                  className="mdi mdi-account-child font-size-18"
                  id="edittooltip"
                />
              </Col>
              <Col>Estimated recipients: 1234</Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={1}>
                <i className="mdi mdi-send font-size-18" id="edittooltip" />
              </Col>
              <Col>Smart sending: ON</Col>
            </Row>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(EditCreateCampaing);
