import React from "react";
import { map } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Card,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

import MessageItem from "./message_item";

export default function BlockMessages({
  chat,
  user,
  curMessage,
  sendVcard,
  handleAddMediaToMessage,
  addMessageTo,
  handleInputMessText,
  _scrollRef_messages,
  isMobMode,
  isMobModeChange,
}) {
  const handleKeyDown = (e) => {
    if (e.code === "Enter" || e.keyCode === 13) {
      addMessageTo();
    }
  };

  // const TEST = () => {
  //   ClientConversation.getLastMessageStatusInterval();
  // };

  return (
    <div className="w-100 user-chat">
      <Card className="mb-1">
        <div className="p-3 border-bottom ">
          <Row>
            <Col md="4" xs="9" className="d-flex flex-row align-items-center">
              {isMobMode.is && (
                <div style={{ marginRight: 20 }} onClick={isMobModeChange}>
                  <i
                    style={{ fontSize: 30 }}
                    className="mdi mdi-keyboard-backspace me-1"
                    id="vcard"
                  />
                </div>
              )}
              <h5 className="font-size-15 m-0">
                {chat.conversationProxy && chat.conversationProxy.friendlyName}
              </h5>
            </Col>
          </Row>
        </div>

        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <PerfectScrollbar
                style={{
                  height: `calc(100vh - 350px)`,
                }}
                containerRef={(ref) => (_scrollRef_messages.current = ref)}
              >
                {chat.messages.length > 0 &&
                  map(chat.messages, (message) => (
                    <MessageItem
                      key={message.id}
                      message={message}
                      user={user}
                    />
                  ))}
              </PerfectScrollbar>
            </ul>
          </div>
          <div className="p-3 chat-input-section position-relative">
            <Row>
              <Col>
                {/* {curMessage.media && curMessage.selectedFile && (
                    <div
                      style={{
                        position: "absolute",
                        top: -27,
                        right: 15,
                        padding: 3,
                        borderRadius: 5,
                        backgroundColor: "rgba(0,0,0,0.1)",
                      }}
                    >
                      File: {curMessage.selectedFile.name}
                    </div>
                  )} */}
                <div className="position-relative">
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    disabled={curMessage.media && curMessage.selectedFile}
                    value={curMessage.text}
                    onChange={handleInputMessText}
                    className="form-control chat-input"
                    placeholder={
                      curMessage.media && curMessage.selectedFile
                        ? ""
                        : "Enter Message..."
                    }
                  />
                  {!curMessage.text && (
                    <div className="chat-input-links">
                      <ul className="list-inline mb-0 d-flex align-items-center">
                        {curMessage.media && curMessage.selectedFile && (
                          <li className="list-inline-item">
                            File: {curMessage.selectedFile.name}
                          </li>
                        )}
                        {/* <li className="list-inline-item">
                            <i
                              style={{ fontSize: 20 }}
                              className="mdi mdi-card-account-phone me-1"
                              id="vcard"
                              onClick={TEST}
                            />
                            <UncontrolledTooltip placement="top" target="vcard">
                              TEST
                            </UncontrolledTooltip>
                          </li> */}

                        <li className="list-inline-item">
                          <i
                            style={{ fontSize: 20 }}
                            className="mdi mdi-card-account-phone me-1 cp-2"
                            id="vcard"
                            onClick={sendVcard}
                          />
                          <UncontrolledTooltip placement="top" target="vcard">
                            Send your vCard
                          </UncontrolledTooltip>
                        </li>

                        <li className="list-inline-item position-relative">
                          <label
                            htmlFor="add_file"
                            className="position-absolute top-0"
                            id="Imagetooltip"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          ></label>
                          <i
                            style={{ fontSize: 20 }}
                            className="mdi mdi-file-plus me-1 cp-2"
                            id="Imagetooltip"
                          />
                          <input
                            type="file"
                            id="add_file"
                            className="file-input"
                            style={{ display: "none" }}
                            onChange={handleAddMediaToMessage}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="Imagetooltip"
                          >
                            Add Files (max size: 5MB)
                          </UncontrolledTooltip>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </Col>
              <Col className="col-auto">
                {curMessage.loading ? (
                  <Spinner className="ms-5 mb-3" color="primary" />
                ) : (
                  <Button
                    type="button"
                    color="primary"
                    onClick={addMessageTo}
                    className="btn btn-primary btn-rounded chat-send w-md "
                  >
                    <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
                    <i className="mdi mdi-send" />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  );
}
