import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import {
  Phone,
  Status,
  StatusBrand,
  BusinessName,
} from "../../Contacts/ContactList/contactlistCol";
import DeleteModal from "components/Common/DeleteModal";

import { deleteUser as onDeleteUser } from "store/contacts/actions";
import { getAllCompanies } from "helpers/API_hendlers";
import { useDispatch } from "react-redux";
import Breadcrumb from "components/Common/Breadcrumb";

const BrandsList = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    brands: [],
  });

  useEffect(() => {
    getAllCompanies().then((r) => {
      console.log(r);
      setState((ps) => ({ ...ps, brands: r.companies }));
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Brand Title",
        accessor: "title",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Legal Business Name",
        accessor: "legal_business_name",

        filterable: true,
        Cell: (cellProps) => {
          return <BusinessName {...cellProps} />;
        },
      },
      {
        Header: "Modified",
        accessor: "modified",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Status",
        filterable: true,
        Cell: (cellProps) => {
          return <StatusBrand {...cellProps} />;
        },
      },

      {
        Header: "Edit Profile",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/a2p-profile/${cellProps.row.original.supervisor_id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-pencil-outline font-size-18"
                  id="editprof"
                />
                <UncontrolledTooltip placement="top" target="editprof">
                  Edit Profile
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Edit Brand",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/brand-registration/${cellProps.row.original.supervisor_id}`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="editbrand" />
                <UncontrolledTooltip placement="top" target="editbrand">
                  Edit Brand registration form
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },

      {
        Header: "Campaigns",
        Cell: (cellProps) => {        

          const amountCampaigns =
            cellProps.row.original?.a2p?.campaings?.length || 0;

          return (
            <div className="d-flex gap-3">
              <Link
                to={`/campaign-registration/${cellProps.row.original.supervisor_id}`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="editcamp" /> (
                {amountCampaigns})
                <UncontrolledTooltip placement="top" target="editcamp">
                  Edit Campaign Registration form
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () =>
    setState((pS) => ({
      ...pS,
      modal: !state.modal,
      isEdit: false,
      contactActive: {},
    }));

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteUser = () => {
    if (state.contactActive && state.contactActive.id) {
      dispatch(onDeleteUser(state.contactActive.id));
      setState((pS) => ({
        ...pS,
        deleteModal: false,
      }));
    }
    onPaginationPageChange(1);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={state.deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() =>
          setState((pS) => ({ ...pS, deleteModal: !state.deleteModal }))
        }
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Brands"  breadcrumbItems={props?.router?.location?.pathname}  />
          {/* {error && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-block-helper me-2"></i>
              {error?.msg || "Something went wrong"}
            </UncontrolledAlert>
          )} */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={state.brands}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={toggle}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandsList);
