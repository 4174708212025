import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import InputMask from "react-input-mask";

import AsyncSelect from "react-select/async";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  FirstName,
  LastName,
  Tags,
  Projects,
  Phone,
  Role,
  Status,
} from "./contactlistCol";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  addNewUser as onAddNewUser,
  updateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";

import { addPhones, phonesAvailable } from "helpers/API_hendlers";
import { useSelector, useDispatch } from "react-redux";
import { values } from "lodash";

const SalesReps = (props) => {
  const dispatch = useDispatch();
  const { users, error, company_title } = useSelector((state) => ({
    company_title: state.Login.user?.company_title,
    users: state.contacts.users,
    error: state.contacts.error,
  }));

  const [state, setState] = useState({
    modal: false,
    deleteModal: false,
    isEdit: false,
    contactActive: {},
    phoneNumbers: [],
    loadingNumbers: false,
    selectedPhone: "",
  });

  const getPhones = async (requestData) => {
    return new Promise((res, rej) => {
      setState((pS) => ({ ...pS, loadingNumbers: true }));
      phonesAvailable(requestData)
        .then((resp) => {
          console.log(resp);
          const ph = [...resp.phoneNumbers];
          const optionsPhones = ph.map((phone) => {
            let val = phone.replace(/ /gm, "");
            // console.log(val);

            let num = `${val.substring(0, 2)} (${val.substring(
              2,
              5
            )}) ${val.substring(5, 8)}-${val.substring(8, val.length)}`;

            num = num.trim();

            return { label: num, value: phone };
          });
          setState((pS) => ({
            ...pS,
            // phoneNumbers: optionsPhones,
            loadingNumbers: false,
          }));

          res(optionsPhones);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
          setState((pS) => ({
            ...pS,
            loadingNumbers: false,
          }));
        });
    });
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: state.contactActive.first_name || "",
      last_name: state.contactActive.last_name || "",
      personal_phone: state.contactActive.personal_phone || "",
      email: state.contactActive.email || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string(),
      last_name: Yup.string(),
      personal_phone: Yup.string(),
      // password: Yup.string().required("Please Enter Password"),
      // phone: Yup.string().required("Please choose Phone Number"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ),
    }),
    onSubmit: async (values) => {
      console.log(values, state);
      // return;
      if (state.isEdit) {
        const updateUseObjr = {
          id: state.contactActive.id,
        };

        if (values.first_name !== "") {
          updateUseObjr["first_name"] = values.first_name;
        }
        if (values.last_name !== "") {
          updateUseObjr["last_name"] = values.last_name;
        }
        if (values.personal_phone !== "") {
          updateUseObjr["personal_phone"] = values.personal_phone;
        }
        if (values.email !== "") {
          updateUseObjr["email"] = values.email;
        }
        if (state.selectedPhone !== "") {
          const registerNumber = await addPhones({
            phoneNumber: state.selectedPhone,
          });
          updateUseObjr["phone"] = state.selectedPhone;
        }

        dispatch(updateUser(updateUseObjr));
        setState((pS) => ({ ...pS, modal: false, isEdit: false }));
        return;
      } else {
        if (state.selectedPhone === "") {
          console.log("EMPTY phone");
          return;
        }

        const registerNumber = await addPhones({
          phoneNumber: state.selectedPhone,
        });
        const payload = { ...values, phone: state.selectedPhone };

        console.log("registerNumber", registerNumber);

        if (!registerNumber) return;
        dispatch(onAddNewUser(payload));
        setState((pS) => ({ ...pS, modal: false }));
      }
      validation.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "is_admin",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {
          return <Role {...cellProps} />;
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
        Cell: (cellProps) => {
          return <FirstName {...cellProps} />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
        Cell: (cellProps) => {
          return <LastName {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Personal Phone",
        accessor: "personal_phone",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Company",
        accessor: "company_title",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClicks(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () =>
    setState((pS) => ({
      ...pS,
      modal: !state.modal,
      isEdit: false,
      contactActive: {},
    }));

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = (userData) => {
    setState((pS) => ({
      ...pS,
      deleteModal: true,
      contactActive: userData,
    }));
  };
  const handleDeleteUser = () => {
    if (state.contactActive && state.contactActive.id) {
      dispatch(onDeleteUser(state.contactActive.id));
      setState((pS) => ({
        ...pS,
        deleteModal: false,
      }));
    }
    onPaginationPageChange(1);
  };
  const handleUserClicks = (userData) => {
    console.log("handleUserClicks", userData);
    if (userData) {
      setState((pS) => ({
        ...pS,
        modal: true,
        isEdit: true,
        contactActive: userData,
      }));
    } else {
      toggle();
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {

      // console.log(inputValue);
      let code = "+1";
      if (inputValue.includes("+1")) {
        code = "";
      }

      const request = {
        country: "",
        areaCode: "",
        contains: code + inputValue,
        inRegion: "",
      };
      getPhones(request).then((r) => {
        resolve(r);
      });
    });

  const handleSelect = (e) => {
    console.log(e);
    setState((pS) => ({ ...pS, selectedPhone: e.value }));
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={state.deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() =>
          setState((pS) => ({ ...pS, deleteModal: !state.deleteModal }))
        }
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Breadcrumb
            title="Sales Reps"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {error && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-block-helper me-2"></i>
              {error?.msg || "Something went wrong"}
            </UncontrolledAlert>
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={"Create Sales Rep"}
                    handleUserClick={toggle}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={state.modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!state.isEdit ? "Edit User" : "Add Sales Rep"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">First Name</Label>
                              <Input
                                name="first_name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ""}
                                invalid={
                                  validation.touched.first_name &&
                                  validation.errors.first_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.first_name &&
                              validation.errors.first_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.first_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Last name</Label>
                              <Input
                                name="last_name"
                                label="Last Name"
                                placeholder="Insert Last Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ""}
                                invalid={
                                  validation.touched.last_name &&
                                  validation.errors.last_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.last_name &&
                              validation.errors.last_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.last_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3 position-relative">
                              <Label className="form-label">
                                Reps Personal Mobile Number
                              </Label>
                              <InputMask
                                mask="+1 (999) 999-9999"
                                name="personal_phone"
                                value={validation.values.personal_phone || ""}
                                className="form-control input-color"
                                onChange={validation.handleChange}
                              ></InputMask>

                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <i
                                  style={{ fontSize: 20 }}
                                  className="mdi mdi-comment-question me-1"
                                  id="info_personal_number"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="info_personal_number"
                                >
                                  {`This is needed so our system can notify the Rep on his personal phone when he has unanswered communications from customers and for other critical notifications from the ${
                                    company_title || "Company Name"
                                  } Cool Cloud system.`}
                                </UncontrolledTooltip>
                              </div>
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            {/* {(!state.isEdit || (state.isEdit && !state.contactActive.phone)) && ( */}
                              <div className="mb-3 position-relative">
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                  }}
                                >
                                  <i
                                    style={{ fontSize: 20 }}
                                    className="mdi mdi-comment-question me-1"
                                    id="info_personal_number"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="info_personal_number"
                                  >
                                    Create a Rep number that will be used to
                                    communicate with customers via SMS. Your
                                    customers will assume this is your Rep's
                                    personal phone.
                                  </UncontrolledTooltip>
                                </div>
                                <div className="ajax-select mt-3 mt-lg-0 select2-container">
                                  <Label>Enter a desired area code</Label>
                                  <AsyncSelect
                                    styles={{
                                      menuList: (style) => ({
                                        ...style,
                                        backgroundColor: "white",
                                      }),
                                    }}
                                    placeholder={state.contactActive?.phone}                                    
                                    onChange={handleSelect}
                                    options={state.phoneNumbers}
                                    isLoading={state.loadingNumbers}
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={promiseOptions}
                                  />
                                </div>
                              </div>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SalesReps);
