import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import USA_states from "assets/data/USA_states";

import { modifyCurrentUser, changeLoader } from "../../store/actions";

import { formatBytes, createImageLink } from "helpers/utility";

const UserProfile = (props) => {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => ({
    user: state.Login?.user,
    loading: state.Login.loading,
    error: state.Login?.error,
  }));

  const mServiceSid = user?.m_service_sid;
  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const email = user?.email;
  const isAdmin = user?.is_admin;
  const registered = user?.registered;
  const phone = user?.phone;
  const address = user?.address;
  const address_2 = user?.address_2;
  const city = user?.city;
  const stateAdd = user?.state;
  const zip = user?.zip;
  const company_title = user?.company_title;
  const image = user?.image;

  const [state, setState] = useState({
    image: { selectedFile: null, value: "", src: image || "" },
    state: USA_states.find(({ value }) => value === stateAdd) || null,
    showAlert: {
      init: false,
      isShow: false,
      message: "Updated successfully",
      type: "success",
      icon: "mdi-information-outline",
    },
  });

  useEffect(() => {
    console.log("loading", loading);
    if (!loading && state.showAlert.init) {
      if (error) {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Something went wrong",
            type: "danger",
            icon: "mdi-block-helper",
            init: true,
          },
        }));
      } else {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Updated successfully",
            type: "success",
            icon: "mdi-information-outline",
            init: true,
          },
        }));
      }
    } else {
      setState((pS) => ({
        ...pS,
        showAlert: {
          isShow: false,
          message: "Something went wrong",
          type: "danger",
          icon: "mdi-block-helper",
          init: true,
        },
      }));
    }
  }, [loading]);

  // const last_activity = user?.last_activity;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      m_service_sid: mServiceSid || "",
      first_name: firstName || "",
      last_name: lastName || "",
      email: email || "",
      company_title: company_title || "",
      image: image || "",
      phone: phone || "",
      address: address || "",
      address_2: address_2 || "",
      city: city || "",
      state: stateAdd || "",
      zip: zip || "",
    },
    onSubmit: async (values) => {
      dispatch(changeLoader(true));
      const payload = { ...values };
      payload["state"] = state.state?.value;
      const image = await createImageLink(state.image.selectedFile);
      if (image && image.link) {
        payload.image = image.link;
      }
      dispatch(modifyCurrentUser(payload));
    },
  });

  function handleSelectGroup(selectedGroup) {
    setState((pS) => ({ ...pS, state: selectedGroup }));
  }

  let preview = { src: "", name: "", size: 0 };
  if (state.image.selectedFile) {
    preview.src = URL.createObjectURL(state.image.selectedFile);
    preview.name = state.image.selectedFile.name;
    preview.size = formatBytes(state.image.selectedFile.size);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="User Profile" breadcrumbItems={props?.router?.location?.pathname} />
          {state.showAlert.isShow && (
            <Alert
              isOpen={state.showAlert.isShow}
              color={state.showAlert.type}
              className="alert-dismissible fade show"
              role="alert"
              toggle={() => {
                setState((pS) => ({
                  ...pS,
                  showAlert: {
                    ...state.showAlert,
                    isShow: !state.showAlert.isShow,
                  },
                }));
              }}
            >
              <i className={`mdi ${state.showAlert.icon} me-2`}></i>
              {state.showAlert.message}
            </Alert>
          )}
          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
              {/* {success ? <Alert color="success">{success}</Alert> : null} */}
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="mr-10">
                      <img
                        src={image || avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-4">
                      <div className="text-muted">
                        <h5>
                          {firstName} {lastName}
                        </h5>
                        <p className="mb-1">Email: {email}</p>
                        <p className="mb-1">Registered: {registered}</p>
                        <p className="mb-1">Assigned phone: {phone}</p>
                        <p className="mb-1">
                          Role: {isAdmin ? "SuperAdmin" : "User"}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title">Connection to the Verified A2P Campaign</h4>
          <p className="">
            The Messaging Service SID connected to the Verified A2P Campaign
          </p>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3 form-group d-flex flex-row justify-content-between">
                  <div style={{ width: "48%" }}>
                    <Label className="form-label">Connected Messaging Service SID</Label>
                    <Input
                      name="m_service_sid"
                      className="form-control"
                      placeholder="*"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.m_service_sid || ""}
                      invalid={
                        validation.touched.m_service_sid &&
                          validation.errors.m_service_sid
                          ? true
                          : false
                      }
                    />
                    {validation.touched.m_service_sid &&
                      validation.errors.m_service_sid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.m_service_sid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="text-center mt-4">
                  {loading ? (
                    <Spinner className="ms-2" color="light" />
                  ) : (
                    <Button type="submit" color="primary">
                      Save Messaging Service SID
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title">Change Your Profile</h4>
          <p className="">
            This fields you can use for creating own vCard.vcf file
          </p>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3 form-group d-flex flex-row justify-content-between">
                  <div style={{ width: "48%" }}>
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="first_name"
                      // value={name}
                      className="form-control"
                      placeholder="*"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.first_name || ""}
                      invalid={
                        validation.touched.first_name &&
                          validation.errors.first_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.first_name &&
                      validation.errors.first_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.first_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-group" style={{ width: "48%" }}>
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="last_name"
                      // value={name}
                      className="form-control"
                      placeholder="*"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.last_name || ""}
                      invalid={
                        validation.touched.last_name &&
                          validation.errors.last_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.last_name &&
                      validation.errors.last_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.last_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="mb-3" />
                <div className="form-group">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    // value={name}
                    className="form-control"
                    placeholder="@"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3" />
                <div className="form-group">
                  <Label className="form-label">Company</Label>
                  <Input
                    name="company_title"
                    disabled={!isAdmin}
                    // value={name}
                    className="form-control"
                    placeholder="Organization name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company_title || ""}
                    invalid={
                      validation.touched.company_title &&
                        validation.errors.company_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.company_title &&
                    validation.errors.company_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.company_title}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3" />

                <div className="form-group">
                  <Label for="exampleFile">Add photo</Label>
                  <Input
                    id="exampleFile"
                    name="image"
                    type="file"
                    onChange={(e) => {
                      const selectedImage = e.target.files[0];
                      console.log("selectedImage", selectedImage);
                      setState((pS) => ({
                        ...pS,
                        image: {
                          ...pS.image,
                          value: e.target.value,
                          selectedFile: selectedImage,
                        },
                      }));
                    }}
                    value={state.image.value}
                  />

                  {state.image.selectedFile && (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="150"
                              alt={preview.name}
                              src={preview.src}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {preview.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{preview.size}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {!state.image.selectedFile && !!image && (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="150"
                              alt={image}
                              src={image}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                </div>
                <div className="mb-3" />
                <div className="mb-3 d-flex flex-row justify-content-between">
                  <div style={{ width: "49%" }}>
                    <Label className="form-label">Address</Label>
                    <Input
                      name="address"
                      autoComplete=""
                      type="address"
                      placeholder="Insert address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address || ""}
                      invalid={
                        validation.touched.address && validation.errors.address
                          ? true
                          : false
                      }
                    />
                    {validation.touched.address && validation.errors.address ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div style={{ width: "49%" }}>
                    <Label className="form-label">Address 2</Label>
                    <Input
                      name="address_2"
                      autoComplete=""
                      type="address"
                      placeholder="Insert address 2"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address_2 || ""}
                      invalid={
                        validation.touched.address_2 &&
                          validation.errors.address_2
                          ? true
                          : false
                      }
                    />
                    {validation.touched.address_2 &&
                      validation.errors.address_2 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address_2}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3 d-flex flex-row justify-content-between">
                  <div style={{ width: "55%" }}>
                    <Label className="form-label">City</Label>
                    <Input
                      name="city"
                      autoComplete=""
                      type="address"
                      placeholder="City"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city || ""}
                      invalid={
                        validation.touched.city && validation.errors.city
                          ? true
                          : false
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div style={{ width: "30%", marginLeft: 5 }}>
                    <Label className="form-label">State</Label>
                    <Select
                      value={state.state || ""}
                      onChange={handleSelectGroup}
                      options={USA_states}
                      className="select2-selection"
                      styles={{
                        menuList: (style) => ({
                          ...style,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                  <div style={{ width: "15%", marginLeft: 5 }}>
                    <Label className="form-label">Zip</Label>
                    <Input
                      name="zip"
                      autoComplete=""
                      maxLength={5}
                      type="number"
                      placeholder="Zip"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.zip || ""}
                      invalid={
                        validation.touched.zip && validation.errors.zip
                          ? true
                          : false
                      }
                    />
                    {validation.touched.zip && validation.errors.zip ? (
                      <FormFeedback type="invalid">
                        {validation.errors.zip}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="text-center mt-4">
                  {loading ? (
                    <Spinner className="ms-2" color="light" />
                  ) : (
                    <Button type="submit" color="primary">
                      Update User
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
