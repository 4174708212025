import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SuperAdminMenu({  props, closeSidebar, my_ai_data }) {
  return (
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title">{props.t("Menu")}</li>

      <li>
        <Link to="/#" className="has-arrow ">
          <i className="far fa-money-bill-alt"></i>
          <span>{props.t("Super Admin")}</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/dashboard" onClick={closeSidebar}>
              <i className="bx bx-home-circle"></i>
              <span>{props.t("Dashboard")}</span>
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/#" className="has-arrow ">
          <i className="far fa-money-bill-alt"></i>
          <span>{props.t("Brand Manager")}</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/sales-reps" onClick={closeSidebar}>
              <i className="bx bxs-user-detail"></i>
              <span>{props.t("Sales Reps")}</span>
            </Link>
          </li>

          <li>
            <Link to="/flows" onClick={closeSidebar}>
              <i className="bx bxl-stack-overflow"></i>
              <span>{props.t("Flows")}</span>
            </Link>
          </li>

          <li>
            <Link to="/campaings" onClick={closeSidebar}>
              <i className="mdi mdi-earth-arrow-right"></i>
              <span>{props.t("Campaigns")}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow ">
              <i className="far fa-money-bill-alt"></i>
              <span>{props.t("Billing")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/billing-super-admin">
                  {props.t("Billing Page for Cool Cloud Admin")}
                </Link>
              </li>
              <li>
                <Link to="/billing-detail">
                  {props.t("WineChips Admin View")}
                </Link>
              </li>
              <li>
                <Link to="/billing-history-company">
                  {props.t("Billing History Company")}
                </Link>
              </li>
              <li>
                <Link to="/billing-history-client">
                  {props.t("Billing History Client")}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/#" className="has-arrow ">
          <i className="far fa-money-bill-alt"></i>
          <span>{props.t("Sales Rep")}</span>
        </Link>
        <ul className="sub-menu">         
          <li>
            <Link to="/chat" onClick={closeSidebar}>
              <i className="bx bx-chat"></i>
              <span>{props.t("Chat")}</span>
            </Link>
          </li>
          <li>
            <Link to="/customers" onClick={closeSidebar}>
              <i className="bx bxs-user-detail"></i>
              <span>{props.t("Customers")}</span>
            </Link>
          </li>
          <li>
            <Link
              to="/my-ai"
              onClick={closeSidebar}
              aria-expanded="false"
              aria-disabled="true"
            >
              <i className="bx bxs-user-detail"></i>
              <span>{props.t("MY AI")}</span>
            </Link>
            <ul className="sub-menu">
              {my_ai_data.map((training) => (
                <li key={training.label}>
                  <Link to={training.route}>
                    <span className="training_myai_label">
                      {props.t(training.label)}
                    </span>{" "}
                    <span>{training.value}%</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  );
}
export default SuperAdminMenu;
