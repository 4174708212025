import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Container, Button, Alert } from "reactstrap";
import withRouter from "components/Common/withRouter";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Record_API from "helpers/recordAPI";
import { voiceRead } from "helpers/API_hendlers";
import Loader from "components/Common/Loader";
import { changeLoader } from "store/actions";

const TEXT_EXAMPLE_READ =
  "We are looking to hire a skilled Magento developer to build and maintain eCommerce websites for our clients. As a Magento developer, you will be responsible for liaising with the design team, setting up Magento 1x and 2x sites, building modules and customizing extensions, testing the performance of each site, and maintaining security and feature updates after the installation is complete.";

const VoiceTraining = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    textToRead: "",
    isOpenRecordModal: false,
  });
  const [audioUrl, setAudioUrl] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getVoice();
  }, []);

  const getVoice = () => {
    console.log("GETTING AUDIO EXAMPLE");
    dispatch(changeLoader(true));
    const data = { text: TEXT_EXAMPLE_READ };
    voiceRead(data)
      .then((r) => {
        console.log(r);
        setAudioUrl(r);
      })
      .catch((e) => {
        console.log(e);
        const mes = JSON.stringify(e);
        setError({ isOpen: true, message: "Error: " + mes });
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Voice Training"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {error && (
            <Alert
              style={{ zIndex: 1000, display: "flex", position: "fixed" }}
              isOpen={error.isOpen}
              color={"danger"}
              className="alert-dismissible fade show"
              role="alert"
              toggle={() => {
                setError(null);
              }}
            >
              <i className={`mdi mdi-block-helper me-2 font-size-20`}></i>
              <div>
                <p className="mb-0">{error.message}</p>
              </div>
            </Alert>
          )}
          <Row>
            <Col lg={12}>
              {audioUrl !== "" && (
                <Card>
                  <CardBody>
                    <h5 className="fw-semibold mb-3">
                      Listen created template
                    </h5>
                    <audio controls src={audioUrl} style={{ width: "100%" }} />
                  </CardBody>
                </Card>
              )}

              <Card>
                <CardBody>
                  <h5 className="fw-semibold mb-3">
                    Create your voice example by recording audio.
                  </h5>
                  <p className="text-muted">{TEXT_EXAMPLE_READ}</p>

                  <div className="mb-3 row">
                    <div className="col-md-8 col-12"></div>
                    <div className="row col-md-4 col-12">
                      <p className="mb-1" style={{ fontSize: 10 }}>
                        Are you ready?
                      </p>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          setState((pS) => ({ ...pS, isOpenRecordModal: true }))
                        }
                      >
                        Start
                      </Button>
                    </div>
                  </div>

                  <Record_API
                    isOpenRecordModal={state.isOpenRecordModal}
                    onAudioSubmit={getVoice}
                    onError={(message) =>
                      setError({ isOpen: true, message: message })
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VoiceTraining);
