import React, { useState } from "react";
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export const InitBlock = ({
  action,
  onDelete,
  onDrag,
  onDragStart,
  onDragEnd,
  id = "init",
  iconClass,
  onClickFlowBlock,
}) => {
  const [menu, setMenu] = useState(false);

  let isShowDropDown = true;
  let isShowStatus = true;
  let isShowStatusList = true;

  let bgc = "#fff";

  if (id === "init") {
    isShowDropDown = false;
    isShowStatus = false;
    bgc = "#d5e2ee";
  }
  if (id.includes("time_delay")) {
    isShowStatus = false;
    bgc = "#dfeaf4";
  }

  if (id.includes("notification")) {
    isShowStatusList = false;
  }

  return (
    <div
      className="flow_block"
      style={{ backgroundColor: bgc }}
      id={id}
      draggable={true}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <div
        className="flow_block_header"
        style={{ marginBottom: isShowStatus ? 20 : 0 }}
      >
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => onClickFlowBlock(id, action)}
        >
          <div>
            <i className={`${iconClass} font-size-24`}></i>
          </div>
          <div style={{ marginLeft: 15 }}>
            <p>
              {action.settings?.title} {action.settings?.value}{" "}
              {action.settings?.period}
            </p>
            {action.settings?.text && <p>{action.settings?.text}</p>}
          </div>
        </div>

        {isShowDropDown && (
          <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block "
            style={{ height: 30 }}
          >
            <DropdownToggle
              className="btn"
              id="page-header-user-dropdown"
              tag="button"
              style={{ lineHeight: 0, fontSize: 20, padding: 0 }}
            >
              ...
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem tag="a" onClick={onOpenEdit}>
                Edit
              </DropdownItem> */}
              {/* <DropdownItem tag="a" onClick={onClone}>
                Clone
              </DropdownItem> */}
              <DropdownItem tag="a" onClick={() => onDelete(id)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      {isShowStatus && (
        <div className="flow_block_status">
          <p className="status">DRAFT</p>
          {isShowStatusList && (
            <div className="status_data_list">
              <i
                className="mdi mdi-email-check"
                style={{ cursor: "pointer" }}
                id={`sending`}
              ></i>
              <UncontrolledTooltip placement="top" target={`sending`}>
                Smart sending is ON
              </UncontrolledTooltip>
              <i
                className="mdi mdi-go-kart-track"
                style={{ cursor: "pointer" }}
                id={`tracking`}
              ></i>
              <UncontrolledTooltip placement="top" target={`tracking`}>
                UTM tracking is ON
              </UncontrolledTooltip>
              <i
                className="mdi mdi-lightning-bolt"
                style={{ cursor: "pointer" }}
                id={`quiet`}
              ></i>
              <UncontrolledTooltip placement="top" target={`quiet`}>
                Quiet Hours are ON
              </UncontrolledTooltip>
              <i
                className="mdi mdi-filter"
                style={{ cursor: "pointer" }}
                id={`filter`}
              ></i>
              <UncontrolledTooltip placement="top" target={`filter`}>
                Additional filters
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
