import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { isNotifBySID } from "helpers/utility";

export default function ConversationItem({
  conversation,
  changeConv,
  chat,
  deleteCurrentConv,
}) {
  // console.log("conversation", conversation);

  if (!conversation.friendlyName || conversation.friendlyName === " ") return;

  let correctStatusIcon = "text-light";

  if (isNotifBySID(conversation.sid)) {
    correctStatusIcon = "text-success";
  }

  return (
    <li
      key={conversation.sid}
      className={
        chat.conversationProxy.sid === conversation.sid ? "active" : ""
      }
    >
      <Link
        to="#"
        onClick={() => {
          changeConv(conversation.sid);
        }}
      >
        <div className="d-flex align-items-center">
          <div className="align-self-center me-3">
            <i className={`mdi mdi-circle ${correctStatusIcon} font-size-14`} />
          </div>

          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-14 mb-1">
              {conversation.friendlyName}
            </h5>
            <p className="text-truncate mb-0">{conversation.state.current}</p>
          </div>
          <div className="font-size-10" style={{marginRight: 10}}>
            <p className="text-truncate mb-0">
              Created: {new Date(conversation.dateCreated).toLocaleDateString()}
            </p>          
            <p className="text-truncate mb-0">Last message: </p>
            <p className="text-truncate mb-0">
              {conversation.lastMessage &&
                new Date(
                  conversation.lastMessage?.dateCreated
                ).toLocaleString()}
            </p>
          </div>
          {chat.conversationProxy.sid === conversation.sid && (
            <div onClick={deleteCurrentConv}>
              <i
                id="deleteConv"
                className={`mdi mdi-delete text-danger font-size-20`}
              />
              <UncontrolledTooltip placement="top" target="deleteConv">
                Delete this conversation
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      </Link>
    </li>
  );
}
