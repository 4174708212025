import {
  GET_BILLING_FAIL,
  GET_BILLING_SUCCESS,
  GET_BILLING_DETAIL_SUCCESS,
  GET_BILLING_DETAIL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  list: [],
  superAdmin: {},
  historyClient: [],
  historyCompany: [],
  billingDetail: {},
  error: {},
};

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case GET_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        billingDetail: action.payload,
      };

    case GET_BILLING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Invoices;
