import {
  GET_USERS_SUCCESS,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  GET_CUSTOMERS_CONTACT,
  GET_CUSTOMERS_CONTACT_SUCCESS,
  ADD_NEW_CUSTOMERS_CONTACT,
  ADD_NEW_CUSTOMERS_CONTACT_SUCCESS,
  GET_CUSTOMERS_CONTACT_PROFILE,
  GET_CUSTOMERS_CONTACT_PROFILE_SUCCESS,
  UPDATE_CUSTOMERS_CONTACT,
  UPDATE_CUSTOMERS_CONTACT_SUCCESS,
  DELETE_CUSTOMERS_CONTACT,
  DELETE_CUSTOMERS_CONTACT_SUCCESS,
  CLEAR_ON_LOGOUT,
  API_ERROR,
} from "./actionTypes";

const INIT_STATE = {
  users: [],
  userProfile: {},
  customers: [],
  customerProfile: {},
  error: {},
  loading: false,
};

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_CONTACT_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        error: null,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        error: null,
      };

    case ADD_NEW_CUSTOMERS_CONTACT_SUCCESS:
      return {
        ...state,
        customers: [...state.customers, action.payload],
        error: null,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        error: null,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        error: null,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user) => user.id.toString() !== action.payload.toString()
        ),
        error: null,
      };

    case UPDATE_CUSTOMERS_CONTACT_SUCCESS:
      return {
        ...state,
        customers: state.customers.map((customer) => {
          if (customer.id.toString() === action.payload.id.toString()) {
            return { ...customer, ...action.payload };
          } else {
           return customer;
          }
        }),
        error: null,
      };

    case DELETE_CUSTOMERS_CONTACT_SUCCESS:
      console.log("DELETE_CUSTOMERS_CONTACT_SUCCESS", action.payload);
      return {
        ...state,
        customers: state.customers.filter(
          (customers) => customers.id.toString() !== action.payload.toString()
        ),
        error: null,
      };

    case CLEAR_ON_LOGOUT:
      return INIT_STATE;

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contacts;
