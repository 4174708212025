import React, { useEffect, useState } from "react";
import { Spinner, UncontrolledTooltip } from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import withRouter from "components/Common/withRouter";
import { getNotificationsOrderByPhone } from "helpers/API_hendlers";
import { ORDER_TIMELINE } from "config";

const BlockTimeline = ({ clientPhone }) => {
  const [state, setState] = useState({
    list: [],
    loading: false,
    filterBY: {
      orderName: "",
      orderId: null,
      event: null,
      limit: 10,
      offset: 0,
      isFinishList: false,
    },
  });

  useEffect(() => {
    getOrderData();
  }, [
    clientPhone,
    state.filterBY.orderId,
    state.filterBY.event,
    state.filterBY.offset,
  ]);

  const getOrderData = () => {
    if (!clientPhone) {
      return;
    }

    console.log("getting Order Data ....");
    setState((ps) => ({ ...ps, loading: true }));

    getNotificationsOrderByPhone(clientPhone, state.filterBY)
      .then((r) => {
        console.log(r);

        setState((ps) => {
          const newState = {
            ...ps,
            loading: false,
            filterBY: {
              ...ps.filterBY,
              isFinishList: r.orderHistory.length === 0,
            },
          };

          if (ps.filterBY.offset > 0) {
            newState.list = [...ps.list, ...r.orderHistory];
          } else {
            newState.list = r.orderHistory;
          }

          return newState;
        });
      })
      .catch((e) => {
        console.log(e);
        setState((ps) => ({ ...ps, loading: false }));
      });
  };

  const handleFilter = (e, orderId, orderName) => {
    const elID = e?.target?.id;
    // console.log("cl", elID, orderId);
    setState((ps) => {
      if (orderId) {
        return {
          ...ps,
          filterBY: {
            ...ps.filterBY,
            orderId: orderId,
            orderName: orderName,
            offset: 0,
          },
        };
      }

      if (elID === "all") {
        return {
          ...ps,
          filterBY: {
            orderName: "",
            orderId: null,
            event: null,
            limit: 10,
            offset: 0,
          },
        };
      }

      return {
        ...ps,
        filterBY: {
          ...ps.filterBY,
          event: ps.filterBY.event === elID ? null : elID,
          offset: 0,
        },
      };
    });
  };

  return (
    <div className="block_timeline">
      <div className="header">
        <p>Activity Timeline</p>
        <ul>
          {Object.keys(ORDER_TIMELINE).map((action) => (
            <li
              key={action}
              onClick={handleFilter}
              id={action}
              className={state.filterBY.event === action ? "active" : ""}
            >
              <i className={ORDER_TIMELINE[action].icon} id={action}></i>
              <UncontrolledTooltip placement="top" target={action}>
                {ORDER_TIMELINE[action].name}
              </UncontrolledTooltip>
            </li>
          ))}

          <li onClick={getOrderData}>
            {state.loading ? (
              <Spinner style={{ marginTop: 4 }} size="sm" />
            ) : (
              <>
                <i className="mdi mdi-refresh" id="refresh"></i>
                <UncontrolledTooltip placement="top" target="refresh">
                  Refresh
                </UncontrolledTooltip>
              </>
            )}
          </li>
        </ul>
      </div>
      {(state.filterBY.event || state.filterBY.orderName) && (
        <div className="filter">
          Filter By: {state.filterBY.orderName}{" "}
          {ORDER_TIMELINE[state.filterBY.event]?.name}
        </div>
      )}
      <div
        className="content_timeline"
        style={!state.list.length ? { height: 0 } : {}}
        onScroll={(r) => {
          if (
            r.target.scrollTop + r.target.clientHeight ===
            r.target.scrollHeight - 1
          ) {
            // console.log('scroll end')
            if (!state.filterBY.isFinishList) {
              setState((ps) => ({
                ...ps,
                filterBY: {
                  ...ps.filterBY,
                  offset: ps.filterBY.offset + ps.filterBY.limit,
                },
              }));
            }
          }
        }}
      >
        <div className="tree">
          <div className="line_tree" />
          <ul className="months">
            {state.list.map((m) => (
              <li
                key={m.id}
                onClick={(e) => handleFilter(e, m.json.id, m.json.name)}
              >
                <h3>{new Date(m.json.updated_at).toDateString()}</h3>
                <ul className="activities">
                  <li key={m.id}>
                    <div className="icon">
                      <i
                        className={
                          ORDER_TIMELINE[state.filterBY]?.icon || "bx bxs-home"
                        }
                      ></i>
                    </div>
                    <div className="data">
                      <h2 id={`filter_by_number_${m.id}`}>
                        {m.json.name} ({m.topic})
                        <UncontrolledTooltip
                          placement="top"
                          target={`filter_by_number_${m.id}`}
                        >
                          Filter by {m.json.name}
                        </UncontrolledTooltip>
                      </h2>
                      <h3>
                        <i className="mdi mdi-clock-time-three-outline"></i>
                        {new Date(
                          m.json.updated_at
                        ).toLocaleTimeString()} by{" "}
                        {m.json?.customer?.first_name}{" "}
                        {m.json?.customer?.last_name} ({m.json?.customer?.email}
                        )
                      </h3>
                      <h4>
                        Order price: {m.json.total_price}{" "}
                        {m.json.presentment_currency};
                      </h4>
                      <h4>Order note: {m.json.note};</h4>
                    </div>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BlockTimeline);
