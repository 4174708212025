import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_FLOWS,
  CREATE_FLOW,
  EDIT_FLOW,
  GET_FLOW_BY_ID,
  DELETE_FLOW,
} from "./actionTypes";
import {
  getFlowsSuccess,
  createFlowSuccess,
  editFlowSuccess,
  getFlowByIDSuccess,
  deleteFlowSuccess,
  apiErrorFlows,
} from "./actions";

import {
  getFlows,
  createFlow,
  editFlow,
  getFlowByID,
  deleteFlow,
} from "helpers/API_hendlers";

function* getFlowsSage() {
  try {
    const response = yield call(getFlows);
    console.log("getFlowsSage", response);
    yield put(getFlowsSuccess(response.flows));
  } catch (error) {
    yield put(apiErrorFlows(error));
  }
}
function* createFlowSage({ payload: { data, navigate } }) {
  try {
    const response = yield call(createFlow, data);
    console.log("createFlowSage", response);
    navigate("/flows");
    yield put(createFlowSuccess());
  } catch (error) {
    yield put(apiErrorFlows(error));
  }
}
function* editFlowSage({ payload: { id, data, navigate } }) {
  try {
    const response = yield call(editFlow, id, data);
    console.log("editFlowSage", response);
    if (navigate) {
      navigate("/flows");
    }

    yield put(editFlowSuccess(response.flow));
  } catch (error) {
    yield put(apiErrorFlows(error));
  }
}
function* getFlowByIDSage({ payload }) {
  try {
    const response = yield call(getFlowByID, payload);
    console.log("getFlowByIDSage", response);
    yield put(getFlowByIDSuccess(response.flow));
  } catch (error) {
    yield put(apiErrorFlows(error));
  }
}
function* deleteFlowSage({ payload }) {
  try {
    const response = yield call(deleteFlow, payload);
    console.log("deleteFlowSage", response);
    yield put(deleteFlowSuccess(payload));
  } catch (error) {
    yield put(apiErrorFlows(error));
  }
}

function* flowsSaga() {
  yield takeEvery(GET_FLOWS, getFlowsSage);
  yield takeEvery(CREATE_FLOW, createFlowSage);
  yield takeEvery(EDIT_FLOW, editFlowSage);
  yield takeEvery(GET_FLOW_BY_ID, getFlowByIDSage);
  yield takeEvery(DELETE_FLOW, deleteFlowSage);
}

export default flowsSaga;
