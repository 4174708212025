import React, { Component } from "react";
import {
  Button,
  Col,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";

import withRouter from "components/Common/withRouter";
import ItemFlowCreateCard from "./components/create/itemFlowCreate";

import { updateFlowState, createFlowAction } from "store/actions";

class CreateFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: { isOpen: false, data: {} },
      dataForCreate: { name: "", tags: [] },
    };
  }
  toggle = () => {
    this.setState((pS) => ({
      ...pS,
      modal: { isOpen: false, data: {} },
    }));
  };

  createFlow = () => {
    console.log(this.state);

    const newFlow = {
      title: this.state.modal.data.title,
      trigger: this.state.dataForCreate.name,
      status: "draft",
      actions: [{}],
    };

    this.props.onCreateFlowAction(newFlow, this.props.router.navigate);
    this.toggle();
  };

  render() {
    const { isOpen, data } = this.state.modal;
    const { name, tags } = this.state.dataForCreate;

    const { flows_list_template } = this.props;

    return (
      <>
        <div
          className="page-content"
          style={{ padding: "70px 0 0 0", position: "relative" }}
        >
          <div
            style={{ padding: 10, position: "absolute", cursor: "pointer" }}
            onClick={() => {
              this.props.router.navigate("/flows");
            }}
          >
            <i className="mdi mdi-arrow-left-thick font-size-24"></i>
          </div>
          <ul className="flows_create_list">
            {flows_list_template.map((flow) => (
              <ItemFlowCreateCard
                key={flow.trigger}
                card={flow}
                onChoose={() =>
                  this.setState({
                    modal: { isOpen: true, data: flow },
                    dataForCreate: { name: flow.trigger, tags: [] },
                  })
                }
              />
            ))}
          </ul>
        </div>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} tag="h4">
            {data?.trigger}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={5} className="modal_create_left">
                <h4>Description</h4>
                <p>{data.description}</p>
                <Label className="form-label">Name</Label>
                <Input
                  name="first_name"
                  type="text"
                  placeholder="Insert Name"
                  onChange={(e) => {
                    this.setState((pS) => ({
                      dataForCreate: {
                        ...pS.dataForCreate,
                        name: e.target.value,
                      },
                    }));
                  }}
                  value={name}
                />

                <Label className="form-label">Tags</Label>
                <Select
                  value={tags}
                  isMulti={true}
                  onChange={(items) => {
                    this.setState((pS) => ({
                      dataForCreate: { ...pS.dataForCreate, tags: items },
                    }));
                  }}
                  options={data.tags}
                  className="select2-selection"
                />

                <Label className="form-label">Trigger</Label>
                <p>
                  {data.trigger} - {data.title}
                </p>
                <Label className="form-label">Prerequisites</Label>

                <p>{data.description}</p>
              </Col>
              <Col xs={7} className="modal_create_right"></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createFlow}>
              Create Flow
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  flows_list_template: state.flow.flows_list_template,
});
const mapDispatchToProps = (dispatch) => ({
  onUpdateFlowState: (payload) => dispatch(updateFlowState(payload)),
  onCreateFlowAction: (payload, navigate) =>
    dispatch(createFlowAction(payload, navigate)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateFlow)
);
