import React from "react";
import {
  Button,
  CardGroup,
  Card,
  CardBody,
  Col,
  Input,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardHeader,
  CardFooter,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const Notification = ({ id, onDoneEdit }) => {
  return (
    <div className="editBlock" id={id}>
      <div style={{ height: "calc(100vh - 190px)", overflow: "auto" }}>
        <div className="settings">
          <h5>Analitics (30 DAYS)</h5>

          <div className="setting_item">
            <p>Waiting</p>
            <p>0</p>
          </div>
          <div className="setting_item">
            <p>Notifications Sent</p>
            <p>0</p>
          </div>
        </div>

        <div className="settings">
          <h5>CONFIGURATION</h5>

          <div className="mb-3">
            <Label className="form-label">Send to</Label>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Email"
              // onChange={validation.handleChange}
              // onBlur={validation.handleBlur}
              // value={validation.values.email || ""}
              // invalid={
              //   validation.touched.email && validation.errors.email ? true : false
              // }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">From</Label>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Email"
              // onChange={validation.handleChange}
              // onBlur={validation.handleBlur}
              // value={validation.values.email || ""}
              // invalid={
              //   validation.touched.email && validation.errors.email ? true : false
              // }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">From / Reply-To Email</Label>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Email"
              // onChange={validation.handleChange}
              // onBlur={validation.handleBlur}
              // value={validation.values.email || ""}
              // invalid={
              //   validation.touched.email && validation.errors.email ? true : false
              // }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Subject</Label>
            <Input
              name="email"
              label="Email"
              type="email"
              placeholder="Enter Email"
              // onChange={validation.handleChange}
              // onBlur={validation.handleBlur}
              // value={validation.values.email || ""}
              // invalid={
              //   validation.touched.email && validation.errors.email ? true : false
              // }
            />
          </div>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                // "blockType",
                "fontSize",
                // "fontFamily",
                // "list",
                "textAlign",
                // "colorPicker",
                // "link",
                "embedded",
                // "emoji",
                // "image",
                // "remove",
                // "history",
              ],
            }}
          />
        </div>
      </div>

      <div className="buttons">
        <Button outline onClick={onDoneEdit}>
          Done
        </Button>
      </div>
    </div>
  );
};
