// module.exports = {
//   google: {
//     API_KEY: "",
//     // CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
//     SECRET: "",
//   },
//   facebook: {
//     APP_ID: "",
//   },
// }

export const BASE_API = window.location.origin.includes(".local")
  ? "http://coolcloud.local/v1"
  : (window.location.origin.includes("app-staging.") ? "https://api-staging.coolcloud.com/v1" : "https://api.coolcloud.com/v1");

export const A2P_config = {
  business_type: [
    { label: "Partnership", value: "Partnership" },
    {
      label: "Limited Liability Corporation",
      value: "Limited Liability Corporation",
    },
    { label: "Co-operative", value: "Co-operative" },
    { label: "Non-profit Corporation", value: "Non-profit Corporation" },
    { label: "Corporation", value: "Corporation" },
  ],
  business_industry: [
    { label: "AUTOMOTIVE", value: "AUTOMOTIVE" },
    { label: "AGRICULTURE", value: "AGRICULTURE" },
    { label: "BANKING", value: "BANKING" },
    { label: "CONSTRUCTION", value: "CONSTRUCTION" },
    { label: "CONSUMER", value: "CONSUMER" },
    { label: "EDUCATION", value: "EDUCATION" },
    { label: "ENGINEERING", value: "ENGINEERING" },
    { label: "ENERGY", value: "ENERGY" },
    { label: "OIL_AND_GAS", value: "OIL_AND_GAS" },
    {
      label: "FAST_MOVING_CONSUMER_GOODS",
      value: "FAST_MOVING_CONSUMER_GOODS",
    },
    { label: "FINANCIAL", value: "FINANCIAL" },
    { label: "FINTECH", value: "FINTECH" },
    { label: "FOOD_AND_BEVERAGE", value: "FOOD_AND_BEVERAGE" },
    { label: "GOVERNMENT", value: "GOVERNMENT" },
    { label: "HEALTHCARE", value: "HEALTHCARE" },
    { label: "HOSPITALITY", value: "HOSPITALITY" },
    { label: "INSURANCE", value: "INSURANCE" },
    { label: "LEGAL", value: "LEGAL" },
    { label: "MANUFACTURING", value: "MANUFACTURING" },
    { label: "MEDIA", value: "MEDIA" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "PROFESSIONAL SERVICES", value: "PROFESSIONAL SERVICES" },
    { label: "RAW_MATERIALS", value: "RAW_MATERIALS" },
    { label: "REAL_ESTATE", value: "REAL_ESTATE" },
    { label: "RELIGION", value: "RELIGION" },
    { label: "RETAIL", value: "RETAIL" },
    { label: "JEWELRY", value: "JEWELRY" },
    { label: "TECHNOLOGY", value: "TECHNOLOGY" },
    { label: "TELECOMMUNICATIONS", value: "TELECOMMUNICATIONS" },
    { label: "TRANSPORTATION", value: "TRANSPORTATION" },
    { label: "TRAVEL", value: "TRAVEL" },
    { label: "ELECTRONICS", value: "ELECTRONICS" },
    { label: "NOT_FOR_PROFIT", value: "NOT_FOR_PROFIT" },
  ],
  business_registration_identifier: [
    {
      label: "EIN",
      value: "EIN",
      description: "USA: Employer Identification Number (EIN)",
      mask: "99-9999999",
    },
    {
      label: "DUNS",
      value: "DUNS",
      mask: "99-999-9999",
      description:
        "USA: DUNS Number (Dun & Bradstreet) [NOTE: If your customer has a US entity or an International Tax ID, use EIN to register their customer profile to avoid brand registration failures. Do not use a DUNS number.]",
    },
    // {
    //   label: "CBN",
    //   value: "CBN",
    //   mask: "999999999",
    //   description: "Canada: Canadian Business Number (first 9 digits only)",
    // },
    // {
    //   label: "CN",
    //   value: "CN",
    //   description: "Great Britain: Company Number",
    // },
    // {
    //   label: "ACN",
    //   value: "ACN",
    //   description: "Australia: Company Number from ASIC (ACN)",
    // },
    // {
    //   label: "CIN",
    //   value: "CIN",
    //   description: "India: Corporate Identity Number",
    // },
    // {
    //   label: "VAT",
    //   value: "VAT",
    //   description: "Estonia: VAT Number",
    // },
    // {
    //   label: "VATRN",
    //   value: "VATRN",
    //   description: "Romania: VAT Registration Number",
    // },
    // {
    //   label: "RN",
    //   value: "RN",
    //   description: "Israel: Registration Number",
    // },
    // {
    //   label: "Other",
    //   value: "Other",
    //   description: "",
    // },
  ],
  business_regions_of_operation: [
    // { label: "Africa", value: "AFRICA" },
    // { label: "Asia", value: "ASIA" },
    // { label: "Europe", value: "EUROPE" },
    // { label: "Latin America", value: "LATIN_AMERICA" },
    { label: "USA and Canada", value: "USA_AND_CANADA" },
  ],

  business_identities: [
    { label: "Direct Customer", value: "direct_customer" },
    // { label: "ISV, Reseiler, or Partner", value: "isv_reseller_or_partner" },
    // { label: "I don't know", value: "unknown" },
  ],
  job_position: [
    { label: "Director", value: "Director" },
    { label: "GM", value: "GM" },
    { label: "VP", value: "VP" },
    { label: "CEO", value: "CEO" },
    { label: "CFO", value: "CFO" },
    { label: "General Counsel", value: "General Counsel" },
    { label: "Other", value: "Other" },
  ],
};

export const BRAND_REGISTRATION = {
  is_business_entity: [
    { label: "Yes", value: "Yes_business_entity" },
    { label: "No", value: "No_business_entity" },
  ],
  hasTaxID: [
    {
      label: "Yes, the business I’m registering has a tax ID",
      value: "Yes_has_tax_id",
    },
    {
      label: "No, the business I’m registering does not have a tax ID",
      value: "No_has_tax_id",
    },
  ],
  would_like_register_secondary: [
    {
      label: "Yes, I would like to register as a Secondary customer profile",
      value: "Yes_secondary",
    },
    {
      label: "No, I would not like to register as a Secondary customer profile",
      value: "No_secondary",
    },
  ],
  business_identities: [
    {
      label: "Direct Customer",
      value: "direct_customer",
      description:
        "My business uses Twilio to communicate internally or with our customers",
    },
    // {
    //   label: "ISV, Reseiler, or Partner",
    //   value: "isv_reseller_or_partner",
    //   description:
    //     "My business uses Twilio in a product that I sell to my customers",
    // },
    // { label: "I don't know", value: "unknown" },
  ],
  business_type: [
    { label: "Partnership", value: "Partnership" },
    {
      label: "Limited Liability Corporation",
      value: "Limited Liability Corporation",
    },
    { label: "Co-operative", value: "Co-operative" },
    { label: "Non-profit Corporation", value: "Non-profit Corporation" },
    { label: "Corporation", value: "Corporation" },
  ],
  business_registration_identifier: [
    {
      label: "EIN",
      value: "EIN",
      description: "USA: Employer Identification Number (EIN)",
      mask: "99-9999999",
    },
    // {
    //   label: "DUNS",
    //   value: "DUNS",
    //   mask: "99-999-9999",
    //   description:
    //     "USA: DUNS Number (Dun & Bradstreet) [NOTE: If your customer has a US entity or an International Tax ID, use EIN to register their customer profile to avoid brand registration failures. Do not use a DUNS number.]",
    // },
    // {
    //   label: "CBN",
    //   value: "CBN",
    //   mask: "999999999",
    //   description: "Canada: Canadian Business Number (first 9 digits only)",
    // },
    // {
    //   label: "CN",
    //   value: "CN",
    //   description: "Great Britain: Company Number",
    // },
    // {
    //   label: "ACN",
    //   value: "ACN",
    //   description: "Australia: Company Number from ASIC (ACN)",
    // },
    // {
    //   label: "CIN",
    //   value: "CIN",
    //   description: "India: Corporate Identity Number",
    // },
    // {
    //   label: "VAT",
    //   value: "VAT",
    //   description: "Estonia: VAT Number",
    // },
    // {
    //   label: "VATRN",
    //   value: "VATRN",
    //   description: "Romania: VAT Registration Number",
    // },
    // {
    //   label: "RN",
    //   value: "RN",
    //   description: "Israel: Registration Number",
    // },
    // {
    //   label: "Other",
    //   value: "Other",
    //   description: "",
    // },
  ],
  business_industry: [
    { label: "AUTOMOTIVE", value: "AUTOMOTIVE" },
    { label: "AGRICULTURE", value: "AGRICULTURE" },
    { label: "BANKING", value: "BANKING" },
    { label: "CONSTRUCTION", value: "CONSTRUCTION" },
    { label: "CONSUMER", value: "CONSUMER" },
    { label: "EDUCATION", value: "EDUCATION" },
    { label: "ENGINEERING", value: "ENGINEERING" },
    { label: "ENERGY", value: "ENERGY" },
    { label: "OIL AND GAS", value: "OIL AND GAS" },
    {
      label: "FAST MOVING CONSUMER GOODS",
      value: "FAST MOVING CONSUMER GOODS",
    },
    { label: "FINANCIAL", value: "FINANCIAL" },
    { label: "FINTECH", value: "FINTECH" },
    { label: "FOOD AND BEVERAGE", value: "FOOD AND BEVERAGE" },
    { label: "GOVERNMENT", value: "GOVERNMENT" },
    { label: "HEALTHCARE", value: "HEALTHCARE" },
    { label: "HOSPITALITY", value: "HOSPITALITY" },
    { label: "INSURANCE", value: "INSURANCE" },
    { label: "LEGAL", value: "LEGAL" },
    { label: "MANUFACTURING", value: "MANUFACTURING" },
    { label: "MEDIA", value: "MEDIA" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "PROFESSIONAL SERVICES", value: "PROFESSIONAL SERVICES" },
    { label: "RAW MATERIALS", value: "RAW_MATERIALS" },
    { label: "REAL ESTATE", value: "REAL ESTATE" },
    { label: "RELIGION", value: "RELIGION" },
    { label: "RETAIL", value: "RETAIL" },
    { label: "JEWELRY", value: "JEWELRY" },
    { label: "TECHNOLOGY", value: "TECHNOLOGY" },
    { label: "TELECOMMUNICATIONS", value: "TELECOMMUNICATIONS" },
    { label: "TRANSPORTATION", value: "TRANSPORTATION" },
    { label: "TRAVEL", value: "TRAVEL" },
    { label: "ELECTRONICS", value: "ELECTRONICS" },
    { label: "NOT FOR PROFIT", value: "NOT FOR PROFIT" },
  ],
  business_regions_of_operation: [
    // { label: "Africa", value: "AFRICA" },
    // { label: "Asia", value: "ASIA" },
    // { label: "Europe", value: "EUROPE" },
    // { label: "Latin America", value: "LATIN_AMERICA" },
    { label: "USA and Canada", value: "USA_AND_CANADA" },
  ],
  job_position: [
    { label: "Director", value: "Director" },
    { label: "GM", value: "GM" },
    { label: "VP", value: "VP" },
    { label: "CEO", value: "CEO" },
    { label: "CFO", value: "CFO" },
    { label: "General Counsel", value: "General Counsel" },
    { label: "Other", value: "Other" },
  ],
  add_another_authorized: [
    { label: "Yes", value: "Yes_add_another_authorized" },
    { label: "No", value: "No_add_another_authorized" },
  ],
  company_types: [
    { label: "Private", value: "Private" },
    { label: "Public", value: "Public" },
    { label: "US Non Profit", value: "us_non_profit" },
    { label: "US Government", value: "us_government" },
  ],

  are_527_politic: [
    { label: "Yes", value: "Yes_are_527_politic" },
    { label: "No", value: "No_are_527_politic" },
  ],

  brand_types: [
    {
      label: "Low-Volume Standart Brand",
      labelAdd: "$4 one-time fee",
      value: "low_value",
      description:
        "Recommended for customers sending fewer than 6,000 message segments per day to the US (2,000 message segments per day to T-Mobile)",
    },
    // {
    //   label: "Standart",
    //   value: "standart",
    //   labelAdd: "$44 one-time fee",
    //   description:
    //     "Recommended for customers sending messages at scale. Your daily limit may fall between 6,000 and 600,000 message segments per day to the US (2,000 - 200,000 per day to T-Mobile). Read more",
    // },
  ],
  stock_exchange: [
    {
      label: "AMEX: American Stock Exchange",
      value: "AMEX: American Stock Exchange",
    },
    {
      label: "AMX: Armeniz Securities Exchange",
      value: "AMX: Armeniz Securities Exchange",
    },
    {
      label: "ASX: Australian Securities Exchange",
      value: "ASX: Australian Securities Exchange",
    },
    { label: "B3: Brazil Stock Exchange", value: "B3: Brazil Stock Exchange" },
    {
      label: "BME: Bolsas y Mercados Espanoles",
      value: "BME: Bolsas y Mercados Espanoles",
    },
    {
      label: "BSE: AmericanStock Exchange",
      value: "BSE: AmericanStock Exchange",
    },
    {
      label: "FRA: Frankfurt Stock Exchange",
      value: "FRA: Frankfurt Stock Exchange",
    },
    {
      label: "ICEX: Indian Commodity Exchange",
      value: "ICEX: Indian Commodity Exchange",
    },
    { label: "JPX: Japan Exchange Group", value: "JPX: Japan Exchange Group" },
    {
      label: "JSE: Jamaica Stock Exchange",
      value: "JSE: Jamaica Stock Exchange",
    },
    { label: "KRX: Korea Exchange", value: "KRX: Korea Exchange" },
    {
      label: "LON: London Stock Exchange",
      value: "LON: London Stock Exchange",
    },
    { label: "NASDAQ", value: "NASDAQ" },
    {
      label: "NSE: National Stock Exchange of India Ltd",
      value: "NSE: National Stock Exchange of India Ltd",
    },
    {
      label: "NYSE: New York Stock Exchange",
      value: "NYSE: New York Stock Exchange",
    },
    { label: "None", value: "None" },
    { label: "OMX: NASDAQ Stockholm", value: "OMX: NASDAQ Stockholm" },
    {
      label: "SEHK: Stock Exchange of Hong Kong",
      value: "SEHK: Stock Exchange of Hong Kong",
    },
    {
      label: "SGX: Singapore Exchange Limited",
      value: "SGX: Singapore Exchange Limited",
    },
    {
      label: "SSE: Shanghai Stock Exchange",
      value: "SSE: Shanghai Stock Exchange",
    },
    {
      label: "STO: Stockholm Stock Exchange",
      value: "STO: Stockholm Stock Exchange",
    },
    { label: "SWX: Swiss Stock Exchange", value: "SWX: Swiss Stock Exchange" },
    {
      label: "SZSE: Shenzhen Stock Exchange",
      value: "SZSE: Shenzhen Stock Exchange",
    },
    {
      label: "TSX: Toronto Stock Exchange",
      value: "TSX: Toronto Stock Exchange",
    },
    {
      label: "TWSE: Taiwan Stock Exchange",
      value: "TWSE: Taiwan Stock Exchange",
    },
    {
      label: "VSE: Vadodara Stock Exchange Limited",
      value: "VSE: Vadodara Stock Exchange Limited",
    },
    { label: "Other", value: "Other" },
  ],
};

export const CAMPAIGN_REGISTRATION = {
  available_A2P_campaign_use_case: [
    {
      label: "Customer Care ($10.00/month)",
      value: "Customer Care",
      label_add: "Customer Care ($10.00/month)",
      desc: "Support, account management, and other avenues of customer interaction",
    },
  ],
  messaging_services: [
    {
      label: "Create new Messaging Service",
      value: "create_new",
      description:
        "Messaging Service will be created automatically with Campaign registration.",
    },
    { label: "Select existing Messaging Service", value: "select_exist" },
  ],
  existing_messaging_services: [
    {
      label:
        "Marketing A2P Messaging Service (MG4adfcef38833124a05ced39bdbc9764c)",
      value: "marketing_MG4adfcef38833124a05ced39bdbc9764c",
      id: "MG4adfcef38833124a05ced39bdbc9764c",
    },
    {
      label:
        "Customer A2P Messaging Service (MG4adfcef38833124a05ced39bdbc9ff00)",
      value: "customer_MG4adfcef38833124a05ced39bdbc9ff00",
      id: "MG4adfcef38833124a05ced39bdbc9ff00",
    },
    {
      label:
        "Customer A2P Messaging Service (MG4adfcef38833124a05ced39bdbc9e2e0)",
      value: "customer_MG4adfcef38833124a05ced39bdbc9e2e0",
      id: "MG4adfcef38833124a05ced39bdbc9e2e0",
    },
  ],
};

export const INIT_MY_AI_DATA = [
  {
    color: "#A8DADC",
    value: 0,
    label: "Personal",
    route: "/my-ai/personal",
  },
  {
    color: "#219EBC",
    value: 0,
    label: "Pricing",
    route: "/my-ai/pricing",
  },
  {
    color: "#8DC9E5",
    value: 0,
    label: "On boarding",
    route: "/my-ai/onboarding",
  },
  {
    color: "#DFAFA0",
    value: 0,
    label: "Custom Support",
    route: "/my-ai/custom-support",
  },
  {
    color: "#E53946",
    value: 0,
    label: "Product Info",
    route: "/my-ai/product-info",
  },
  {
    color: "#333333",
    value: 0,
    label: "Company Info",
    route: "/my-ai/company-info",
  },
  {
    color: "#FED5BA",
    value: 0,
    label: "Voice Training",
    route: "/my-ai/voice",
  },
  {
    color: "#1D527B",
    value: 0,
    label: "Recovers",
    route: "/my-ai/recovers",
  },
];

export const ORDER_TIMELINE = {
  all: { name: "All", icon: "bx bxs-home" },
  orderCreated: { name: "Order Placed", icon: "bx bxs-cart" },
  orderPaid: { name: "Order Payment Status", icon: "bx bxs-dollar-circle" },
  orderFulfilled: {
    name: "Order Fulfilled / Shipped",
    icon: "bx bxs-truck",
  },
  orderDelivered: { name: "Order Delivered", icon: "bx bxs-check-circle" },
  orderError: { name: "Order Error", icon: "bx bxs-error" },
  orderCancelled: { name: "Order Cancelled", icon: "bx bxs-minus-circle" },
};
