import React, { createRef } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, UncontrolledAlert } from "reactstrap";
//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

import ModalsHelperChat from "./components/Modals";
import ModalsCreateConversation from "./components/ModalCreateConversation";

import { createVcard, isUseThisNumber, custom_delay, phoneNumberFormatter } from "helpers/utility";

import {
  addNewCustomerContact,
  changeLoader,
  updateState,
} from "store/actions";
import BlockConvs from "./components/Block_conv";
import BlockMessages from "./components/BlockMessages";
import BlockTimeline from "./components/BlockTimeline";
import Breadcrumb from "components/Common/Breadcrumb";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    const profile = props.user;

    this._scrollRef_messages = createRef();

    this.state = {
      currentUser: {
        name: `${profile?.first_name} ${profile?.last_name}`,
        image: profile.image || avatar1,
        isActive: false,
      },

      search_Menu: false,
      settings_Menu: false,
      activeTab: "1",

      isMobileMode: {
        is: window.screen.width <= 991,
        openedList: "conversations", // messages
      },

      // ====
      modalAddConversation: {
        isOpen: false,
        phone: "",
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        error: null,
        loading: false,
        isAlreadyInUse: true,
      },
      modalAddVcard: {
        isOpen: false,
        photo: "",
        title: "",
        error: null,
        loading: false,
        selectedFile: null,
      },
      curMessage: {
        media: null,
        text: "",
        attributes: {},
        selectedFile: null,
        loading: false,
      },
    };

    this.createConversation = this.createConversation.bind(this);
    this.addMessageTo = this.addMessageTo.bind(this);
    this.changeConversation = this.changeConversation.bind(this);
    this.deleteCurrentConversation = this.deleteCurrentConversation.bind(this);
    this.sendVcard = this.sendVcard.bind(this);

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleTest = this.handleTest.bind(this);
  }

  componentDidMount() {
    this.scrollToBottom();
    this.props.onUpdateState({
      error: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.chat.messages.length !== this.props.chat.messages.length) {
      this.scrollToBottom();
    }
  }

  async checkIsAlreadyExistThisNumber() {
    return new Promise((res, rej) => {
      try {
        const modalConvState = this.state.modalAddConversation;
        console.log(modalConvState);

        const modifiedNumber = modalConvState.phone
          .replace(/\s/g, "")
          .replace(/[()-]/g, "");

        let isExistInCustomersList = this.props.contacts?.customers.find(
          (c) =>
            c.phone.replace(/\D/g, "") ===
            modalConvState.phone.replace(/\D/g, "")
        );

        console.log("isExistInCustomersList", isExistInCustomersList);

        const isInUse = isUseThisNumber(
          this.props.chat.conversations,
          modifiedNumber
        );
        if (isInUse) {
          this.changeConversation(isInUse);
          return res("already in use");
        }

        if (isExistInCustomersList) {
          return res({
            isExistInCustomersList,
            mes: "exist in customers list but do not exist in conversations",
          });
        }

        res();
      } catch (error) {
        console.log(error);
        res(null);
      }
    });
  }

  createConversation = async (e) => {
    e.preventDefault();

    const check = await this.checkIsAlreadyExistThisNumber();
    console.log("CHECK", check);
    if (
      check?.mes === "exist in customers list but do not exist in conversations"
    ) {
      this.setState({
        modalAddConversation: {
          ...this.state.modalAddConversation,
          error: {
            type: "info",
            message: `This number already exists in your customers list as "${check.isExistInCustomersList.first_name} ${check.isExistInCustomersList.last_name}"`,
          },
        },
      });
      return;
    }

    if (check === "already in use") {
      this.setState({
        modalAddConversation: {
          isOpen: false,
          phone: "",
          first_name: "",
          last_name: "",
          company: "",
          email: "",
          error: null,
          loading: false,
          isAlreadyInUse: true,
        },
      });
      return;
    }

    if (!check && this.state.modalAddConversation.isAlreadyInUse) {
      this.setState({
        modalAddConversation: {
          ...this.state.modalAddConversation,
          first_name: "",
          last_name: "",
          company: "",
          email: "",
          error: null,
          loading: false,
          isAlreadyInUse: false,
        },
      });
      return;
    }

    this.setState({
      modalAddConversation: {
        ...this.state.modalAddConversation,
        loading: true,
      },
    });
    const { phone, first_name, last_name, company, email } =
      this.state.modalAddConversation;

    await ClientConversation.createConversation({
      phone,
      first_name,
      last_name,
    })
      .then(async (r) => {
        this.setState({
          modalAddConversation: {
            isOpen: false,
            phone: "",
            first_name: "",
            last_name: "",
            company: "",
            email: "",
            error: null,
            loading: false,
          },
        });

        // send invitation
        const user = this.props.user;
        const { file_vcard, vcard_type, file_name } = await createVcard(
          user,
          user.image
        );

        const inviteText =
          "Hi " +
          first_name +
          " please add my vCard to your contacts by clicking on it and saving my information to your contact book";

        const curMessage = {
          text: inviteText,
          media: null,
          attributes: {},
          selectedFile: null,
        };

        await ClientConversation.sendMessage(curMessage)
          .then(async (messageIndexN) => {
            console.log("Invitation text messageIndex", messageIndexN);

            const curMessage = {
              text: "",
              media: file_vcard,
              attributes: {},
              selectedFile: {
                type: vcard_type,
                name: file_name,
              },
            };
            await custom_delay(1000);

            await ClientConversation.sendMessage(curMessage)
              .then(async (messageIndexN) => {
                console.log("Invitation VCARD messageIndex", messageIndexN);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });

        const payload = {
          first_name: first_name || "",
          last_name: last_name || "",
          company: company || "",
          phone: phone || "",
          email: email || "",
        };
        this.props.ONaddNewCustomerContact(payload);
      })
      .catch((e) => {
        console.log(e.message);

        this.setState({
          modalAddConversation: {
            ...this.state.modalAddConversation,
            error: {
              message: e.messages || "Something went wrong",
            },
            loading: false,
          },
        });
      });
  };
  addMessageTo = async () => {
    this.setState((pS) => ({
      ...pS,
      curMessage: { ...pS.curMessage, loading: true },
      // curMessage: { ...pS.curMessage, loading: false },
    }));

    await ClientConversation.sendMessage(this.state.curMessage)
      .then((messageIndexN) => {
        console.log("messageIndex", messageIndexN);
        this.setState({
          curMessage: {
            media: null,
            text: "",
            attributes: {},
            loading: false,
            selectedFile: null,
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState({ search_Menu: !this.state.search_Menu });
  };
  toggleSettings = () => {
    this.setState({ settings_Menu: !this.state.settings_Menu });
  };
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  scrollToBottom = () => {
    if (this._scrollRef_messages.current) {
      this._scrollRef_messages.current.scrollTop =
        this._scrollRef_messages.current.scrollHeight + 1000;
    }
  };
  searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };
  toggle = () => {
    if (this.state.activeTab === "1") {
      this.setState({
        modalAddConversation: {
          ...this.state.modalAddConversation,
          isOpen: !this.state.modalAddConversation.isOpen,
        },
      });
    }
    if (this.state.activeTab === "3") {
      this.setState({
        modalAddParticipiant: {
          ...this.state.modalAddParticipiant,
          isOpen: !this.state.modalAddParticipiant.isOpen,
        },
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      modalAddConversation: {
        ...this.state.modalAddConversation,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleAddMediaToMessage = async (e) => {
    e.preventDefault();
    try {
      const selectedFile = e.target.files[0];
      console.log("selectedFile", selectedFile);
      const reader = new FileReader();

      if (selectedFile.type === "text/plain") {
        reader.readAsText(selectedFile);
      } else {
        reader.readAsArrayBuffer(selectedFile);
      }

      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });
        console.log("Blob: ", blob);

        this.setState({
          curMessage: { ...this.state.curMessage, media: blob, selectedFile },
        });
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    } catch (error) {
      console.log(error);
    }
  };
  changeConversation = async (id) => {
    await ClientConversation.changeConversation(id);
    if (this.state.isMobileMode.is) {
      this.setState({
        isMobileMode: {
          ...this.state.isMobileMode,
          openedList:
            this.state.isMobileMode.openedList === "conversations"
              ? "messages"
              : "conversations",
        },
      });
    }
  };

  deleteCurrentConversation = async () => {
    await ClientConversation.deleteCurrentConversation();
  };

  sendVcard = async (e) => {
    try {
      e.preventDefault();
      const user = this.props.user;
      // console.log(this.state);
      // const { selectedFile, title } = this.state.modalAddVcard;

      // if (selectedFile) {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(selectedFile);
      //   reader.onload = () => {
      //     let PHOTO_base64 = reader.result.split(",")[1];
      //     this.createVcard(user, title, PHOTO_base64);
      //   };
      //   reader.onerror = function () {
      //     console.log(reader.error);
      //   };
      //   return;
      // }
      const { file_vcard, vcard_type, file_name } = await createVcard(
        user,
        user.image
      );

      this.setState((pS) => ({
        ...pS,
        curMessage: {
          ...pS.curMessage,
          media: file_vcard,
          selectedFile: {
            type: vcard_type,
            name: file_name,
          },
        },
        modalAddVcard: {
          isOpen: false,
          photo: "",
          title: "",
          error: null,
          loading: false,
          selectedFile: null,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  handleTest() {
    console.log("tst", this._scrollRef_messages);
    // this.scrollToBottom();
    this.scrollToBottom();
  }

  render() {
    const chat = this.props.chat;

    const fieldsForModalNewConv = [
      {
        title: "First name",
        type: "text",
        name: "first_name",
        placeholder: "",
        value: this.state.modalAddConversation.first_name,
      },
      {
        title: "Last name",
        type: "text",
        name: "last_name",
        placeholder: "",
        value: this.state.modalAddConversation.last_name,
      },
      {
        title: "Company",
        type: "text",
        name: "company",
        placeholder: "",
        value: this.state.modalAddConversation.company,
      },
      {
        title: "Email (optional)",
        type: "text",
        name: "email",
        placeholder: "",
        value: this.state.modalAddConversation.email,
      },
    ];

    const convProps = {
      chat,
      toggle: this.toggle,
      searchUsers: this.searchUsers,
      changeConversation: this.changeConversation,
      deleteCurrentConversation: this.deleteCurrentConversation,
      isMobMode: this.state.isMobileMode,
      currentUser: this.state.currentUser,
    };

    const messProps = {
      chat,
      user: this.props.user,
      curMessage: this.state.curMessage,
      sendVcard: this.sendVcard,
      handleAddMediaToMessage: this.handleAddMediaToMessage,
      addMessageTo: this.addMessageTo,
      _scrollRef_messages: this._scrollRef_messages,
      isMobMode: this.state.isMobileMode,
      isMobModeChange: () => {
        this.setState((pS) => ({
          ...pS,
          isMobileMode: {
            ...pS.isMobileMode,
            openedList:
              pS.isMobileMode.openedList === "conversations"
                ? "messages"
                : "conversations",
          },
        }));
      },
      handleInputMessText: (e) => {
        this.setState((pS) => ({
          ...pS,
          curMessage: {
            ...pS.curMessage,
            text: e.target.value,
          },
        }));
      },
    };

    const clientPhone = phoneNumberFormatter(this.props.chat?.conversationProxy?._internalState?.uniqueName);
    // const isAdmin = this.props?.user?.is_admin
 
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <Breadcrumb
              title="Chat"
              breadcrumbItems={this.props?.router?.location?.pathname}
            /> */}
            {chat.error && JSON.stringify(chat.error) !== "{}" && (
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
                onClick={() => {
                  this.props.onUpdateState({
                    error: null,
                  });
                }}
              >
                <i className="mdi mdi-block-helper me-2"></i>
                {chat.error?.body && chat.error?.body?.message}
              </UncontrolledAlert>
            )}

            <Row>
              <Col xxl="8" xl="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar me-lg-2">
                    <div>
                      {!this.state.isMobileMode.is && (
                        <BlockConvs {...convProps} />
                      )}
                      {this.state.isMobileMode.is && (
                        <>
                          {this.state.isMobileMode.openedList ===
                          "conversations" ? (
                            <BlockConvs {...convProps} />
                          ) : (
                            <BlockMessages {...messProps} />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {!this.state.isMobileMode.is && (
                    <BlockMessages {...messProps} />
                  )}
                </div>
              </Col>
              <Col xxl={4} xl={12}>
                <BlockTimeline clientPhone={clientPhone}/>
              </Col>
            </Row>
          </Container>
        </div>

        <ModalsCreateConversation
          loading={this.state.modalAddConversation.loading}
          isOpen={this.state.modalAddConversation.isOpen}
          error={this.state.modalAddConversation.error}
          onToggle={this.toggle}
          title={"Start a New Conversation"}
          onSubmit={this.createConversation}
          handleChange={this.handleChange}
          isAlreadyInUse={this.state.modalAddConversation.isAlreadyInUse}
          numberField={{
            title: "Mobile Number",
            type: "phone",
            name: "phone",
            placeholder: "Insert Phone",
            value: this.state.modalAddConversation.phone,
          }}
          closeModal={() =>
            this.setState({
              modalAddConversation: {
                isOpen: false,
                phone: "",
                first_name: "",
                last_name: "",
                company: "",
                email: "",
                error: null,
                loading: false,
                isAlreadyInUse: true,
              },
            })
          }
          fields={fieldsForModalNewConv}
        />
        <ModalsHelperChat
          loading={this.state.modalAddVcard.loading}
          isOpen={this.state.modalAddVcard.isOpen}
          error={this.state.modalAddVcard.error}
          onToggle={this.toggle}
          title={"Add custom Image and title to vcard file"}
          onSubmit={this.sendVcard}
          handleChange={(e) => {
            if (e.target.placeholder === "Insert Phone") {
              const selectedImage = e.target.files[0];
              console.log("selectedImage", selectedImage);
              this.setState({
                modalAddVcard: {
                  ...this.state.modalAddVcard,
                  [e.target.name]: e.target.value,
                  selectedFile: selectedImage,
                },
              });
            } else {
              this.setState({
                modalAddVcard: {
                  ...this.state.modalAddVcard,
                  [e.target.name]: e.target.value,
                },
              });
            }
          }}
          closeModal={() =>
            this.setState({
              modalAddVcard: {
                ...this.state.modalAddVcard,
                isOpen: false,
                photo: "",
                title: "",
                error: null,
                selectedFile: null,
              },
            })
          }
          fields={[
            {
              title: "Title",
              type: "text",
              name: "title",
              placeholder: "Input title for your card",
              value: this.state.modalAddVcard.title,
            },
            {
              title: "Photo",
              type: "file",
              name: "photo",
              placeholder: "Insert Phone",
              value: this.state.modalAddVcard.photo,
              selectedFile: this.state.modalAddVcard.selectedFile,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  user: state.Login.user,
  contacts: state.contacts,
});
const mapDispatchToProps = (dispatch) => ({
  ONaddNewCustomerContact: (payload) =>
    dispatch(addNewCustomerContact(payload)),
  onLoader: (payload) => dispatch(changeLoader(payload)),
  onUpdateState: (payload) => dispatch(updateState(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
