import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SalesRepMenu({ props, closeSidebar, my_ai_data }) {
  return (
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title">{props.t("Menu")}</li>
      {/* <li>
        <Link to="/dashboard" onClick={closeSidebar}>
          <i className="bx bx-home-circle"></i>
          <span>{props.t("Dashboard")}</span>
        </Link>
      </li> */}
      <li>
        <Link to="/chat" onClick={closeSidebar}>
          <i className="bx bx-chat"></i>
          <span>{props.t("Chat")}</span>
        </Link>
      </li>
      <li>
        <Link to="/voice-mail" onClick={closeSidebar}>
          <i className="bx bxs-archive-in"></i>
          <span>{props.t("Voice mails")}</span>
        </Link>
      </li>
      <li>
        <Link to="/customers" onClick={closeSidebar}>
          <i className="bx bxs-user-detail"></i>
          <span>{props.t("Customers")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/my-ai"
          onClick={closeSidebar}
          aria-expanded="false"
          aria-disabled="true"
        >
          <i className="bx bxs-user-detail"></i>
          <span>{props.t("MY AI")}</span>
        </Link>
        <ul className="sub-menu">
          {my_ai_data.map((training) => (
            <li key={training.label}>
              <Link to={training.route}>
                <span className="training_myai_label">
                  {props.t(training.label)}
                </span>{" "}
                <span>{training.value}%</span>
              </Link>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}
export default SalesRepMenu;
