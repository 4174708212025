import { UPDATE_STATE, CLEAR_CHAT } from "./actionTypes";

const INIT_STATE = {
  status: "",
  conversations: [],
  conversationProxy: null,
  messages: [],
  error: null,
  notifications: {},
  isPendingMessage: false,
};

const ChatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      const data = action.payload;

      const nState = { ...state };

      if (data.status) {
        nState.status = data.status;
      }

      if (data.messages) {
        if (data.messages.addNew) {
          nState.messages = [...nState.messages, data.messages.addNew];
        } else if (data.messages.updateLast) {
          nState.messages = nState.messages.map((m, ind) => {
            if (nState.messages.length - 1 === ind) {
              return data.messages.updateLast;
            }

            return m;
          });
        } else if (data.messages.removeByIndex) {
          nState.messages = nState.messages.filter(
            (m) => m.index !== data.messages.removeByIndex
          );
        } else {
          nState.messages = data.messages;
        }
      }

      if (data.conversations) {
        if (data.conversations.addNew) {
          nState.conversations = [
            ...nState.conversations,
            data.conversations.addNew,
          ];
        } else {
          nState.conversations = data.conversations;
        }
      }

      if (data.conversationProxy) {
        nState.conversationProxy = data.conversationProxy;
      }

      if (data.notifications) {
        if (data.notifications.addNewMessage) {
          nState.notifications = [
            ...nState.notifications,
            data.notifications.addNewMessage,
          ];
        } else {
          nState.notifications = data.notifications;
        }
      }

      if (data.hasOwnProperty("error")) {
        nState.error = data.error;
      }

      if (data.hasOwnProperty("isPendingMessage")) {
        nState.isPendingMessage = data.isPendingMessage;
      }

      return nState;

    case CLEAR_CHAT:
      return INIT_STATE;

    default:
      return state;
  }
};

export default ChatReducer;
