import React from "react";
import {
  Button,
  CardGroup,
  Card,
  CardBody,
  Col,
  Input,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardHeader,
  CardFooter,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";


export const Email = ({ id, onDoneEdit }) => {
  return (
    <div className="editBlock" id={id}>
      <div className="contentEdit">
        <h5 style={{ marginBottom: 20 }}>CONTENT</h5>
        <p>Subject: </p>
        <p>From: </p>

        <Button outline onClick={onDoneEdit}>
          Configure Content
        </Button>
      </div>

      <div className="settings">
        <h5 style={{ marginBottom: 20 }}>SETTINGS</h5>

        <div className="setting_item">
          <i className="mdi mdi-email-check" id={`sending`}></i>
          <p>Smart Sending</p>
          <p>ON</p>
        </div>
        <div className="setting_item">
          <i className="mdi mdi-go-kart-track" id={`sending`}></i>
          <p>UTM Tracking</p>
          <p>ON</p>
        </div>
        <div className="setting_item">
          <i className="mdi mdi-filter" id={`sending`}></i>
          <p>Additional Filters</p>
          <p>OFF</p>
        </div>
      </div>

      <div className="buttons">
        <Button outline onClick={onDoneEdit}>
          Done
        </Button>
      </div>
    </div>
  );
};
