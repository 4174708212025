import React from "react";
import { map } from "lodash";
import { Button, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import ConversationItem from "./conversation_item";

export default function BlockConvs({
  chat,
  toggle,
  searchUsers,
  changeConversation,
  deleteCurrentConversation,
  isMobMode,
  currentUser,
}) {
  let correctStatusIcon = "text-warning";
  if (chat.status === "Connected") {
    correctStatusIcon = "text-success";
  }
  if (chat.status === "Connecting..." || chat.status === "Disconnecting...") {
    correctStatusIcon = "text-warning";
  }
  if (chat.status === "Coldn't get chat token") {
    correctStatusIcon = "text-danger";
  }
  if (
    chat.status === "Disconnected" ||
    chat.status === "Denied. Failed to connect."
  ) {
    correctStatusIcon = "text-danger";
  }

  return (
    <>
      <div className="pb-4 border-bottom">
        <div className="d-flex">
          <div className="align-self-center me-3">
            <img
              src={currentUser.image}
              className="avatar-xs rounded-circle"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-size-15 mt-0 mb-1">{currentUser.name}</h5>
            <p className="text-muted mb-0">
              <i
                className={`mdi mdi-circle ${correctStatusIcon} align-middle me-2`}
              />
              Status: {chat.status}
            </p>
          </div>
        </div>
      </div>

      <Button
        type="button"
        color="primary"
        onClick={toggle}
        className="btn btn-primary"
        style={{ width: "100%", marginTop: 5 }}
      >
        <span className=" d-sm-inline-block me-2">
          Start a new Conversation
        </span>
      </Button>

      <div className="search-box chat-search-box py-1">
        <div className="position-relative">
          <Input
            onKeyUp={searchUsers}
            id="search-user"
            type="text"
            className="form-control"
            placeholder="Search..."
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>

      <div className="chat-leftsidebar-nav">
        <div>
          <h5 className="font-size-14 mb-1">Recent</h5>
          <ul className="list-unstyled chat-list" id="recent-list">
            <PerfectScrollbar
              style={{
                height: `calc(100vh - ${isMobMode.is ? "330px" : "400px"})`,
              }}
            >
              {map(chat.conversations, (conversation) => (
                <ConversationItem
                  key={conversation.sid}
                  conversation={conversation}
                  changeConv={(sid) => changeConversation(sid)}
                  chat={chat}
                  deleteCurrentConv={deleteCurrentConversation}
                />
              ))}
            </PerfectScrollbar>
          </ul>
        </div>
      </div>
    </>
  );
}
