export function optionsChart() {
  let delayed;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      //   duration: 1000,
      animateRotate: false,
      animateScale: true,
      // animateScale: (g) => {
      //   console.log("animateScale", g);
      //   if (g.index === 0) {
      //     return true
      //   }
      //   return false
      // },

      onProgress: function (context) {
        // console.log("onProgress", context);
      },

      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        // return 0

        // console.log(context);
        let delay = 0;
        if (
          context.type === "data" &&
          context.mode === "default" &&
          !delayed &&
          context.index === 0
        ) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return 0;
      },
    },
    scales: {
      r: {
        min: 0,
        max: 100,
        pointLabels: {
          display: true,
          centerPointLabels: true,
          backdropPadding: 10,
          font: {
            size: 14,
          },
        },
        angleLines: {
          display: true,
          color: "#000000",
          lineWidth: 1,
        },
        ticks: {
          display: false,
          //   callback: function (value, index, values) {
          //     return value + "%";
          //   },
          autoSkip: true,
          fontSize: 11,
          padding: 1,
          z: 2,
        },
        grid: {
          drawTicks: true,
          lineWidth: (g) => {
            if (g.index === 10) {
              return 1;
            }
            return 1;
          },
          tickWidth: 1,
          tickLength: 80,
          color: (g) => {
            if (g.index === 10) {
              return "#000000";
            }
            return "#E5E5E5";
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      datalabels: {
        formatter: function (value, context) {
          return Math.floor(value) + "%" || null;
        },
        anchor: "center",
        // backgroundColor: '#ffffff',
        align: "end",
        color: "#000000",
        font: { size: 15, weight: 800 },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (t, d) => {
            return t.raw + "%";
          },
          title: (t) => {
            return t[0].label;
          },
        },
      },
    },
  };

  return options;
}
