import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";

const VOICE_MAILS = [
  { id: "1", customer: "Brand Registration" },
  { id: "2", customer: "Campaign Registration" },
  { id: "3", customer: "Connect to Shopify" },
  { id: "4", customer: "Register as Sales Rep" },
];

function VoiceMail({ props, closeSidebar, my_ai_data, m_service_sid }) {
  return (
    <div className={`page-content`}>
      <Container fluid>
        <Breadcrumb
          title="Voice mails"
          breadcrumbItems={props?.router?.location?.pathname}
        />
        <Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-nowrap align-middle mb-0">
                <tbody>
                  {VOICE_MAILS.map((step) => (
                    <tr key={step.id}>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          <Link to="#" className="text-dark">
                            {step.customer}
                          </Link>
                        </h5>
                      </td>
                      <td style={{ width: "60px" }}>
                        <div className="d-flex gap-3" >
                          <Link
                            to="/chat"                            
                            className="text-primary"
                            onClick={() => {
                              // const userData = cellProps.row.original;
                              // const isUse = isUseThisNumber(
                              //   conversations,
                              //   userData.phone
                              // );
                              // // console.log(userData, "isUse", isUse);

                              // if (isUse) {
                              //   ClientConversation.changeConversation(
                              //     isUse,
                              //     userData.conversation_sid ? null : userData.id
                              //   );
                              // } else {
                              //   ClientConversation.createConversation({
                              //     phone: userData.phone,
                              //     first_name: userData.first_name || "",
                              //     last_name: userData.last_name || "",
                              //     contactID: userData.id,
                              //   });
                              // }
                            }}
                          >
                            <i
                              className="mdi mdi-send font-size-18"
                              id="go_to_chat"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="go_to_chat"
                            >
                              Go to Chat
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
export default withRouter(VoiceMail);
