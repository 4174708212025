import React, { useEffect, useState, useMemo } from "react";

import { Card } from "reactstrap";

const ItemFlowCreateCard = ({ card, onChoose }) => {
  return (
    <Card key={card.title} className="ItemFlowCreateCard" onClick={onChoose}>
      <h2>{card.trigger}</h2>
      <h3>{card.title}</h3>
      <p>{card.description}</p>
      <div>
        <div></div>
        <div>
          <i
            className="mdi mdi-email-check"
            style={{ cursor: "pointer" }}
            id={`sending`}
          ></i>
        </div>
      </div>
    </Card>
  );
};

export default ItemFlowCreateCard;
