import React, { useEffect } from "react";
import { UncontrolledTooltip, Spinner, Button } from "reactstrap";
import store from "store";
import { statusMessageColor } from "helpers/utility";
import LinkPreviewComponent from "components/Common/LinkPreview";

import { updateState } from "store/actions";

let countResent = 5;

const MessageText = ({ text }) => {
  if (text?.includes("https://")) {
    return <LinkPreviewComponent link={text} />;
  } else {
    return (
      <p
        style={{
          textAlign: "left",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        {text}
      </p>
    );
  }
};

const MessageMedia = ({ message }) => {
  if (message.media) {
    if (
      message.contentType === "text/plain" ||
      message.contentType === "application/pdf" ||
      message.contentType.includes("application") ||
      message.contentType === "text/vcard"
    ) {
      let icon_class = "mdi-file-image-outline";

      if (message.contentType === "text/vcard") {
        icon_class = "mdi-card-account-phone";
      }
      if (message.contentType === "application/pdf") {
        icon_class = "mdi-file-pdf-box";
      }

      return (
        <>
          <p
            className="chat-time mb-0"
            style={{ fontSize: 70 }}
            onClick={() => {
              window.location.href = message.media;
            }}
          >
            <i className={`mdi ${icon_class} me-1`} id="Imagetooltip"></i>
          </p>
          <p>{message.filename}</p>
        </>
      );
    }

    if (message.contentType?.includes("video")) {
      return (
        <>
          <video controls width={300}>
            <source src={message.media} type="video/webm" />
            <source src={message.media} type="video/mp4" />
            <source src={message.media} type="video/3gpp" />
            <source src={message.media} type="video/3gp" />
            Sorry, your browser doesn't support videos.
          </video>
          <p
            onClick={() => {
              window.location.href = message.media;
            }}
          >
            {message.filename}
          </p>
        </>
      );
    }

    if (message.contentType?.includes("image")) {
      return (
        <>
          <img
            width={300}
            src={message.media}
            className="img-thumbnail"
            alt=""
          />
          <p>{message.filename}</p>
        </>
      );
    }

    return <>EMPTY</>;
  } else {
    return <></>;
  }
};

const handleResentMessage = async (message) => {
  // console.log(message);

  let MEDIA = null;

  if (message.media) {
    const file = await fetch(message.media);
    // console.log("file", file);
    const fileBlob = await file.blob();
    // console.log("fileBlob", fileBlob);
    MEDIA = fileBlob;
  }
  const curMessage = {
    text: message.message,
    media: MEDIA,
    attributes: {},
    selectedFile: { type: message.contentType, name: message.filename },
  };

  // console.log(curMessage);

  await ClientConversation.sendMessage(curMessage)
    .then((messageIndexN) => {
      // console.log("messageIndex", messageIndexN);

      message.FULL_DATA_MESSAGE.remove().then((r) => {
        // console.log("deleted", r);
        store.dispatch(
          updateState({
            messages: {
              removeByIndex: message.index,
            },
          })
        );
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

const MessageStatusTime = ({ message, user }) => {
  const status = statusMessageColor(message);
  const isMessageLast = ClientConversation.isLastMessage(message.index);

  useEffect(() => {
    if (
      status.statusInfo === "undelivered" &&
      isMessageLast &&
      countResent > 0
    ) {
      handleResentMessage(message);
      countResent = countResent - 1;
    }
    if (status.statusInfo === "delivered") {
      countResent = 5;
    }
  }, [status.statusInfo]);

  return (
    <>
      <p className="chat-time mb-0">
        <i className={`bx bx-time-five me-1  ${status?.color || ""}`}></i>{" "}
        {message.time}
        {"  "}
        {message.email === user.email && (
          <>
            {!status || status.message === "wait" ? (
              <Spinner tag="span" className="" color="primary" size="sm" />
            ) : (
              <>
                <i
                  id={`status_message_${message?.id}`}
                  className={`mdi mdi-alert-box me-1 ${status?.color || ""}`}
                  style={{ marginLeft: 5 }}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`status_message_${message?.id}`}
                >
                  {status?.message}
                </UncontrolledTooltip>
                {status.statusInfo === "undelivered" &&
                  isMessageLast &&
                  countResent === 0 && (
                    <Button
                      outline
                      size="sm"
                      // type="button"
                      color="info"
                      onClick={() => {
                        handleResentMessage(message);
                        countResent = 5;
                      }}
                    >
                      Resent
                    </Button>
                  )}
              </>
            )}
          </>
        )}
      </p>
    </>
  );
};

function MessageItem({ message, user }) {
  // console.log(message, user);

  let mesSender = message.sender;

  if (message.email === user.email) {
    mesSender = user.first_name + " " + user.last_name;
  }
  if (message.attributes?.campaignId) {
    mesSender = "Campaign message";
  }

  return (
    <li
      key={"test_k_" + message.id}
      className={message.email === user.email ? "right" : ""}
    >
      <div className="conversation-list">
        <div className="ctext-wrap">
          <div className="conversation-name">{mesSender}</div>

          <MessageMedia message={message} />
          <MessageText text={message?.message} />
          <MessageStatusTime message={message} user={user} />
        </div>
      </div>
    </li>
  );
}

export default React.memo(MessageItem);
