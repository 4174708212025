import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table, Input } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";

//redux
import { useSelector, useDispatch } from "react-redux";

const testDta = [
  {
    month: "current",
    status: "unpaid",
    year: "",
    amount: "$33.22",
  },
  {
    month: "July",
    status: "paid",
    year: "2023",
    amount: "$133.65",
  },
  {
    status: "paid",
    month: "May",
    year: "2023",
    amount: "$456.16",
  },
];

const BillingHistoryCompany = (props) => {
  const dispatch = useDispatch(); 

  const params = props.router.params;
  // useEffect(() => {
  //   if (params && params.id) {
  //     dispatch(getBillingDetail(params.id));
  //   } else {
  //     dispatch(getBillingDetail(1)); //remove this after full integration
  //   }
  // }, [dispatch, getBillingDetail]);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };
  const searchUsers = () => {};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Billing History Company" breadcrumbItems={props?.router?.location?.pathname} />
          <div className="d-flex flex-row">
            <div className="search-box py-2">
              <div className="position-relative">
                <Input
                  onKeyUp={searchUsers}
                  id="search-user"
                  type="text"
                  className="form-control"
                  placeholder="Month"
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
            <div className="search-box py-2">
              <div className="position-relative">
                <Input
                  onKeyUp={searchUsers}
                  id="search-user"
                  type="text"
                  className="form-control"
                  placeholder="Year"
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
          </div>

          <PerfectScrollbar
            style={{ height: "calc(100vh - 280px)", backgroundColor: "#fff" }}
          >
            <Table className="table-nowrap">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {map(testDta, (item, key) => (
                  <tr key={key}>
                    <td>
                      {item.month} {item.year}
                    </td>
                    <td>{item.status}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BillingHistoryCompany);
