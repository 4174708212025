import { UPDATE_STATE, CLEAR_CHAT } from "./actionTypes";

export const updateState = (data) => ({
  type: UPDATE_STATE,
  payload: data,
});

export const clearChat = () => ({
  type: CLEAR_CHAT,
  payload: {},
});
