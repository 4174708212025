import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);

  const { first_name, last_name, image, is_admin } = props.user;

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={image || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {first_name} {last_name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag="a"
            onClick={() => props.router.navigate("/profile")}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {is_admin !== 0 && (
            <DropdownItem
              tag="a"
              onClick={() => props.router.navigate("/landing-constructor")}
            >
              <i className="mdi mdi-monitor-eye font-size-16 align-middle me-1" />
              {props.t("Landing Page")}{" "}
            </DropdownItem>
          )}
          {is_admin === 2 && (
            <DropdownItem
              tag="a"
              onClick={() => props.router.navigate("/a2p-profile/my")}
            >
              <i className="mdi mdi-monitor-eye font-size-16 align-middle me-1" />
              {props.t("A2P-10DLC Profile")}{" "}
            </DropdownItem>
          )}
          {is_admin !== 0 && is_admin !== 2 &&(
            <DropdownItem
              tag="a"
              onClick={() => props.router.navigate("/brand-registration/my")}
            >
              <i className="mdi mdi-monitor-eye font-size-16 align-middle me-1" />
              {props.t("Brand Registration")}{" "}
            </DropdownItem>
          )}
          {is_admin !== 0 && is_admin !== 2 &&(
            <DropdownItem
              tag="a"
              onClick={() => props.router.navigate("/campaign-registration/my")}
            >
              <i className="mdi mdi-monitor-eye font-size-16 align-middle me-1" />
              {props.t("Campaign Registration")}{" "}
            </DropdownItem>
          )}
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
