import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";


//<span className="badge rounded-pill badge-soft-warning font-size-11">Pending</span>
//<span className="badge rounded-pill badge-soft-success font-size-11">Complete</span>
//<span className="badge rounded-pill badge-soft-secondary font-size-11">Waiting</span>
//<span className="badge rounded-pill badge-soft-primary font-size-11">Approved</span>

const STEPS = [
  { step: "1", stepName: "Brand Registration", status: "Begin", statusType: 'secondary' },
  { step: "2", stepName: "Campaign Registration", status: "Pending", statusType: 'warning' },
  { step: "3", stepName: "Connect to Shopify", status: "Complete", statusType: 'success' },
  { step: "4", stepName: "Register as Sales Rep", status: "Approved", statusType: 'primary' },

];

function OnBoardingFlow({ props, closeSidebar, my_ai_data, m_service_sid }) {
  return (
    <div className={`page-content`}>
      <Container fluid>
        <Breadcrumb
          title="Onboarding Flow"
          breadcrumbItems={props?.router?.location?.pathname}
        />
        <Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-nowrap align-middle mb-0">
                <tbody>
                  {STEPS.map((step) => (
                    <tr key={step.step}>
                      <td style={{ width: "40px" }}>
                        <div className="form-check font-size-16">
                          {step.step}
                        </div>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          <Link to="#" className="text-dark">
                            {step.stepName}
                          </Link>
                        </h5>
                      </td>
                      <td>
                        <div className="text-center">
                          <span className={`badge rounded-pill badge-soft-${step.statusType} font-size-11`}>
                            {step.status}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
export default withRouter(OnBoardingFlow);
