import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Loader from "./loader/reducer";


// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Billing
import billing from "./billing/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//my_ai
import my_ai from "./my_ai/reducer";

//flow
import flow from "./flow/reducer";

//campaings
import campaings from "./campaings/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Loader,
  Login,
  // ForgetPassword,
  // Profile,
  // ecommerce,
  // calendar,
  chat,
  my_ai,
  flow,
  campaings,
  billing,
  // mails,
  // crypto,
  invoices,
  // JobReducer,
  // projects,
  // tasks,
  contacts,
  Dashboard,
  // DashboardSaas,
  // DashboardCrypto,
  // DashboardBlog,
  // DashboardJob
});

export default rootReducer;
