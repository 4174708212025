import React, { useEffect, useState, useMemo } from "react";

import {
  Button,
  CardGroup,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardHeader,
  CardFooter,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";
// import { connect } from "react-redux";
import { Link } from "react-router-dom";

import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import { Flow, Type, Value, ValueinUSD, Status, Revenue } from "./flowItem";

import TableContainer from "../../components/Common/TableContainer";

import logoImg from "../../assets/images/logo.svg";

import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getFlowsAction, deleteFlowAction } from "store/actions";

const Flows = (props) => {
  const { router } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  useEffect(() => {
    dispatch(getFlowsAction());
  }, []);

  const { flows_list_active } = useSelector(
    (state) => ({
      flows_list_active: state.flow.flows_list_active,
    }),
    shallowEqual
  );

  const handleDeleteFlow = (id) => {
    dispatch(deleteFlowAction(id));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div
            // style={{}}
            // onClick={() => {
            //   const flowData = cellProps.row.original;
            //   console.log("tap flow label", flowData);
            // }}
            // id="hover"
            >
              <Flow {...cellProps} />
            </div>
          );
        },
      },
      {
        Header: "Trigger",
        accessor: "trigger",
        filterable: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Last Updated",
        accessor: "modified",
        filterable: true,
        Cell: (cellProps) => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const flowData = cellProps.row.original;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/flows/edit/${flowData.id}`}
                state={flowData}
                className="text-success"
                onClick={() => {}}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteFlow(flowData.id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleCreateFlow = () => {
    router.navigate(`/flows/create`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Flows"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={flows_list_active}
                    isGlobalFilter={true}
                    isAddOptions={"Create Flow"}
                    customPageSize={10}
                    handleOrderClicks={handleCreateFlow}
                  />
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Keep It Up</h4>
                  <h6 className=" mb-3">
                    Drive up revenue and engagement with key automations that
                    are pre-built and ready to turn on!
                  </h6>

                  <Row className="mb-4">
                    <CardGroup style={{ overflow: "hidden" }}>
                      {EXAMPLE_FLOWS.map((card) => (
                        <Card
                          key={card.title}
                          style={{
                            margin: 10,
                            border: "1px solid #000",
                            borderRadius: 10,
                            width: 400,
                          }}
                          className="overflow-hidden"
                        >
                          <div className="bg-primary bg-soft">
                            <Row>
                              <Col className="col-7">
                                <div className="text-primary p-4"></div>
                              </Col>
                            </Row>
                          </div>
                          <CardBody
                            style={{
                              borderBottom: "1px solid #000",
                              margin: 15,
                            }}
                          >
                            <div>
                              <Link to="#">
                                <div className="avatar-md profile-user-wid mb-4">
                                  <span className="avatar-title rounded-circle bg-light">
                                    <img
                                      src={logoImg}
                                      alt=""
                                      className="rounded-circle"
                                      height="34"
                                    />
                                  </span>
                                </div>
                              </Link>
                            </div>

                            <CardTitle>{card.title}</CardTitle>
                            <CardSubtitle>{card.description}</CardSubtitle>
                          </CardBody>
                          <CardFooter>
                            <Row>
                              <Col xs={1}>
                                <img src={logoImg} alt="" height="14" />
                              </Col>
                              <Col xs={11}>{card.description_2}</Col>
                            </Row>
                            <Button
                              outline
                              type="button"
                              color="primary"
                              className="w-md"
                              // onClick={() => handleFilter()}
                            >
                              Get Started
                            </Button>
                          </CardFooter>
                        </Card>
                      ))}
                    </CardGroup>
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Flows);
