import { Client } from "@twilio/conversations";
import store from "store";
import { getChatToken, hashCreate } from "./API_hendlers";

import {
  updateState,
  getCustomersContact,
  getUsers,
  changeLoader,
  updateCustomerContact,
} from "store/actions";

import moment from "moment";

import {
  updateTimeInNotifications,
  removeOpenedConvFromNotific,
} from "./utility";

export default class ClientConversation {
  constructor() {
    this.conversationProxy = null;
    this.conversationsClient = null;
    this.intervalId = {};

    this.createConversation = this.createConversation.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeConversation = this.changeConversation.bind(this);
    this.deleteCurrentConversation = this.deleteCurrentConversation.bind(this);
    this.getMedia = this.getMedia.bind(this);
    this.getLastMessage = this.getLastMessage.bind(this);

    this.ChatToken();
    updateTimeInNotifications("start");
    onbeforeunload = () => {
      localStorage.setItem("lastSeen", Date.now());
    };
  }

  ChatToken() {
    getChatToken()
      .then((r) => {
        // console.log(r);
        if (r.chatToken) {
          this.initClient(r.chatToken);
          this.proxyAddress = store.getState().Login?.user?.phone;

          // console.log("CREATED TWILIO SUCCESS", r.chatToken);
          console.log("CREATED TWILIO SUCCESS");
        }
        // if (is_admin) {
        //   store.dispatch(getUsers());
        // } else {
        //   store.dispatch(getCustomersContact());
        // }
      })
      .catch((e) => {
        console.log("ERROR GET CHAT TOKEN,", e);
        store.dispatch(updateState({ status: "Coldn't get chat token" }));
      })
      .finally(() => {
        const is_admin = store.getState()?.Login?.user?.is_admin;
        console.log("is_admin", is_admin);
        if (is_admin === 0) {
          store.dispatch(getCustomersContact());
        }
        if (is_admin === 1) {
          store.dispatch(getUsers());
        }
        if (is_admin === 2) {
          store.dispatch(getCustomersContact());
          store.dispatch(getUsers());
        }
      });
  }

  close() {
    return new Promise(async (res, rej) => {
      if (this.conversationsClient) {
        const shutdownClient = await this.conversationsClient.shutdown();
        console.log("shutdownClient", shutdownClient);
        res("shutdown");
      }
    });
  }

  initClient(chatToken) {
    this.conversationsClient = new Client(chatToken)
      .on("messageAdded", async (message) => {
        console.log("NEW MESSAGE", message);

        const isInChatPage = window.location.href.includes("/chat");

        if (
          message.conversation.sid === this.conversationProxy.sid &&
          isInChatPage
        ) {
          store.dispatch(
            updateState({
              messages: { addNew: await this.messageTemplate(message) },
            })
          );
          return;
        } else {
          this.setNotifications(message);
        }
      })
      .on("connectionStateChanged", async (state) => {
        if (state === "connecting")
          store.dispatch(updateState({ status: "Connecting..." }));
        if (state === "disconnecting")
          store.dispatch(updateState({ status: "Disconnecting..." }));
        if (state === "disconnected")
          store.dispatch(updateState({ status: "Disconnected" }));
        if (state === "denied")
          store.dispatch(updateState({ status: "Denied. Failed to connect." }));
        if (state === "connected") {
          let conversations =
            await this.conversationsClient.getSubscribedConversations();

          this.getNotificationsOnInit(conversations);

          if (conversations.items.length === 0)
            store.dispatch(updateState({ status: "Connected" }));

          // const sortConv = conversations.items.sort(async (convA, convB) => {
          //   const lastM_convA = convA.lastMessage;
          //   // const messages_convA = await convA.getMessages(
          //   //   2,
          //   //   lastM_convA.index
          //   // );

          //   const lastM_convB = convB.lastMessage;
          //   // const messages_convB = await convB.getMessages(
          //   //   2,
          //   //   lastM_convA.index
          //   // );

          //   // console.log(lastM_convA, "messages_convA", messages_convA);
          //   // console.log(lastM_convB, "messages_convB", messages_convB);

          //   const lastMessageTime_A = new Date(
          //     lastM_convA?.dateCreated
          //   ).getTime();
          //   const lastMessageTime_B = new Date(
          //     lastM_convB?.dateCreated
          //   ).getTime();

          //   if (lastMessageTime_A < lastMessageTime_B) {
          //     return -1;
          //   }
          //   if (lastMessageTime_A > lastMessageTime_B) {
          //     return 1;
          //   }
          //   return 0;
          // });

          console.log("conversations", conversations)
          let lastConversation =
            conversations.items[conversations.items.length - 1];

          let lastConvTime = 0;

          for (let i = 0; i < conversations.items.length; i++) {
            const conversation = conversations.items[i];
            if (!conversation.lastMessage) continue;
            const lastMessageTime = new Date(
              conversation.lastMessage?.dateCreated
            ).getTime();
            if (lastMessageTime > lastConvTime) {
              lastConversation = conversation;
              lastConvTime = lastMessageTime;
            } else {
              continue;
            }
          }

          this.conversationProxy = lastConversation;

          const messagesList = await this.getMessages(lastConversation);

          store.dispatch(
            updateState({
              status: "Connected",
              messages: messagesList,
              conversations: [...conversations.items],
              conversationProxy: lastConversation,
            })
          );
        }
      })
      .on("stateChanged", (state) => {
        // console.log("stateChanged", state);
      })
      .on("conversationLeft", (thisConversation) => {
        // console.log("conversationLeft", thisConversation);
      });
  }

  notificationTemplate = (message) => {
    return {
      title: message.conversation.friendlyName || message.state.author,
      text: message.state.body,
      isMedia: message.state.media ? true : false,
      time: message.state.timestamp,
      timeFromNow: moment(message.state.timestamp).fromNow(),
      icon: null,
      messageSID: message.state.sid,
      phone: message.conversation.uniqueName,
      convSID: message.conversation.sid,
    };
  };

  messageTemplate = async (ms) => {
    const MEDIA = await this.getMedia(ms);
    const msState = ms.state;

    return {
      id: msState.sid,
      email: msState.author,
      sender: ms.conversation.friendlyName || msState.author,
      message: msState.body,
      time: new Date(msState.timestamp).toLocaleTimeString(),
      index: msState.index,
      type: msState.type,
      media: MEDIA,
      contentType: msState?.media?.state?.contentType,
      filename: msState?.media?.state?.filename,
      detailedDeliveryReceipts: await ms.getDetailedDeliveryReceipts(),
      attributes: msState.attributes,
      FULL_DATA_MESSAGE: ms,
    };
  };

  setNotifications = (message) => {
    const notif = this.notificationTemplate(message);

    store.dispatch(
      updateState({
        notifications: notif,
      })
    );

    const fullNotifFromLocalStorage = localStorage.getItem("notifications");
    if (fullNotifFromLocalStorage) {
      const nots = JSON.parse(fullNotifFromLocalStorage);
      const isThisNotConv = nots.find((not) => not.convSID === notif.convSID);
      let newNots = [];
      if (isThisNotConv) {
        newNots = nots.map((not) => {
          if (not.convSID === notif.convSID) {
            return notif;
          }
          return not;
        });
      } else {
        newNots = [...nots, notif];
      }

      localStorage.setItem("notifications", JSON.stringify(newNots));
    } else {
      localStorage.setItem("notifications", JSON.stringify([notif]));
    }
  };

  getLastMessageStatusInterval = (messageIndexN, curMessage) => {
    this.intervalId[`mIndex_${messageIndexN}`] = setInterval(
      this.getLastMessage,
      1000,
      messageIndexN,
      curMessage
    );
  };

  getLastMessage = async (messageIndexN, curMessage) => {
    const lastM = this.conversationProxy.lastMessage;
    const messages = await this.conversationProxy.getMessages(1, lastM.index);
    if (messages.items.length === 0) return;
    const lastMessageData = messages.items[0];
    const detailedDeliveryReceipts =
      await lastMessageData.getDetailedDeliveryReceipts();
    const status = detailedDeliveryReceipts?.[0]?.status;
    // console.log("status", status);

    if (!status) {
      clearInterval(this.intervalId[`mIndex_${messageIndexN}`]);
    }

    if (status && (status === "delivered" || status === "undelivered")) {
      clearInterval(this.intervalId[`mIndex_${messageIndexN}`]);
      store.dispatch(
        updateState({
          isPendingMessage: false,
          messages: {
            updateLast: await this.messageTemplate(lastMessageData),
          },
        })
      );
    }
  };

  isLastMessage = (index) => {
    return this.conversationProxy.lastMessage.index === index;
  };

  getNotificationsOnInit = async (conversations) => {
    // console.log("conversations", conversations);
    const lastSeen = localStorage.getItem("lastSeen");
    if (!lastSeen || conversations.items.length === 0) return;

    const allPromises = [];
    for (let i = 0; i < conversations.items.length; i++) {
      const conversation = conversations.items[i];
      if (!conversation.lastMessage) continue;

      allPromises.push(
        new Promise(async (res, rej) => {
          const lastMessageTime = new Date(
            conversation.lastMessage?.dateCreated
          ).getTime();

          // console.log("lastMessage ", conversation.sid, lastMessageTime);

          if (+lastSeen < lastMessageTime) {
            // if (1689841007574 < lastMessageTime) {
            const messages = await conversation.getMessages();
            if (messages.items.length === 0) return res();
            const lastMessageData = messages.items.find(
              (m) => m.index === conversation.lastMessage.index
            );
            if (lastMessageData) {
              return res(this.notificationTemplate(lastMessageData));
            }
          }
          res();
        })
      );
    }

    Promise.all(allPromises).then((messagesList) => {
      const notifs = messagesList.filter((n) => n);

      if (notifs.length === 0) return;

      const fullNotifFromLocalStorage = localStorage.getItem("notifications");

      if (fullNotifFromLocalStorage) {
        const nots = JSON.parse(fullNotifFromLocalStorage);

        let newNots = [...nots];

        for (let y = 0; y < notifs.length; y++) {
          const notif = notifs[y];

          let isThisNotConv = null;

          if (newNots.length > 0) {
            isThisNotConv = nots.find((not) => not?.convSID === notif.convSID);
          }

          if (isThisNotConv) {
            newNots = newNots.map((not) => {
              if (not.convSID === notif.convSID) {
                return notif;
              }
              return not;
            });
          } else {
            newNots = [...newNots, notif];
          }
        }

        localStorage.setItem("notifications", JSON.stringify(newNots));
      } else {
        localStorage.setItem("notifications", JSON.stringify(notifs));
      }

      store.dispatch(
        updateState({
          notifications: { ...notifs[0] },
        })
      );
    });
  };

  getMessages = (conversation) => {
    return new Promise(async (res) => {
      try {
        if (!conversation) {
          return res([]);
        }

        const messages = await conversation.getMessages();

        if (messages.items.length === 0) return res([]);
        const allPromises = [];
        messages.items.forEach((ms) => {
          allPromises.push(
            new Promise(async (res, rej) => {
              try {
                res(await this.messageTemplate(ms));
              } catch (error) {
                console.log(error);
                rej(error);
              }
            })
          );
        });

        Promise.all(allPromises)
          .then((messagesList) => res(messagesList))
          .catch((e) => {
            console.log("getMessages:", e);
            store.dispatch(changeLoader(false));
            store.dispatch(
              updateState({
                error: e,
              })
            );
          });
      } catch (error) {
        console.log("ERROR getMessages", error);
        store.dispatch(changeLoader(false));
        res([]);
      }
    });
  };

  createConversation = async ({ phone, first_name, last_name, contactID }) => {
    return new Promise(async (res, rej) => {
      try {
        const fN = `${first_name} ${last_name}`;
        console.log(phone, first_name, last_name);

        const newConv = await this.conversationsClient.createConversation({
          attributes: {},
          friendlyName: fN,
          uniqueName: phone,
        });
        console.log("newConv", newConv);
        await newConv.join();
        await newConv.addNonChatParticipant(this.proxyAddress, phone);
        this.conversationProxy = newConv;

        if (contactID) {
          store.dispatch(
            updateCustomerContact(contactID, { conversation_sid: newConv.sid })
          );
        }

        store.dispatch(
          updateState({
            messages: [],
            conversations: { addNew: newConv },
            conversationProxy: newConv,
          })
        );
        res();
      } catch (error) {
        console.log("Error createConversation", error);
        store.dispatch(changeLoader(false));
        store.dispatch(
          updateState({
            error: error,
          })
        );
        // rej(error);
      }
    });
  };

  updateConvFriendlyName = (phone, newFriendlyName) => {
    console.log(phone, newFriendlyName);
    return new Promise(async (res, rej) => {
      try {
        const convForChangeName =
          await this.conversationsClient.getConversationByUniqueName(phone);

        await convForChangeName.updateFriendlyName(newFriendlyName);

        console.log("updated successfull");
      } catch (error) {
        console.log("ERROR updateConvFriendlyName", error);
      }
    });
  };

  sendMessage = (curMessage) => {
    return new Promise(async (res, rej) => {
      try {
        store.dispatch(updateState({ isPendingMessage: true }));
        const { text, media, attributes, selectedFile } = curMessage;

        console.log("sendMessage", text, media, attributes, selectedFile);

        const uniqueName = this.conversationProxy.uniqueName;

        console.log("uniqueName", uniqueName);

        const changeToConv = store
          .getState()
          .contacts.customers.find((conv) => conv.phone === uniqueName);

        let modifText = text;

        if (text.includes("https://") && !text.includes("hashCoolCloud=")) {
          if (changeToConv) {
            await hashCreate({ contact_id: changeToConv.id, details: {} })
              .then((r) => {
                console.log(r);
                modifText = text + "?" + "hashCoolCloud=" + r.hash.value;
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }

        console.log("modifText", modifText);

        let messageIndexN;

        if (media) {
          const sendMediaOptions = {
            contentType: selectedFile.type,
            filename: selectedFile.name,
            media: media, // string | Blob | Buffer
          };

          messageIndexN = await this.conversationProxy
            .prepareMessage()
            .addMedia(sendMediaOptions)
            .setBody(modifText)
            .buildAndSend();
        } else {
          messageIndexN = await this.conversationProxy
            .prepareMessage()
            .setBody(modifText)
            .buildAndSend();
        }
        this.getLastMessageStatusInterval(messageIndexN, {
          ...curMessage,
          text: modifText,
        });

        res(messageIndexN);
      } catch (error) {
        rej(error);
      }
    });
  };

  clearingAllIntervals = () => {
    // console.log("clearingAllIntervals", this.intervalId);

    for (const key in this.intervalId) {
      // console.log(key);
      clearInterval(this.intervalId[key]);
    }
    this.intervalId = {};
  };

  changeConversation = async (SID, contactID) => {
    // clearInterval(this.intervalId);
    this.clearingAllIntervals();

    try {
      store.dispatch(changeLoader(true));
      // console.log("changeConversation to", SID);
      const changeToConv = store
        .getState()
        .chat.conversations.find((conv) => conv.sid === SID);
      if (!changeToConv) {
        store.dispatch(changeLoader(false));
        return;
      }
      const messagesList = await this.getMessages(changeToConv);

      // console.log("changeToConv", changeToConv);

      this.conversationProxy = changeToConv;

      if (contactID) {
        store.dispatch(
          updateCustomerContact(contactID, { conversation_sid: SID })
        );
      }

      store.dispatch(
        updateState({
          messages: messagesList,
          conversationProxy: changeToConv,
        })
      );
      changeToConv.setAllMessagesRead();
      removeOpenedConvFromNotific(changeToConv.sid);
      store.dispatch(changeLoader(false));
    } catch (error) {
      console.log("ERROR changeConversation", error);
      store.dispatch(changeLoader(false));
    }
  };

  deleteCurrentConversation = async () => {
    try {
      const allConv = store.getState().chat.conversations;

      await this.conversationProxy
        .delete()
        .then(async (deleted) => {
          console.log("deleted: ", deleted.friendlyName, deleted.uniqueName);
          const newCurrentCOnv = allConv[allConv.length - 2] || null;
          let messagesList = [];
          if (newCurrentCOnv) {
            messagesList = await this.getMessages(newCurrentCOnv);
          }
          store.dispatch(
            updateState({
              messages: messagesList,
              conversations: [
                ...allConv.filter(
                  (it) => it.sid !== this.conversationProxy.sid
                ),
              ],
              conversationProxy: newCurrentCOnv,
            })
          );
        })
        .catch((e) => {
          console.log("deleteCurrentConversation", e);
        });
    } catch (error) {
      console.log("deleteCurrentConversation", error);
    }
  };

  getMedia = async (message) => {
    try {
      const media = message.attachedMedia;
      if (media.length === 0) return null;
      const categorizedMedia = await message.getMediaByCategories(["media"]);
      const mediaUrl = await categorizedMedia[0].getContentTemporaryUrl();
      return mediaUrl;
    } catch (error) {
      console.log("getMedia", error);
    }
  };
}
