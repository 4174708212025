import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  Spinner,
} from "reactstrap";

import { formatBytes } from "helpers/utility";

export default function ModalsHelperChat({
  isOpen,
  onToggle,
  title,
  onSubmit,
  handleChange,
  fields,
  error,
  closeModal,
  loading,
}) {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} tag="h4">
        {title}
      </ModalHeader>
      {error && <Alert color="danger">{error?.message}</Alert>}
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12}>
              {fields.map((field) => {
                let preview = { src: "", name: "", size: 0 };
                if (field.type === "file" && field.selectedFile) {
                  preview.src = URL.createObjectURL(field.selectedFile);
                  preview.name = field.selectedFile.name;
                  preview.size = formatBytes(field.selectedFile.size);
                }

                return (
                  <div key={field.title} className="mb-3">
                    <Label className="form-label">{field.title}</Label>
                    <Input
                      name={field.name}                     
                      type={field.type}
                      placeholder={field.placeholder}
                      onChange={handleChange}
                      // onBlur={validation.handleBlur}
                      value={field.value}
                      // invalid={
                      //   validation.touched.name && validation.errors.name
                      //     ? true
                      //     : false
                      // }
                    />
                    {/* {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null} */}

                    {field.type === "file" && field.selectedFile && (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="150"
                                className="avatar-lg rounded bg-light"
                                alt={preview.name}
                                src={preview.src}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {preview.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{preview.size}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                  </div>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col>
              {loading ? (
                <Spinner className="ms-1 mb-3" color="primary" />
              ) : (
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Create
                  </button>
                  <button
                    onClick={closeModal}
                    className="btn btn-error save-user"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}
