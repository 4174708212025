import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import logodark from "../../assets/images/coolCloud/Logo-Icon.png";
import logolight from "../../assets/images/logo-light.png";
import { initForgotPassword } from "helpers/API_hendlers";

const EmailVerification = (props) => {
  const { state } = useLocation();
  console.log(state);

  const [locState, setLocalState] = useState({ ...state, loading: false });
  const [error, setError] = useState(null);

  const handleResentEmailVerification = async () => {
    setLocalState((pS) => ({ ...pS, loading: true }));
    console.log("RESENT", locState);
    if (locState.isForgotPassword) {
      await initForgotPassword(values)
        .then((r) => {})
        .catch((e) => {
          console.log(e.message);
          setError(e.message || e?.msg);
        })
        .finally(() => {
          setLocalState((pS) => ({ ...pS, loading: false }));
        });
    } else {
      setTimeout(() => {
        setLocalState((pS) => ({ ...pS, loading: false }));
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="#" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="100"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="100"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        {error && (
                          <UncontrolledAlert
                            color="danger"
                            className="alert-dismissible fade show"
                            role="alert"
                          >
                            <i className="mdi mdi-block-helper me-2"></i>
                            {"Couldn't resent email"}
                          </UncontrolledAlert>
                        )}
                        <h4>
                          {locState.isForgotPassword
                            ? "Check your email"
                            : "Verify your email"}
                        </h4>
                        <p>
                          {locState.isForgotPassword
                            ? "We have sent you instruction to email"
                            : "We have sent you verification link to email"}{" "}
                          <span className="fw-semibold">
                            {locState?.email || "example@rr.com"}
                          </span>
                          , Please check it
                        </p>                       
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {locState.isForgotPassword && (
                  <>                
                    {!locState.loading ? (
                      <p>
                        Didn&apos;t receive an email ?{" "}
                        <span
                          className="fw-medium text-primary btn"
                          onClick={handleResentEmailVerification}
                        >
                          Resend
                        </span>{" "}
                      </p>
                    ) : (
                      <Spinner className="ms-1 mb-3" color="primary" />
                    )}
                  </>
                )}

                <p>
                  © {new Date().getFullYear()} Cool Cloud.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EmailVerification;
