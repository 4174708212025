import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table, Input } from "reactstrap";
import { isEmpty, map } from "lodash";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

//Import Image
// import logo from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";
// import { getBillingDetail } from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import USA_states from "assets/data/USA_states";

const customersTestData = [
  {
    name: "Customer1",
    company: "Company1",
    total: "$200",
    id: 1,
    isOpenThee: false,
    months: [
      {
        name: "January 2023",
        listThing: [
          { item: "sms", price: "$5.00" },
          { item: "mms", price: "$3.34" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
      {
        name: "February 2023",

        listThing: [
          { item: "sms", price: "$3.65" },
          { item: "mms", price: "$10.01" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
    ],
  },
  {
    name: "Customer2",
    company: "Company2",
    total: "$300",
    id: 2,
    isOpenThee: false,
    months: [
      {
        name: "January 2023",
        listThing: [
          { item: "sms", price: "$5.00" },
          { item: "mms", price: "$3.34" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
      {
        name: "February 2023",

        listThing: [
          { item: "sms", price: "$3.65" },
          { item: "mms", price: "$10.01" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
    ],
  },
];

const BillingHistoryClient = (props) => {
  const dispatch = useDispatch();

  // const { invoiceDetail } = useSelector((state) => ({
  //   invoiceDetail: state.invoices.invoiceDetail,
  // }));

  const [state, setState] = useState({
    modal: false,
    deleteModal: false,
    isEdit: false,
    contactActive: {},
    image: { selectedFile: null, value: "", src: "" },
    localError: null,
    state: null,
  });

  const [customersTest, setCustomersTest] = useState(customersTestData);

  const handleOpenTreeToggle = (id) => {
    setCustomersTest((pS) => {
      const updatedList = pS.map((customer) => {
        if (customer.id === id) {
          customer.isOpenThee = !customer.isOpenThee;
        }
        return customer;
      });

      return updatedList;
    });
  };

  const params = props.router.params;
  // useEffect(() => {
  //   if (params && params.id) {
  //     dispatch(getBillingDetail(params.id));
  //   } else {
  //     dispatch(getBillingDetail(1)); //remove this after full integration
  //   }
  // }, [dispatch, getBillingDetail]);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };
  const searchUsers = () => {};

  function handleSelectGroup(selectedGroup) {
    setState((pS) => ({ ...pS, state: selectedGroup }));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb
            title="Billing History Client"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          <div className="d-flex flex-row">
            <div className="search-box py-2">
              <div className="position-relative">
                <Input
                  onKeyUp={searchUsers}
                  id="search-user"
                  type="text"
                  className="form-control"
                  placeholder="Company Search..."
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
            <div className="search-box py-2">
              <Select
                value={""}
                onChange={handleSelectGroup}
                placeholder="Month"
                options={USA_states}
                className="select2-selection"
                styles={{
                  menuList: (style) => ({
                    ...style,
                    backgroundColor: "white",
                  }),
                }}
              />
            </div>
            <div className="search-box py-2">
              <Select
                value={""}
                onChange={handleSelectGroup}
                placeholder="Year"
                options={USA_states}
                className="select2-selection"
                styles={{
                  menuList: (style) => ({
                    ...style,
                    backgroundColor: "white",
                  }),
                }}
              />
            </div>
          </div>

          <ul
            className="list-unstyled chat-list bg-white rounded-3 p-3"
            id="recent-list"
          >
            <PerfectScrollbar style={{ height: "calc(100vh - 280px)" }}>
              {map(customersTest, (customer) => (
                <li key={customer.id}>
                  <div className="d-flex align-items-center">
                    <div
                      className="align-self-center me-3"
                      onClick={() => handleOpenTreeToggle(customer.id)}
                    >
                      <i
                        className={
                          customer.isOpenThee
                            ? "bx bx-caret-right text-success font-size-14"
                            : "bx bx-caret-down font-size-14"
                        }
                      />
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <h4 className="text-truncate mb-1">{customer.company}</h4>
                    </div>
                  </div>
                  {customer.isOpenThee && (
                    <>
                      <ul className="list-unstyled" style={{ marginLeft: 100 }}>
                        {map(customer.months, (month) => (
                          <li className="" key={month.name}>
                            <h6 className="font-size-15 fw-bold">
                              {month.name}
                            </h6>
                            <Table className="table-nowrap">
                              <thead>
                                <tr>
                                  <th style={{ width: "70px" }}>No.</th>
                                  <th>Item</th>
                                  <th className="text-end">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {map(month.listThing, (item, key) => (
                                  <tr key={key}>
                                    <td>{key}</td>
                                    <td>{item.item}</td>
                                    <td className="text-end">{item.price}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td colSpan="2" className="text-end">
                                    Sub Total
                                  </td>
                                  <td className="text-end">{month.total}</td>
                                </tr>
                                <tr>
                                  <td colSpan="2" className="border-0 text-end">
                                    <strong>Shipping</strong>
                                  </td>
                                  <td className="border-0 text-end">$13.00</td>
                                </tr>
                                <tr>
                                  <td colSpan="2" className="border-0 text-end">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="border-0 text-end">
                                    <h4 className="m-0">{month.total}</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </li>
                        ))}
                      </ul>
                      <div className="text-end d-flex align-items-center justify-content-end border-top pt-2">
                        <h4 className="m-0">
                          <strong>Account Total</strong>
                        </h4>
                        <h4>{"_"}</h4>
                        <h4 className="m-0">{customer.accountTotal}</h4>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </PerfectScrollbar>
          </ul>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BillingHistoryClient);
