import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

import Select from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";

import { modifyCurrentUser, changeLoader } from "../../store/actions";

import { formatBytes, createImageLink } from "helpers/utility";

import logodark from "../../assets/images/coolCloud/Logo-Icon.png";
import logolight from "../../assets/images/logo-light.png";

const ConstructorLandingPage = () => {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector((state) => ({
    user: state.Login?.user,
    loading: state.Login.loading,
    error: state.Login?.error,
  }));

  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const email = user?.email;
  const isAdmin = user?.is_admin;
  const phone = user?.phone;
  const company_title = user?.company_title;
  const image = user?.image;

  const [state, setState] = useState({
    imageLogo: { selectedFile: null, value: "", src: image || "" },
    imageBackground: { selectedFile: null, value: "", src: image || "" },
    company_title: company_title,
    textContent:
      "Join our VIP SMS List for direct, exclusive communication with our Sales team",
    textCheckbox:
      "I want to be notified about market Product specials. Please add me.",

    buttonName: "Add me",
    buttonColor: "#000000",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const propName = e.target.name;
    const value = e.target.value;

    setState((pS) => ({ ...pS, [propName]: value }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const image = await createImageLink(state.imageLogo.selectedFile);
  };

  let preview = { src: "", name: "", size: 0 };
  if (state.imageLogo.selectedFile) {
    preview.src = URL.createObjectURL(state.imageLogo.selectedFile);
    preview.name = state.imageLogo.selectedFile.name;
    preview.size = formatBytes(state.imageLogo.selectedFile.size);
  }
 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* {state.showAlert.isShow && (
            <Alert
              isOpen={state.showAlert.isShow}
              color={state.showAlert.type}
              className="alert-dismissible fade show"
              role="alert"
              toggle={() => {
                setState((pS) => ({
                  ...pS,
                  showAlert: {
                    ...state.showAlert,
                    isShow: !state.showAlert.isShow,
                  },
                }));
              }}
            >
              <i className={`mdi ${state.showAlert.icon} me-2`}></i>
              {state.showAlert.message}
            </Alert>
          )}        */}

          <h4 className="card-title">Create your custom landing page</h4>

          <Card>
            <CardBody>
              <Form className="form-horizontal" onSubmit={onSubmit}>
                <div className="mb-3 form-group d-flex flex-row justify-content-between">
                  <div className="form-group" style={{ width: "33%" }}>
                    <Label className="form-label">Company name</Label>
                    <Input
                      name="company_title"
                      value={state.company_title}
                      className="form-control"
                      placeholder="Organization name"
                      type="text"
                      onChange={handleChange}
                    />
                    <div className="form-group">
                      <Label for="exampleFile">Add Company Logo</Label>
                      <Input
                        id="exampleFile"
                        name="imageLogo"
                        type="file"
                        onChange={(e) => {
                          const selectedImage = e.target.files[0];
                          console.log("selectedImage", selectedImage);
                          setState((pS) => ({
                            ...pS,
                            imageLogo: {
                              ...pS.image,
                              value: e.target.value,
                              selectedFile: selectedImage,
                            },
                          }));
                        }}
                        value={state.imageLogo.value}
                      />

                      {state.imageLogo.selectedFile && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="150"
                                  alt={preview.name}
                                  src={preview.src}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {preview.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{preview.size}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                      {!state.imageLogo.selectedFile && !!image && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="150"
                                  alt={image}
                                  src={image}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>
                  <div className="form-group" style={{ width: "33%" }}>
                    <Label className="form-label">Text content</Label>
                    <Input
                      name="textContent"
                      value={state.textContent}
                      className="form-control"
                      placeholder="*"
                      type="textarea"
                      onChange={handleChange}
                    />
                    <Label className="form-label">Text at checkbox</Label>
                    <Input
                      name="textCheckbox"
                      value={state.textCheckbox}
                      className="form-control"
                      placeholder="*"
                      type="textarea"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group" style={{ width: "33%" }}>
                    <Label className="form-label">Button name</Label>
                    <Input
                      name="buttonName"
                      className="form-control"
                      placeholder="Button name"
                      type="text"
                      onChange={handleChange}
                      value={state.buttonName}
                    />
                    <label
                      htmlFor="example-color-input"
                      className="col-md-2 col-form-label"
                    >
                      Button color
                    </label>
                    <div className="">
                      <input
                        className="form-control form-control-color mw-100"
                        type="color"
                        value={state.buttonColor}
                        name="buttonColor"
                        id="example-color-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  {loading ? (
                    <Spinner className="ms-2" color="light" />
                  ) : (
                    <Button type="submit" color="primary">
                      Generate Link
                    </Button>
                  )}
                </div>
                <p>
                  Link <a href="#">https://app.coolcloud.com</a>
                </p>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title">Result</h4>

          <Card>
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mb-3 mt-4 text-muted">
                      <Link to="#" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="100"
                          className="auth-logo-dark mx-auto"
                        />
                      </Link>
                    </div>
                    {state.company_title && (
                      <h3 style={{ textAlign: "center" }}>
                        {state.company_title}
                      </h3>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md={7} lg={6} xl={5}>
                    <Card>
                      <CardBody>
                        <div className="p-2">
                          <div className="text-center">
                            <div className="p-0 mt-1">
                              <p style={{ fontSize: 16 }}>
                                {state.textContent}
                              </p>

                              <InputMask
                                mask="+1 (999) 999-9999"
                                name="phone"
                                style={{ textAlign: "center" }}
                                value={""}
                                className="form-control input-color mb-3"
                                onChange={(e) => {}}
                              ></InputMask>
                              <div className="form-check form-check-end mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={true}
                                  id="defaultCheck2"
                                  onChange={() => {}}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck2"
                                  style={{ fontSize: 11 }}
                                >
                                  {state.textCheckbox}
                                </label>
                              </div>

                              <Button
                                style={{ backgroundColor: state.buttonColor }}
                                onClick={() => {}}
                              >
                                {state.buttonName}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConstructorLandingPage;
