import { BASE_API } from "config";

const ROUTES_WITHOU_TOKEN = [
  "/users/login",
  "/users/forgot",
  "/users/register",
  "/users/code",
];

async function call(method = "GET", url, config = {}) {
  return new Promise((res, rej) => {
    try {
      if (
        !ROUTES_WITHOU_TOKEN.includes(url) &&
        !localStorage.getItem("authUser")
      )
        throw new Error("token is EMPTY");

      const authUser = JSON.parse(localStorage.getItem("authUser"));
      let token = null;
      if (authUser) token = authUser.auth.token;
      if (!ROUTES_WITHOU_TOKEN.includes(url) && !token)
        throw new Error("YOU ARE NOT LOGIN");

      // const controller = new AbortController();
      // const timeoutId = setTimeout(() => controller.abort(), 50000);

      const options = {
        method: method,
        // signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (method === "POST" || method === "PUT") {
        options.body = JSON.stringify(config);
      }

      if (config instanceof FormData) {
        delete options.headers["Content-Type"];
        if (method === "POST" || method === "PUT") {
          options.body = config;
        }
      }

      fetch(BASE_API + url, options)
        .then((resp) => {
          // clearTimeout(timeoutId);

          console.log(resp.status, method, url, config);
          if (resp.status === 401) {
            return (window.location.href = "/login");
          }
          if (url.includes("/users/me/speech")) {
            return resp.blob();
          }
          return resp.json();
        })
        .then((r) => {
          if (r.error) {
            console.log("fetch error", r);
            rej(r.error);
            return;
          }
          if (url.includes("/users/me/speech")) {
            const objectURL = URL.createObjectURL(r);
            return res(objectURL);
          }
          res(r);
        })
        .catch((e) => {
          console.log("ERROR IN FETCH CALL", e);
          rej(e);
        });
    } catch (error) {
      console.log("ERROR IN CALL", error);
      rej(error);
    }
  });
}

export const login = (cr) => call("POST", "/users/login", cr);
export const register = (cr) => call("POST", "/users/register", cr);
export const confirmCreatePassword_API = (cr) =>
  call("POST", "/users/code", cr);
export const initForgotPassword = (cr) => call("POST", "/users/forgot", cr);
export const getCurrentUser = () => call("GET", "/users/me");
export const modifyCurrentUser = (cr) => call("PUT", `/users/me`, cr);
// ====== for admin role ONLY =================
export const createUser = (cr) => call("POST", `/users`, cr);
export const getUsers = () => call("GET", `/users`);
export const getUserById = (userId) => call("GET", `/users/${userId}`);
export const modifyUserById = (userId, cr) =>
  call("PUT", `/users/${userId}`, cr);
export const deleteUserById = (userId, cr) =>
  call("DELETE", `/users/${userId}`, cr);
export const phonesAvailable = (request) =>
  call("POST", "/phones/available", request);
export const addPhones = (phone) => call("POST", "/phones/add", phone);
// ==========================================
export const getCustomersContact = () => call("GET", `/contacts`);
export const addNewCustomerContact = (cr) => call("POST", `/contacts`, cr);
export const getCustomerContactProfile = (contactId) =>
  call("GET", `/contacts/${contactId}`);
export const getNotificationsOrderByPhone = (phone, filter) =>
  call(
    "GET",
    `/orders/${phone}?${filter.orderId ? `id=${filter.orderId}&` : ""}limit=${
      filter.limit || 10
    }&offset=${filter.offset}${filter.event ? `&event=${filter.event}` : ""}`
  ); // order notifications

export const updateCustomerContact = (contactId, cr) =>
  call("PUT", `/contacts/${contactId}`, cr);
export const deleteCustomerContact = (contactId) =>
  call("DELETE", `/contacts/${contactId}`);
// ============================================
export const uploadFile = (file) => call("POST", "/users/upload", file);
export const getChatToken = () => call("GET", "/chat/token");
// ================= billing ==================================
export const getBillingUsage = () => call("GET", "/billing/usage");
// ================= flows ====================================
export const createFlow = (cr) => call("POST", "/flows", cr);
export const getFlows = () => call("GET", "/flows");
export const editFlow = (id, cr) => call("PUT", `/flows/${id}`, cr);
export const getFlowByID = (id) => call("GET", `/flows/${id}`);
export const deleteFlow = (id) => call("DELETE", `/flows/${id}`);
// ================= campaings ====================================
export const createCampaing = (cr) => call("POST", "/campaigns", cr);
export const getCampaings = () => call("GET", "/campaigns");
export const editCampaing = (id, cr) => call("PUT", `/campaigns/${id}`, cr);
export const getCampaingByID = (id) => call("GET", `/campaigns/${id}`);
export const deleteCampaing = (id) => call("DELETE", `/campaigns/${id}`);
export const startCampaingID = (id) => call("POST", `/campaigns/${id}`);
// ================= a2p_profile ====================================
export const createMyCompany = (cr) => call("POST", "/companies/my", cr);
export const getAllCompanies = () => call("GET", "/companies"); // for super admin only
export const editMyCompany = (cr, id) => call("PUT", `/companies/${id}`, cr); // id - for super admin only
export const getCompany = (id) => call("GET", `/companies/${id}`);

// ===================== handlers for shopify ==================
export const hashCreate = (cr) => call("POST", "/hashes", cr);
// ====================== MY AI ===============================

export const voiceCreate = (cr) => call("POST", "/users/me/voice", cr);
export const voiceRead = (cr) => call("POST", "/users/me/speech", cr);
