import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row, UncontrolledAlert } from "reactstrap";

import withRouter from "components/Common/withRouter";

import { editFlowAction, getFlowByIDAction, changeLoader } from "store/actions";

const actions = [
  // { title: "Email", icon: "mdi mdi-email-multiple", id: "email_drop" },
  { title: "SMS", icon: "fas fa-sms", id: "sms_drop" },
  // { title: "Phone", icon: "fas fa-phone", id: "phone_drop" },
  // { title: "Notification", icon: "mdi mdi-bell", id: "notification_drop" },
];

import { LineToDrop } from "./components/tree/lineDrop";
import { TimeDelay } from "./components/edits/timeDelay";
import { InitBlock } from "./components/tree/firstInitBlock";
import SMS from "./components/edits/SMS";
import { Phone } from "./components/edits/Phone";
import { Notification } from "./components/edits/Notification";
import { Email } from "./components/edits/Email";
import { Target } from "./components/edits/Target";
import { getMS, isDeepEqual } from "helpers/utility";
import Breadcrumb from "components/Common/Breadcrumb";

class EditFlow extends Component {
  constructor(props) {
    super(props);
    console.log("EditFlow", props);

    this.state = {
      isActiveDrag: false,
      isDragOver: null,
      activeElement: null,
      indexDragElement: null,
      editView: null,
      editViewState: {},
      childrenTree: [
        (state) => (
          <LineToDrop
            key={"0"}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
            isActive={state.isActiveDrag}
            isDragOver={state.isDragOver}
            id={`line_0`}
            indCur={0}
          />
        ),
      ],
    };

    this.addChildComponent = this.addChildComponent.bind(this);

    props.loader(true);
    const FLOW_ID = props.router.location.state.id;

    console.log("FLOW_ID", FLOW_ID);

    props.onGetFlowByIDAction(FLOW_ID);
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.editFlow &&
        prevProps.editFlow &&
        !isDeepEqual(prevProps.editFlow, this.props.editFlow)) ||
      this.props.editFlow !== prevProps.editFlow
    ) {
      this.props.loader(false);

      this.handleBuildTree(this.props.editFlow);
    }

    if (this.props.error) {
      this.props.loader(false);
    }

    if (prevState.childrenTree.length !== this.state.childrenTree.length) {
      this.handleSave(true);
    }
  }

  handleBuildTree(flow) {
    this.setState((prevState) => {
      const actions = flow?.actions || [];

      const newTree = [
        (state) => (
          <LineToDrop
            key={"0"}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
            isActive={state.isActiveDrag}
            isDragOver={state.isDragOver}
            id={`line_0`}
            indCur={0}
          />
        ),
      ];

      actions.forEach((action, index) => {
        if (action.action === "TIME_DELAY") {
          const idKey = `time_delay_${index}_flowBlock`;
          const iconClass = "mdi mdi-timer";
          newTree.push((state) => (
            <InitBlock
              onOpenEdit={this.onOpenEdit}
              onClone={this.onClone}
              onDelete={this.onDelete}
              action={action}
              key={idKey}
              id={idKey}
              iconClass={iconClass}
              onDrag={this.onDrag}
              onDragStart={this.onStart}
              onDragEnd={this.onStop}
              onClickFlowBlock={this.onClickFlowBlock}
            />
          ));
          newTree.push((state) => (
            <LineToDrop
              key={index + 1}
              onDragOver={this.onDragOver}
              onDrop={this.onDrop}
              isActive={state.isActiveDrag}
              isDragOver={state.isDragOver}
              id={`line_${index + 1}`}
              indCur={index}
            />
          ));
        }
        if (action.action === "SMS") {
          const idKey = `sms_drop_${index}_flowBlock`;
          const iconClass = "fas fa-sms";
          newTree.push((state) => (
            <InitBlock
              onOpenEdit={this.onOpenEdit}
              onClone={this.onClone}
              onDelete={this.onDelete}
              action={action}
              key={idKey}
              id={idKey}
              iconClass={iconClass}
              onDrag={this.onDrag}
              onDragStart={this.onStart}
              onDragEnd={this.onStop}
              onClickFlowBlock={this.onClickFlowBlock}
            />
          ));
          newTree.push((state) => (
            <LineToDrop
              key={index + 1}
              onDragOver={this.onDragOver}
              onDrop={this.onDrop}
              isActive={state.isActiveDrag}
              isDragOver={state.isDragOver}
              id={`line_${index + 1}`}
              indCur={index}
            />
          ));
        }
      });

      return {
        childrenTree: newTree,
      };
    });
  }

  addChildComponent = (dragElementID, toElementId) => {
    // console.log(dragElementID, toElementId);
    const dateForId = Date.now();
    let indexForChange = +toElementId.slice(5);

    if (this.state.childrenTree.length - indexForChange * 2 > 1) {
      indexForChange = (this.state.childrenTree.length + 1) / 2;
    }

    let indexNeed = null;

    let isAlreadySMS = [];
    let isAlreadyNotification = [];

    this.state.childrenTree.forEach((cf, index) => {
      const comp = cf(this.state);
      if (comp.props.id === toElementId) {
        indexNeed = index;
        return;
      }

      if (comp.props.id.includes("sms")) {
        isAlreadySMS.push(comp.props.id);
      }
      if (comp.props.id.includes("notification")) {
        isAlreadyNotification.push(comp.props.id);
      }
    });

    const newComponent = (state) => {
      const idKey = `${dragElementID}_${indexForChange}_flowBlock`;

      let iconClass = "mdi mdi-lightning-bolt";

      const action = { action: "", settings: {}, id: "" };

      if (dragElementID === "email_drop") {
        action.action = "EMAIL";
        action.settings["title"] = "Email #1";
        action.settings["text"] = "Configure content";
        action.settings["imgSrc"] = "";
        action.id = `email_${dateForId}`;
        iconClass = "mdi mdi-email-multiple";
      }
      if (dragElementID === "time_delay") {
        action.action = "TIME_DELAY";
        action.settings["title"] = "Wait";
        action.settings["value"] = "10";
        action.settings["period"] = "min";
        action.settings["delay"] = 600000; //ms
        action.id = `time_delay_${dateForId}`;
        iconClass = "mdi mdi-timer";
      }
      if (dragElementID === "sms_drop") {
        action.action = "SMS";
        action.settings["title"] = "SMS #1";
        action.settings["text"] = "Configure content";
        action.settings["imgSrc"] = "";
        action.id = `sms_${dateForId}`;
        iconClass = "fas fa-sms";
      }
      if (dragElementID === "phone_drop") {
        action.action = "PHONE";
        action.settings["title"] = "Phone #1";
        action.settings["text"] = "Configure content";
        action.id = `phone_${dateForId}`;
        iconClass = "fas fa-phone";
      }
      if (dragElementID === "notification_drop") {
        action.action = "NOTIFICATION";
        action.settings["title"] = "Notification #1";
        action.settings["text"] = "Configure content";
        action.settings["imgSrc"] = "";
        action.id = `notification_${dateForId}`;
        iconClass = "mdi mdi-bell";
      }

      if (isAlreadySMS.length > 0) {
        if (action.action === "SMS") {
          action.settings["title"] = `${action.settings["title"].slice(0, -1)}${
            isAlreadySMS.length + 1
          }`;
        }
      }
      if (isAlreadyNotification.length > 0) {
        if (action.action === "NOTIFICATION") {
          action.settings["title"] = `${action.settings["title"].slice(0, -1)}${
            isAlreadyNotification.length + 1
          }`;
        }
      }

      return (
        <InitBlock
          onOpenEdit={this.onOpenEdit}
          onClone={this.onClone}
          onDelete={this.onDelete}
          action={action}
          key={idKey}
          id={idKey}
          iconClass={iconClass}
          onDrag={this.onDrag}
          onDragStart={this.onStart}
          onDragEnd={this.onStop}
          onClickFlowBlock={this.onClickFlowBlock}
        />
      );
    };

    if (dragElementID) {
      this.setState((prevState) => {
        const newTree = [...prevState.childrenTree];

        newTree.splice(
          indexNeed + 1,
          0,
          (state) => newComponent(state),
          (state, index) => (
            <LineToDrop
              key={indexForChange + 1}
              onDragOver={this.onDragOver}
              onDrop={this.onDrop}
              isActive={state.isActiveDrag}
              isDragOver={state.isDragOver}
              id={`line_${indexForChange + 1}`}
              indCur={index}
            />
          )
        );

        return {
          childrenTree: newTree,
        };
      });
      return;
    }
  };

  onStart = (e) => {
    // console.log("onStart", e);
    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/plain", e.target.id);

    let indexCurrent = 0;

    this.state.childrenTree.forEach((cf, index) => {
      const comp = cf(this.state);
      if (comp.props.id === e.target.id) {
        indexCurrent = index;
      }
    });

    this.setState({
      isActiveDrag: true,
      activeElement: e,
      indexDragElement: indexCurrent,
    });
  };
  onDrag = (e) => {
    // e.preventDefault();
    // console.log("onDrag");
  };
  onStop = (e) => {
    this.setState({
      isActiveDrag: false,
      isDragOver: null,
      indexDragElement: null,
      activeElement: null,
    });
  };

  onDrop = (event) => {
    event.preventDefault();
    const droppedItemId = event.dataTransfer.getData("text/plain");
    // console.log("droppedItemId", droppedItemId, event);

    const toElement = event.nativeEvent.toElement;

    let idToElement = toElement.id;
    if (!idToElement) {
      idToElement = toElement.parentElement.id;
    }

    if (this.state.indexDragElement) {
      let indexTarget = 0;

      this.state.childrenTree.forEach((cf, index) => {
        const comp = cf(this.state);
        if (comp.props.id === idToElement) {
          indexTarget = index;
        }
      });

      if (
        this.state.indexDragElement === indexTarget ||
        this.state.indexDragElement === indexTarget + 1 ||
        this.state.indexDragElement === indexTarget - 1
      ) {
        return;
      }
    }

    if (droppedItemId.includes("_flowBlock")) {
      this.sertedChild(droppedItemId, idToElement);
      return;
    }

    this.addChildComponent(droppedItemId, idToElement);
  };

  sertedChild(droppedItemId, idToElement) {
    this.setState((prevState) => {
      const newTree = [...prevState.childrenTree];

      let indexCurrent = 0;

      let indexNeed = null;
      this.state.childrenTree.forEach((cf, index) => {
        const comp = cf(this.state);
        if (comp.props.id === idToElement) {
          indexNeed = index;
        }
        if (comp.props.id === droppedItemId) {
          indexCurrent = index;
        }
      });
      // console.log(droppedItemId, "indexCurrent", indexCurrent);
      // console.log(idToElement, "indexNeed", indexNeed);

      let elementToMove = newTree.splice(indexCurrent, 1)[0];
      let elementToMoveLine = newTree.splice(indexCurrent - 1, 1)[0];

      newTree.splice(indexNeed, 0, elementToMoveLine, elementToMove);

      return {
        childrenTree: newTree,
      };
    });
  }

  onChangeEditView = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    console.log(value);

    this.setState({
      editViewState: {
        ...this.state.editViewState,
        settings: { ...this.state.editViewState.settings, [name]: value },
      },
    });
  };

  onClickFlowBlock = (flow_block_id, dataForPass) => {
    // console.log("click", flow_block_id);
    let editComponent = null;
    if (flow_block_id.includes("email")) {
      editComponent = (
        <Email
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }
    if (flow_block_id.includes("phone")) {
      editComponent = (
        <Phone
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }
    if (flow_block_id.includes("notification")) {
      editComponent = (
        <Notification
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }
    if (flow_block_id.includes("sms")) {
      editComponent = (
        <SMS
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }
    if (flow_block_id.includes("time_delay")) {
      editComponent = (
        <TimeDelay
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }
    if (flow_block_id.includes("init")) {
      editComponent = (
        <Target
          onDoneEdit={this.onDoneEdit}
          dataForPass={dataForPass}
          editViewState={this.state.editViewState}
          onChangeEditView={this.onChangeEditView}
        />
      );
    }

    this.setState({ editView: editComponent, editViewState: dataForPass });
  };

  onDragOver = (event) => {
    event.preventDefault();
    let id = event.target.id;
    if (!id) id = event.target.parentElement.id;
    if (this.state.isDragOver !== id) {
      this.setState({ isDragOver: id });
    }
  };

  onDelete = (id) => {
    // console.log("DELETE", id);

    this.setState((prevState) => {
      let indexLineForDelete = null;
      const newTree = [...prevState.childrenTree].filter((cf, index) => {
        if (cf(this.state).props.id === id) {
          indexLineForDelete = index + 1;
          return false;
        }

        if (
          (indexLineForDelete || indexLineForDelete === 0) &&
          index === indexLineForDelete
        ) {
          return false;
        }

        return true;
      });

      return {
        childrenTree: newTree,
      };
    });
  };
  onClone = (id) => {};
  onOpenEdit = (id) => {};

  onDoneEdit = () => {
    // this.props.loader(true);
    console.log(this.state);

    const actions = [];

    this.state.childrenTree.forEach((cf, index) => {
      const comp = cf(this.state);
      if (!comp.props.id.includes("line_")) {
        actions.push(comp.props.action);
      }
    });

    console.log("actions", actions);

    const newActionData = { ...this.state.editViewState };

    if (newActionData.settings.delay) {
      newActionData.settings.delay = getMS(
        newActionData.settings.value,
        newActionData.settings.period
      );
    }

    const actUpd = actions.map((act) => {
      if (act.id === newActionData.id) {
        return newActionData;
      }
      return act;
    });

    const initData = this.props.router.location.state;

    const editFlow = {
      actions: actUpd,
    };

    this.props.onEditFlowAction(initData.id, editFlow);

    this.setState((prevState) => {
      return {
        editView: null,
        editViewState: {},
      };
    });
  };

  handleSave = (notBack) => {
    const actions = [];

    this.state.childrenTree.forEach((cf, index) => {
      const comp = cf(this.state);
      // console.log(comp);

      if (!comp.props.id.includes("line_")) {
        actions.push(comp.props.action);
      }
    });

    console.log("actions", actions);

    const initData = this.props.router.location.state;

    const editFlow = {
      status: "live",
      actions: actions,
    };

    this.props.onEditFlowAction(
      initData.id,
      editFlow,
      notBack ? null : this.props.router.navigate
    );
  };

  render() {
    const initData = this.props.router.location.state;
    return (
      <div className="page-content" style={{ padding: "70px 0 0 0" }}>
        {this.props.error && (
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            <i className="mdi mdi-block-helper me-2"></i>
            {this.props.error?.msg}
          </UncontrolledAlert>
        )}

        <Row className="flow_container">
          {/* <Breadcrumb
            title="Flow Edit"
            breadcrumbItems={this.props?.router?.location?.pathname}
          /> */}
          {this.state.editView ? (
            <Col xs={4} className="flow_leftBlock edit">
              {this.state.editView}
            </Col>
          ) : (
            <Col xs={4} className="flow_leftBlock mainScreen">
              <div>
                <h2 className="title_flow mb-3">ACTIONS</h2>
                {actions.map((act) => {
                  return (
                    <div
                      key={act.id}
                      draggable={true}
                      onDrag={this.onDrag}
                      onDragStart={this.onStart}
                      onDragEnd={this.onStop}
                      id={act.id}
                      className="mb-3 action_block"
                    >
                      <i className={`${act.icon} font-size-24`}></i>
                      <p>{act.title}</p>
                    </div>
                  );
                })}
                <h2 className="title_flow mb-3">TIMING</h2>

                <div
                  draggable={true}
                  onDrag={this.onDrag}
                  onDragStart={this.onStart}
                  onDragEnd={this.onStop}
                  id={"time_delay"}
                  className="mb-3 action_block timing"
                >
                  <i className="mdi mdi-timer font-size-24"></i>
                  <p>Time Delay</p>
                </div>
              </div>
              <Button onClick={() => this.handleSave()}>SAVE</Button>
            </Col>
          )}

          <Col className="flow_rightBlock">
            <InitBlock
              onOpenEdit={this.onOpenEdit}
              onClone={this.onClone}
              onDelete={this.onDelete}
              onClickFlowBlock={this.onClickFlowBlock}
              action={{
                action: "Trigger",
                settings: {
                  text: `When "${initData.trigger}"`,
                  title: "Trigger" + "-" + initData.title,
                },
              }}
              key={"init"}
              id={"init"}
              iconClass={"mdi mdi-lightning-bolt"}
            />
            {this.state.childrenTree.map((child, index) =>
              child(this.state, index)
            )}
            <p className="exit">EXIT</p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  editFlow: state.flow.editFlow,
  error: state.flow.error,
});
const mapDispatchToProps = (dispatch) => ({
  onEditFlowAction: (id, payload, navigate) =>
    dispatch(editFlowAction(id, payload, navigate)),
  onGetFlowByIDAction: (payload) => dispatch(getFlowByIDAction(payload)),
  loader: (payload) => dispatch(changeLoader(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditFlow)
);
