import React from "react";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { changeLoader } from "store/actions";

function Loader({ loader }) { 
  if (!loader) {
    return;
  }
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10000,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
      }}
    >
      <Spinner
        style={{
          position: "absolute",
          zIndex: 10001,
          top: "50%",
          left: "50%",
          marginLeft: "-4rem",
          marginTop: "-4rem",
          height: "8rem",
          width: "8rem",
        }}
        className=""
        color="primary"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loader: state.Loader.loader,
});
const mapDispatchToProps = (dispatch) => ({
  changeLoader: (payload) => dispatch(changeLoader(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
