import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Container, Button, Alert } from "reactstrap";
import withRouter from "components/Common/withRouter";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const Personal = (props) => {
  const [error, setError] = useState(null);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Personal Training"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {error && (
            <Alert
              style={{ zIndex: 1000, display: "flex", position: "fixed" }}
              isOpen={error.isOpen}
              color={"danger"}
              className="alert-dismissible fade show"
              role="alert"
              toggle={() => {
                setError(null);
              }}
            >
              <i className={`mdi mdi-block-helper me-2 font-size-20`}></i>
              <div>
                <p className="mb-0">{error.message}</p>
              </div>
            </Alert>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5 className="fw-semibold mb-3">Personal Page</h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Personal);
