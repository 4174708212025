import React from "react";

import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  Spinner,
} from "reactstrap";

import InputMask from "react-input-mask";

export default function ModalsCreateConversation({
  isOpen,
  onToggle,
  title,
  onSubmit,
  handleChange,
  fields,
  numberField,
  error,
  closeModal,
  loading,
  isAlreadyInUse,
}) {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} tag="h4">
        {title}
      </ModalHeader>
      {error && <Alert color={error.type || "danger"}>{error?.message}</Alert>}
      {!isAlreadyInUse && (
        <Alert color="info">
          The phone number is not in your customer list, you can add this
          contact now.
        </Alert>
      )}

      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12}>
              <Label className="form-label">{numberField.title}</Label>
              <InputMask
                mask="+1 (999) 999-9999"
                name="phone"
                value={numberField.value}
                className="form-control input-color"
                onChange={handleChange}
              ></InputMask>

              <div className="mb-3"></div>
              {!isAlreadyInUse && (
                <>
                  <div className="mb-3 d-flex flex-row justify-content-between">
                    <div key={fields[0].title} style={{ width: "48%" }}>
                      <Label className="form-label">{fields[0].title}</Label>
                      <Input
                        name={fields[0].name}
                        type={fields[0].type}
                        placeholder={fields[0].placeholder}
                        onChange={handleChange}
                        // onBlur={validation.handleBlur}
                        value={fields[0].value}
                        // invalid={
                        //   validation.touched.name && validation.errors.name
                        //     ? true
                        //     : false
                        // }
                      />
                      {/* {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null} */}
                    </div>
                    <div key={fields[1].title} style={{ width: "48%" }}>
                      <Label className="form-label">{fields[1].title}</Label>
                      <Input
                        name={fields[1].name}
                        type={fields[1].type}
                        placeholder={fields[1].placeholder}
                        onChange={handleChange}
                        // onBlur={validation.handleBlur}
                        value={fields[1].value}
                        // invalid={
                        //   validation.touched.name && validation.errors.name
                        //     ? true
                        //     : false
                        // }
                      />
                      {/* {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null} */}
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-row justify-content-between">
                    <div key={fields[2].title} style={{ width: "48%" }}>
                      <Label className="form-label">{fields[2].title}</Label>
                      <Input
                        name={fields[2].name}
                        type={fields[2].type}
                        placeholder={fields[2].placeholder}
                        onChange={handleChange}
                        // onBlur={validation.handleBlur}
                        value={fields[2].value}
                        // invalid={
                        //   validation.touched.name && validation.errors.name
                        //     ? true
                        //     : false
                        // }
                      />
                      {/* {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null} */}
                    </div>
                    <div key={fields[3].title} style={{ width: "48%" }}>
                      <Label className="form-label">{fields[3].title}</Label>
                      <Input
                        name={fields[3].name}
                        type={fields[3].type}
                        placeholder={fields[3].placeholder}
                        onChange={handleChange}
                        // onBlur={validation.handleBlur}
                        value={fields[3].value}
                        // invalid={
                        //   validation.touched.name && validation.errors.name
                        //     ? true
                        //     : false
                        // }
                      />
                      {/* {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null} */}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {loading ? (
                <Spinner className="ms-1 mb-3" color="primary" />
              ) : (
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Create and Send
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-error save-user"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}
