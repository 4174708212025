import React from "react";

export const Phone = ({id}) => {
  return (
    <div className="flow_block" id={id}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <i className="fas fa-phone font-size-24"></i>
        </div>
        <div>
          <p>Phone</p>
          <p>
            Phone #1
          </p>
        </div>
      </div>
    </div>
  );
};
