import { uploadFile } from "./API_hendlers";
import moment from "moment";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export async function loadImage(imageData) {
  return new Promise(async (res) => {
    try {
      fetch(imageData)
        .then((response) => response.blob())
        .then((blob) => {
          // console.log("loadImage", blob);
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            const base64data = reader.result;
            const cutUnuse = base64data.slice(base64data.indexOf(",") + 1);
            res(`PHOTO;ENCODING=BASE64;TYPE=JPEG:${cutUnuse}`);
          };
          reader.onerror = function () {
            console.log(reader.error);
            res(null);
          };
        });
    } catch (error) {
      console.log("loadImage", error);
    }
  });
}

export async function createImageLink(selectedFile) {
  return new Promise((res, rej) => {
    try {
      if (!selectedFile) return res(null);

      const formData = new FormData();
      formData.append("files", selectedFile, selectedFile?.name);
      uploadFile(formData)
        .then((r) => res(r))
        .catch((e) => res(null));
    } catch (error) {
      console.log(error);
      res(null);
    }
  });
}

export function isUseThisNumber(conversations, phone) {
  try {
    // console.log("conversations", conversations);
    // console.log("phone", phone);

    const phoneInputValue = phone.replace(/\D/g, "");

    let convToUse_SID = null;

    for (const conv of conversations) {
      const participants = Object.fromEntries(conv._participants);
      for (const partSID in participants) {
        if (
          participants[partSID].state?.type === "sms" &&
          participants[partSID].state?.bindings?.sms?.address.replace(
            /\D/g,
            ""
          ) === phoneInputValue
        ) {
          convToUse_SID = conv.sid;
        }
      }
    }
    if (!convToUse_SID) return;

    return convToUse_SID;
    // console.log("convToUse_SID", convToUse_SID);
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function createVcard(user, imageData) {
  try {
    const vcard_type = "text/vcard";

    let PHOTO = `PHOTO;ENCODING=BASE64;TYPE=JPEG:${imageData}`;

    if (imageData?.includes("https://api.coolcloud.com/storage")) {
      const base64_from_url_image = await loadImage(imageData);
      if (base64_from_url_image) {
        PHOTO = base64_from_url_image;
      }
    }
    if (!imageData) {
      PHOTO = null;
    }
    //Post Office Address; Extended Address; Street; Locality; Region; Postal Code; Country)
    const o = {
      PObox: "",
      StrAddr: user?.address || "",
      StrAddr2: user?.address_2 || "",
      Locality: user?.city || "",
      Region: user?.state || "",
      zip: user?.zip || "",
      country: "United States",
    };

    const address = `ADR;TYPE=WORK:${o.PObox};${o.StrAddr};${o.StrAddr2};${o.Locality};${o.Region};${o.zip};${o.country}\n`;

    const V = `VERSION:3.0\n`;
    const N = `N:${user?.last_name || "last name"};${
      user?.first_name || "first name"
    }\n`;
    const FN = `FN:${user?.first_name || "Rep"}\n`;
    const ORG = user?.company_title ? `ORG:${user?.company_title}\n` : "";
    const TEL = user?.phone ? `TEL;TYPE=WORK,VOICE:${user?.phone}\n` : "";
    const EMAIL = user?.email ? `EMAIL:${user?.email}\n` : "";
    const REV = `REV:${new Date().toISOString()}\n`;
    const PH = PHOTO ? `${PHOTO}\n` : "";

    let vcard = `BEGIN:VCARD\n${V}${N}${FN}${ORG}${address}${TEL}${EMAIL}${REV}${PH}END:VCARD`;
    // console.log(vcard);

    const file_vcard = new Blob([vcard], { type: vcard_type });

    return {
      file_vcard,
      vcard_type,
      file_name: `${user?.first_name}_${user?.last_name}.vcf`,
    };
  } catch (error) {
    console.log(error);
  }
}
let intervalID = null;
export function updateTimeInNotifications(action) {
  if (action === "start") {
    intervalID = setInterval(update, 60000);
  }

  if (action === "stop") {
    clearInterval(intervalID);
  }

  function update() {
    // console.log("Update time in notifications");

    const locNot = localStorage.getItem("notifications");
    if (locNot) {
      const notifications = JSON.parse(locNot);

      const newNots = notifications.map((not) => ({
        ...not,
        timeFromNow: moment(not.time).fromNow(),
      }));

      localStorage.setItem("notifications", JSON.stringify(newNots));
    }
  }
}

export function removeOpenedConvFromNotific(convSID) {
  const fullNotifFromLocalStorage = localStorage.getItem("notifications");
  if (fullNotifFromLocalStorage) {
    const nots = JSON.parse(fullNotifFromLocalStorage);
    let newNots = [];
    newNots = nots.filter((not) => not.convSID !== convSID);
    localStorage.setItem("notifications", JSON.stringify(newNots));
  }
}

export function isNotifBySID(convSID) {
  const fullNotifFromLocalStorage = localStorage.getItem("notifications");
  if (fullNotifFromLocalStorage) {
    const nots = JSON.parse(fullNotifFromLocalStorage);
    return nots.find((not) => not.convSID === convSID);
  }
}

export async function custom_delay(delay) {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, delay);
  });
}

export function statusMessageColor(message) {
  let statusColor = "text-info";
  let messageStatus = "wait";

  const delRec = message.detailedDeliveryReceipts[0];
  // console.log("delRec", delRec);

  const status = delRec && delRec?.status;

  if (status === "undelivered" || status === "failed") {
    statusColor = "text-danger";
    messageStatus = `Message was ${status}`;
  }

  if (!status) {
    statusColor = "text-info";
    messageStatus = ``;
  }

  if (status === "delivered") {
    statusColor = "text-success";
    messageStatus = `Message was ${status}`;
  }

  return { color: statusColor, message: messageStatus, statusInfo: status };
}

export const getMS = (value, period) => {
  switch (period) {
    case "min":
      return value * 60 * 1000;
    case "hour":
      return value * 3600 * 1000;
    case "day":
      return value * 3600 * 1000 * 24;
    default:
      return null;
  }
};

export const isDeepEqual = (object1, object2) => {
  // console.log("object1", object1);
  // console.log("object2", object2);

  if (!object1 && !object2) {
    return true;
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = typeof value1 === "object" && typeof value2 === "object";

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const searchVanilla = (
  inputSearchID,
  idContainer_ul,
  tagChild,
  tagTextContent
) => {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(inputSearchID);
  filter = input.value.toUpperCase();
  ul = document.getElementById(idContainer_ul);
  li = ul.getElementsByTagName(tagChild);
  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName(tagTextContent)[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
};

export const formatDate = (currentDate, currentTime) => {
  try {
    if (!currentDate) {
      return;
    }
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(currentDate.getUTCDate()).padStart(2, "0");
    let hours = String(currentDate.getUTCHours()).padStart(2, "0");
    let minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
    let seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");

    if (currentTime) {
      hours = String(currentTime.getUTCHours()).padStart(2, "0");
      minutes = String(currentTime.getUTCMinutes()).padStart(2, "0");
      seconds = String(currentTime.getUTCSeconds()).padStart(2, "0");
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  } catch (error) {
    console.log(error);
  }
};

export const formatDateFromUTC = (currentDate) => {
  try {
    console.log("currentDate", currentDate);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();

    const utcDate = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds)
    );
    console.log("utcDate", utcDate);

    return utcDate;
  } catch (error) {
    console.log(error);
  }
};

export const fetchLinkPreview = (link) => {
  return new Promise(async (res, rej) => {
    await fetch(link, {
      mode: "cors",
    })
      .then((r) => r.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const title = doc.querySelector("title")?.textContent || "";
        const description =
          doc
            .querySelector('meta[name="description"]')
            ?.getAttribute("content") || "";

        let image =
          doc
            .querySelector('meta[property="og:image"]')
            ?.getAttribute("content") || "";

        let icon =
          doc.querySelector('link[rel="icon"]')?.getAttribute("href") || "";

        // console.log("icon", icon);
        //link rel="icon"
        if (!image) {
          const imgElement = doc.querySelector("img");
          if (imgElement) {
            image = imgElement.src;
          }
        }

        res({ title, description, image, icon });
      })
      .catch((e) => {
        rej(e);
      });
  });
};

export function phoneNumberFormatter(phone) {
  if (phone) {
    let format = phone.replace(/\s/g, "").replace(/[()-]/g, "");

    if (format.includes("+") && format.indexOf("+") === 0) {
      return format.slice(1);
    }

    return format;
  }
}
