import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import InputMask from "react-input-mask";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import withRouter from "components/Common/withRouter";
import USA_states from "assets/data/USA_states";
import { modifyCurrentUser, changeLoader } from "../../../store/actions";
import { formatBytes, createImageLink } from "helpers/utility";
import { A2P_config } from "config";
import {
  createMyCompany,
  getCompany,
  editMyCompany,
  getAllCompanies,
} from "helpers/API_hendlers";
import Breadcrumb from "components/Common/Breadcrumb";
// {
//   "title": "Company 14",
//   "margin": "0.01",
//   "factor": "1.01",
//   "a2p": {
//       "lol": "joj"
//   }
// }

const A2P_10DLC_Profile = (props) => {
  const { user, loading, error } = useSelector(
    (state) => ({
      user: state.Login?.user,
      loading: state.Login.loading,
      error: state.Login?.error,
    }),
    shallowEqual
  );

  const [modalPBA, setModalPBA] = useState({
    isOpenModal: false,
    loading: false,
  });

  const [state, setState] = useState({
    isExistCompanyProfile: true,
    company: {},
    loading: false,
    showAlert: {
      isShow: false,
      message: "Updated successfully",
      type: "success",
      icon: "mdi-information-outline",
    },
  });

  useEffect(() => {
    setState((pS) => ({ ...pS, loading: true }));
    let supervisor_id = props.router.params.id;
    getCompany(supervisor_id)
      .then((r) => {
        console.log("getCompany", r);
        setState((pS) => {
          const newState = {
            ...pS,
            company: r.company,
            isExistCompanyProfile: true,
            loading: false,
          };

          if (r?.company?.applied === 1) {
            newState.showAlert = {
              isShow: true,
              message:
                "The A2P profile form is being reviewed and you cannot change any details until the review is complete",
              type: "success",
              icon: "mdi-information-outline",
            };
          }

          return newState;
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.msg === "Cannot find company") {
          setState((pS) => ({
            ...pS,
            isExistCompanyProfile: false,
            loading: false,
          }));
        }
      });
  }, []);

  const validation_create_profile = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: state.company?.title || "",
      margin: state.company?.margin || "",
      factor: state.company?.factor || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      margin: Yup.string().required("Required"),
      factor: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("validation_create_profile", values);
      // return;

      if (!state.isExistCompanyProfile) {
        const payload = {
          title: values.title,
          margin: values.margin,
          factor: values.factor,
          a2p: {},
        };

        createMyCompany(payload)
          .then((r) => {
            console.log(r);

            setState((pS) => ({
              ...pS,
              company: r.company,
              isExistCompanyProfile: true,
              loading: false,
            }));
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const payload = {
          title: values.title,
          margin: values.margin,
          factor: values.factor,
        };

        editMyCompany(payload, props.router.params.id)
          .then((r) => {
            console.log(r);
            setState((pS) => ({
              ...pS,
              company: r.company,
              isExistCompanyProfile: true,
              loading: false,
            }));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  });
  const validation_modalPBA = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer_name:
        state.company?.a2p?.customer_profile_address?.customer_name || "",
      iso_country:
        state.company?.a2p?.customer_profile_address?.iso_country || "USA",
      street: state.company?.a2p?.customer_profile_address?.street || "",
      city: state.company?.a2p?.customer_profile_address?.city || "",
      region: state.company?.a2p?.customer_profile_address?.region || "",
      postal_code:
        state.company?.a2p?.customer_profile_address?.postal_code || "",

      street_secondary:
        state.company?.a2p?.customer_profile_address?.street_secondary || "",
      friendly_name:
        state.company?.a2p?.customer_profile_address?.friendly_name || "",
      emergency_enabled:
        state.company?.a2p?.customer_profile_address?.emergency_enabled || "",
      auto_correct_address:
        state.company?.a2p?.customer_profile_address?.auto_correct_address ||
        "",
    },
    validationSchema: Yup.object({
      customer_name: Yup.string().required("Required"),
      iso_country: Yup.string().required("Required"),
      street: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      region: Yup.object().required("Required"),
      postal_code: Yup.string().required("Required"),

      street_secondary: Yup.string(),
      friendly_name: Yup.string(),
      emergency_enabled: Yup.boolean(),
      auto_correct_address: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit_modalPBA", values);
      // return;
      const payload = { a2p: { customer_profile_address: { ...values } } };
      console.log("payload", payload);

      editMyCompany(payload, props.router.params.id)
        .then((r) => {
          console.log(r);
          setModalPBA({ ...modalPBA, isOpenModal: false });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });
  const validation_business_info = useFormik({
    enableReinitialize: true,
    initialValues: {
      friendlyName:
        state.company?.a2p?.customer_profile_business_information
          ?.friendlyName || "",
      business_name:
        state.company?.a2p?.customer_profile_business_information
          ?.business_name || "",
      business_identity:
        state.company?.a2p?.customer_profile_business_information
          ?.business_identity || A2P_config.business_identities[0].value,
      business_type:
        state.company?.a2p?.customer_profile_business_information
          ?.business_type || "",
      business_industry:
        state.company?.a2p?.customer_profile_business_information
          ?.business_industry || "",
      business_registration_identifier:
        state.company?.a2p?.customer_profile_business_information
          ?.business_registration_identifier ||
        A2P_config.business_registration_identifier[0],
      business_regions_of_operation:
        state.company?.a2p?.customer_profile_business_information
          ?.business_regions_of_operation ||
        A2P_config.business_regions_of_operation[0].value,
      business_registration_number:
        state.company?.a2p?.customer_profile_business_information
          ?.business_registration_number || "",
      website_url:
        state.company?.a2p?.customer_profile_business_information
          ?.website_url || "",
      social_media_profile_urls:
        state.company?.a2p?.customer_profile_business_information
          ?.social_media_profile_urls || "",
    },
    validationSchema: Yup.object({
      business_name: Yup.string().required("Required"),
      // business_identity: Yup.object().required("Required"),
      // business_type: Yup.object().required("Required"),
      // business_industry: Yup.object().required("Required"),
      // business_registration_identifier: Yup.string().required("Required"),
      // business_regions_of_operation: Yup.string().required("Required"),
      business_registration_number: Yup.string().required("Required"),
      website_url: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit_validation_business_info", values);

      const payload = {
        a2p: { customer_profile_business_information: { ...values } },
      };
      console.log("payload", payload);
      // return;
      editMyCompany(payload, props.router.params.id)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });
  const validation_authorized_r1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      business_title:
        state.company?.a2p?.authorized_representative_1?.business_title || "",
      job_position:
        state.company?.a2p?.authorized_representative_1?.job_position || "",
      last_name:
        state.company?.a2p?.authorized_representative_1?.last_name || "",
      phone_number:
        state.company?.a2p?.authorized_representative_1?.phone_number || "",
      first_name:
        state.company?.a2p?.authorized_representative_1?.first_name || "",
      email: state.company?.a2p?.authorized_representative_1?.email || "",
    },
    validationSchema: Yup.object({
      business_title: Yup.string().required("Required"),
      // job_position: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      phone_number: Yup.string().required("Required"),
      first_name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit_modalPBA", values);

      const payload = {
        a2p: {
          authorized_representative_1: {
            ...values,
          },
        },
      };

      if (
        state.company?.a2p &&
        state.company?.a2p.hasOwnProperty("authorized_representative_2")
      ) {
        if (state.company?.a2p.authorized_representative_2 === null) {
          payload.a2p["authorized_representative_2"] = null;
        }
      }

      console.log("payload", payload);
      // return;
      editMyCompany(payload, props.router.params.id)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });
  const validation_authorized_r2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      business_title:
        state.company?.a2p?.authorized_representative_2?.business_title || "",
      job_position:
        state.company?.a2p?.authorized_representative_2?.job_position || "",
      last_name:
        state.company?.a2p?.authorized_representative_2?.last_name || "",
      phone_number:
        state.company?.a2p?.authorized_representative_2?.phone_number || "",
      first_name:
        state.company?.a2p?.authorized_representative_2?.first_name || "",
      email: state.company?.a2p?.authorized_representative_2?.email || "",
    },
    validationSchema: Yup.object({
      business_title: Yup.string().required("Required"),
      // job_position: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      phone_number: Yup.string().required("Required"),
      first_name: Yup.string().required("Required"),
      // email: Yup.object().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmit_modalPBA", values);

      const payload = { a2p: { authorized_representative_2: { ...values } } };
      console.log("payload", payload);

      editMyCompany(payload, props.router.params.id)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const onToggle = () => {
    setModalPBA((pS) => ({ ...pS, isOpenModal: !pS.isOpenModal }));
  };

  const handleApplyAllData = () => {
    const isValid_create_profile = validation_create_profile.isValid;
    const isValid_modalPBA = validation_modalPBA.isValid;
    const isValid_business_info = validation_business_info.isValid;
    const isValid_authorized_r1 = validation_authorized_r1.isValid;
    const isValid_authorized_r2 = validation_authorized_r2.isValid;

    if (
      isValid_create_profile &&
      isValid_modalPBA &&
      isValid_business_info &&
      isValid_authorized_r1
    ) {
      const payload = {
        ...validation_create_profile.values,
        a2p: {
          authorized_representative_2:
            validation_authorized_r2.isValid &&
            validation_authorized_r2.values.business_title
              ? validation_authorized_r2.values
              : null,
          authorized_representative_1: validation_authorized_r1.values,
          customer_profile_business_information:
            validation_business_info.values,
          customer_profile_address: validation_modalPBA.values,
        },
        applied: 1,
      };
      console.log("handleApplyAllData", payload);

      // return;
      editMyCompany(payload, props.router.params.id)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const messages = {
        isValid_create_profile: "",
        isValid_modalPBA: "",
        isValid_business_info: "",
        isValid_authorized_r1: "",
        isValid_authorized_r2: "",
      };

      if (!isValid_create_profile) {
        messages.isValid_create_profile = "Profile Info";
      }
      if (!isValid_modalPBA) {
        messages.isValid_modalPBA = "Physical Business Address";
      }
      if (!isValid_business_info) {
        messages.isValid_business_info = "Business information";
      }
      if (!isValid_authorized_r1) {
        messages.isValid_authorized_r1 = "Authorized Representative #1";
      }
      // if (!isValid_authorized_r2) {
      //   messages.isValid_authorized_r2 = "Authorized Representative #2";
      // }

      let MS_ER = `Check:\n${messages.isValid_create_profile}\n${messages.isValid_modalPBA}\n${messages.isValid_business_info}\n${messages.isValid_authorized_r1}\n${messages.isValid_authorized_r2} all required fields must be filled`;

      setState({
        ...state,
        showAlert: {
          isShow: true,
          message: MS_ER,
          type: "danger",
          icon: "mdi-block-helper",
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div
        className={`page-content ${
          state.company.applied === 1 ? "overlay_block" : ""
        }`}
      >
        <Container fluid>
          <Breadcrumb
            title="A2P Profile"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {state.showAlert.isShow && (
            <Alert
              style={{ zIndex: 1000 }}
              isOpen={state.showAlert.isShow}
              color={state.showAlert.type}
              className="alert-dismissible fade show "
              role="alert"
              toggle={() => {
                setState((pS) => ({
                  ...pS,
                  showAlert: {
                    ...state.showAlert,
                    isShow: !state.showAlert.isShow,
                  },
                }));
              }}
            >
              <i className={`mdi ${state.showAlert.icon} me-2`}></i>
              {state.showAlert.message}
            </Alert>
          )}

          <Card>
            <CardBody>
              <h4 className="card-title">Profile Info</h4>
              <Row className="mb-3 form-group">
                <Col>
                  <Label className="form-label">
                    <span className="red_star">*</span> Title
                  </Label>
                  <Input
                    name="title"
                    className="form-control mb-3"
                    placeholder="*"
                    type="text"
                    onChange={validation_create_profile.handleChange}
                    onBlur={validation_create_profile.handleBlur}
                    value={validation_create_profile.values.title || ""}
                    invalid={
                      validation_create_profile.touched.title &&
                      validation_create_profile.errors.title
                        ? true
                        : false
                    }
                  />
                  {validation_create_profile.touched.title &&
                  validation_create_profile.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation_create_profile.errors.title}
                    </FormFeedback>
                  ) : null}

                  <Button
                    type="button"
                    onClick={() => {
                      validation_create_profile.handleSubmit();
                    }}
                    color="primary"
                  >
                    {state.isExistCompanyProfile
                      ? "Update Profile Info"
                      : "Create New A2P-10DLC Profile"}
                  </Button>
                </Col>
                <Col className="form-group">
                  <Label className="form-label">
                    <span className="red_star">*</span> Margin
                  </Label>
                  <InputMask
                    name="margin"
                    mask={"9.9999"}
                    className="form-control input-color"
                    placeholder="*"
                    onChange={validation_create_profile.handleChange}
                    onBlur={validation_create_profile.handleBlur}
                    value={validation_create_profile.values.margin || ""}
                    // invalid={
                    //   validation_create_profile.touched.margin &&
                    //   validation_create_profile.errors.margin
                    //     ? true
                    //     : false
                    // }
                  />
                  {/* {validation_create_profile.touched.margin &&
                  validation_create_profile.errors.margin ? (
                    <FormFeedback type="invalid">
                      {validation_create_profile.errors.margin}
                    </FormFeedback>
                  ) : null} */}
                </Col>
                <Col className="form-group">
                  <Label className="form-label">
                    <span className="red_star">*</span> Factor
                  </Label>

                  <InputMask
                    mask={"9.9999"}
                    name="factor"
                    className="form-control input-color"
                    placeholder="*"
                    onChange={validation_create_profile.handleChange}
                    onBlur={validation_create_profile.handleBlur}
                    value={validation_create_profile.values.factor || ""}
                    // invalid={
                    //   validation_create_profile.touched.factor &&
                    //   validation_create_profile.errors.factor
                    //     ? true
                    //     : false
                    // }
                  />
                  {/* {validation_create_profile.touched.factor &&
                  validation_create_profile.errors.factor ? (
                    <FormFeedback type="invalid">
                      {validation_create_profile.errors.factor}
                    </FormFeedback>
                  ) : null} */}
                </Col>
              </Row>
            </CardBody>
          </Card>

          {false && (
            <>
              <Card>
                <CardBody>
                  <h4 className="card-title">General information</h4>
                  <Row className="mb-3 form-group">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Legal Business Name
                      </Label>
                      <Input
                        name="business_name"
                        className="form-control"
                        placeholder="*"
                        type="text"
                        onChange={validation_business_info.handleChange}
                        onBlur={validation_business_info.handleBlur}
                        value={
                          validation_business_info.values.business_name || ""
                        }
                        invalid={
                          validation_business_info.touched.business_name &&
                          validation_business_info.errors.business_name
                            ? true
                            : false
                        }
                      />
                      {validation_business_info.touched.business_name &&
                      validation_business_info.errors.business_name ? (
                        <FormFeedback type="invalid">
                          {validation_business_info.errors.business_name}
                        </FormFeedback>
                      ) : null}
                      <Label className="form-label mt-1">
                        Enter the exact legal business name, as registered with
                        the EIN.E.g. Twilio Inc. rather than Twilio
                      </Label>
                      <Button
                        type="button"
                        onClick={() => {
                          setModalPBA({ ...modalPBA, isOpenModal: true });
                        }}
                        color="primary"
                      >
                        Physical business address
                      </Button>
                    </Col>
                    <Col className="form-group">
                      <Label className="form-label">
                        Profile Friendly Name
                      </Label>
                      <Input
                        name="friendlyName"
                        className="form-control"
                        placeholder="*"
                        type="text"
                        onChange={validation_business_info.handleChange}
                        onBlur={validation_business_info.handleBlur}
                        value={
                          validation_business_info.values.friendlyName || ""
                        }
                        invalid={
                          validation_business_info.touched.friendlyName &&
                          validation_business_info.errors.friendlyName
                            ? true
                            : false
                        }
                      />
                      {validation_business_info.touched.friendlyName &&
                      validation_business_info.errors.friendlyName ? (
                        <FormFeedback type="invalid">
                          {validation_business_info.errors.friendlyName}
                        </FormFeedback>
                      ) : null}

                      <FormGroup check className="mt-1">
                        <Input type="checkbox" id="Use legal business name" />
                        <Label check htmlFor="Use legal business name">
                          Use legal business name as profile friendly name
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title">Business information</h4>

                  <Row className="mb-3">
                    <Label className="form-label">
                      <span className="red_star">*</span> Business Identity
                    </Label>
                    <div className="">
                      {A2P_config.business_identities.map((bi) => (
                        <div key={bi.value} className="form-check mb-1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="business_identity"
                            checked={
                              validation_business_info.values
                                .business_identity === bi.value
                            }
                            id={bi.value}
                            onChange={(s) =>
                              validation_business_info.setFieldValue(
                                "business_identity",
                                bi.value
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={bi.value}
                          >
                            {bi.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="mb-3 form-group">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Business Type
                      </Label>
                      <Select
                        options={A2P_config.business_type}
                        onChange={(s) =>
                          validation_business_info.setFieldValue(
                            "business_type",
                            s
                          )
                        }
                        // onBlur={validation_business_info.handleBlur}
                        value={
                          validation_business_info.values.business_type || ""
                        }
                        className="select2-selection"
                        styles={{
                          menuList: (style) => ({
                            ...style,
                            backgroundColor: "white",
                          }),
                        }}
                      />
                    </Col>
                    <Col className="form-group">
                      <Label className="form-label">
                        <span className="red_star">*</span> Business Industry
                      </Label>
                      <Select
                        options={A2P_config.business_industry}
                        onChange={(s) =>
                          validation_business_info.setFieldValue(
                            "business_industry",
                            s
                          )
                        }
                        value={
                          validation_business_info.values.business_industry
                        }
                        className="select2-selection"
                        styles={{
                          menuList: (style) => ({
                            ...style,
                            backgroundColor: "white",
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 form-group">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Business
                        Registration ID Type
                      </Label>
                      <Select
                        options={A2P_config.business_registration_identifier}
                        onChange={(s) =>
                          validation_business_info.setFieldValue(
                            "business_registration_identifier",
                            s
                          )
                        }
                        value={
                          validation_business_info.values
                            .business_registration_identifier || ""
                        }
                        className="select2-selection"
                        styles={{
                          menuList: (style) => ({
                            ...style,
                            backgroundColor: "white",
                          }),
                        }}
                      />
                    </Col>
                    <Col className="form-group">
                      <Label className="form-label">
                        <span className="red_star">*</span> Business
                        Registration Number
                      </Label>

                      <InputMask
                        disabled={
                          !validation_business_info.values
                            .business_registration_identifier?.mask
                        }
                        mask={
                          validation_business_info.values
                            .business_registration_identifier?.mask ||
                          "99-9999999"
                        }
                        name="business_registration_number"
                        value={
                          validation_business_info.values
                            .business_registration_number || ""
                        }
                        className="form-control input-color"
                        onChange={validation_business_info.handleChange}
                      ></InputMask>
                      <Label className="form-label">
                        Enter the EIN Tax ID as it appears in the EIN listing
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-3 form-group">
                    <Label className="form-label">
                      <span className="red_star">*</span> Business Regions of
                      Operations
                    </Label>
                    <div className="">
                      {A2P_config.business_regions_of_operation.map((d) => (
                        <div key={d.label} className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="business_regions_of_operation"
                            checked={
                              validation_business_info.values
                                .business_regions_of_operation === d.value
                            }
                            id={d.value}
                            onChange={(s) =>
                              validation_business_info.setFieldValue(
                                "business_regions_of_operation",
                                d.value
                              )
                            }
                          />
                          <label className="form-check-label" htmlFor={d.value}>
                            {d.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="mb-3 form-group">
                    <Col className="form-group" xs={7}>
                      <Label className="form-label">
                        <span className="red_star">*</span> Website Url
                      </Label>
                      <Input
                        name="website_url"
                        // value={name}
                        className="form-control"
                        placeholder="https://"
                        type="text"
                        onChange={validation_business_info.handleChange}
                        onBlur={validation_business_info.handleBlur}
                        value={
                          validation_business_info.values.website_url || ""
                        }
                        invalid={
                          validation_business_info.touched.website_url &&
                          validation_business_info.errors.website_url
                            ? true
                            : false
                        }
                      />
                      {validation_business_info.touched.website_url &&
                      validation_business_info.errors.website_url ? (
                        <FormFeedback type="invalid">
                          {validation_business_info.errors.website_url}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-3 form-group">
                    <Col className="form-group" xs={7}>
                      <Label className="form-label">
                        Social Media Profile URL
                      </Label>
                      <Input
                        name="social_media_profile_urls"
                        className="form-control"
                        placeholder="i.e. LinkedIn, Facebook, Twitter"
                        type="text"
                        onChange={validation_business_info.handleChange}
                        onBlur={validation_business_info.handleBlur}
                        value={
                          validation_business_info.values
                            .social_media_profile_urls || ""
                        }
                      />
                    </Col>
                  </Row>

                  <Button
                    type="button"
                    onClick={() => {
                      validation_business_info.handleSubmit();
                    }}
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    Save
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title">People To Contact</h4>
                  <p className="">
                    We will contact your authorized representatives to verify
                    your identities. Please unsure that they are contactable via
                    email and phone
                  </p>

                  <h4 className="card-title">Authorized Representative #1</h4>

                  <Row className="mb-3">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Last Name
                      </Label>
                      <Input
                        name="last_name"
                        // value={name}
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation_authorized_r1.handleChange}
                        onBlur={validation_authorized_r1.handleBlur}
                        value={validation_authorized_r1.values.last_name || ""}
                        invalid={
                          validation_authorized_r1.touched.last_name &&
                          validation_authorized_r1.errors.last_name
                            ? true
                            : false
                        }
                      />
                      {validation_authorized_r1.touched.last_name &&
                      validation_authorized_r1.errors.last_name ? (
                        <FormFeedback type="invalid">
                          {validation_authorized_r1.errors.last_name}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Email
                      </Label>
                      <Input
                        name="email"
                        // value={name}
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation_authorized_r1.handleChange}
                        onBlur={validation_authorized_r1.handleBlur}
                        value={validation_authorized_r1.values.email || ""}
                        invalid={
                          validation_authorized_r1.touched.email &&
                          validation_authorized_r1.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation_authorized_r1.touched.email &&
                      validation_authorized_r1.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation_authorized_r1.errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3 form-group">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> First Name
                      </Label>
                      <Input
                        name="first_name"
                        // value={name}
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation_authorized_r1.handleChange}
                        onBlur={validation_authorized_r1.handleBlur}
                        value={validation_authorized_r1.values.first_name || ""}
                        invalid={
                          validation_authorized_r1.touched.first_name &&
                          validation_authorized_r1.errors.first_name
                            ? true
                            : false
                        }
                      />
                      {validation_authorized_r1.touched.first_name &&
                      validation_authorized_r1.errors.first_name ? (
                        <FormFeedback type="invalid">
                          {validation_authorized_r1.errors.first_name}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Business Title
                      </Label>
                      <Input
                        name="business_title"
                        // value={name}
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation_authorized_r1.handleChange}
                        onBlur={validation_authorized_r1.handleBlur}
                        value={
                          validation_authorized_r1.values.business_title || ""
                        }
                        invalid={
                          validation_authorized_r1.touched.business_title &&
                          validation_authorized_r1.errors.business_title
                            ? true
                            : false
                        }
                      />
                      {validation_authorized_r1.touched.business_title &&
                      validation_authorized_r1.errors.business_title ? (
                        <FormFeedback type="invalid">
                          {validation_authorized_r1.errors.business_title}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3 form-group">
                    <Col xs={6}>
                      <Label className="form-label">
                        <span className="red_star">*</span> Job Position
                      </Label>
                      <Select
                        name="job_position"
                        options={A2P_config.job_position}
                        className="select2-selection"
                        onChange={(s) =>
                          validation_authorized_r1.setFieldValue(
                            "job_position",
                            s
                          )
                        }
                        value={
                          validation_authorized_r1.values.job_position || ""
                        }
                        styles={{
                          menuList: (style) => ({
                            ...style,
                            backgroundColor: "white",
                          }),
                        }}
                      />
                    </Col>
                    <Col className="form-group mb-3" xs={6}>
                      <Label className="form-label">
                        <span className="red_star">*</span> Phone Number
                      </Label>
                      <InputMask
                        mask="+1 (999) 999-9999"
                        name="phone_number"
                        // value={name}
                        className="form-control"
                        placeholder=""
                        type="text"
                        onChange={validation_authorized_r1.handleChange}
                        onBlur={validation_authorized_r1.handleBlur}
                        value={
                          validation_authorized_r1.values.phone_number || ""
                        }
                        // invalid={
                        //   validation_authorized_r1.touched.phone_number &&
                        //   validation_authorized_r1.errors.phone_number
                        //     ? true
                        //     : false
                        // }
                      />
                      {/* {validation_authorized_r1.touched.phone_number &&
                      validation_authorized_r1.errors.phone_number ? (
                        <FormFeedback type="invalid">
                          {validation_authorized_r1.errors.phone_number}
                        </FormFeedback>
                      ) : null} */}
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        onClick={() => {
                          validation_authorized_r1.handleSubmit();
                        }}
                        color="primary"
                        style={{ marginRight: 20 }}
                      >
                        Save
                      </Button>

                      <FormGroup check className="mt-1">
                        <Input
                          type="checkbox"
                          id="I do not have a second authorized representative"
                          onChange={(e) => {
                            const checked = e.target.checked;

                            setState((pS) => ({
                              ...pS,
                              company: {
                                ...pS.company,
                                a2p: {
                                  ...pS.company.a2p,
                                  authorized_representative_2: checked
                                    ? null
                                    : {},
                                },
                              },
                            }));
                          }}
                        />
                        <Label
                          check
                          htmlFor="I do not have a second authorized representative"
                        >
                          I do not have a second authorized representative
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {state.company?.a2p?.authorized_representative_2 && (
                    <div>
                      <h4 className="card-title">
                        Authorized Representative #2
                      </h4>

                      <Row className="mb-3">
                        <Col>
                          <Label className="form-label">
                            <span className="red_star">*</span> Last Name
                          </Label>
                          <Input
                            name="last_name"
                            // value={name}
                            className="form-control"
                            placeholder=""
                            type="text"
                            onChange={validation_authorized_r2.handleChange}
                            onBlur={validation_authorized_r2.handleBlur}
                            value={
                              validation_authorized_r2.values.last_name || ""
                            }
                            invalid={
                              validation_authorized_r2.touched.last_name &&
                              validation_authorized_r2.errors.last_name
                                ? true
                                : false
                            }
                          />
                          {validation_authorized_r2.touched.last_name &&
                          validation_authorized_r2.errors.last_name ? (
                            <FormFeedback type="invalid">
                              {validation_authorized_r2.errors.last_name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Label className="form-label">
                            <span className="red_star">*</span> Email
                          </Label>
                          <Input
                            name="email"
                            // value={name}
                            className="form-control"
                            placeholder=""
                            type="text"
                            onChange={validation_authorized_r2.handleChange}
                            onBlur={validation_authorized_r2.handleBlur}
                            value={validation_authorized_r2.values.email || ""}
                            invalid={
                              validation_authorized_r2.touched.email &&
                              validation_authorized_r2.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation_authorized_r2.touched.email &&
                          validation_authorized_r2.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation_authorized_r2.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3 form-group">
                        <Col>
                          <Label className="form-label">
                            <span className="red_star">*</span> First Name
                          </Label>
                          <Input
                            name="first_name"
                            // value={name}
                            className="form-control"
                            placeholder=""
                            type="text"
                            onChange={validation_authorized_r2.handleChange}
                            onBlur={validation_authorized_r2.handleBlur}
                            value={
                              validation_authorized_r2.values.first_name || ""
                            }
                            invalid={
                              validation_authorized_r2.touched.first_name &&
                              validation_authorized_r2.errors.first_name
                                ? true
                                : false
                            }
                          />
                          {validation_authorized_r2.touched.first_name &&
                          validation_authorized_r2.errors.first_name ? (
                            <FormFeedback type="invalid">
                              {validation_authorized_r2.errors.first_name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Label className="form-label">
                            <span className="red_star">*</span> Business Title
                          </Label>
                          <Input
                            name="business_title"
                            // value={name}
                            className="form-control"
                            placeholder=""
                            type="text"
                            onChange={validation_authorized_r2.handleChange}
                            onBlur={validation_authorized_r2.handleBlur}
                            value={
                              validation_authorized_r2.values.business_title ||
                              ""
                            }
                            invalid={
                              validation_authorized_r2.touched.business_title &&
                              validation_authorized_r2.errors.business_title
                                ? true
                                : false
                            }
                          />
                          {validation_authorized_r2.touched.business_title &&
                          validation_authorized_r2.errors.business_title ? (
                            <FormFeedback type="invalid">
                              {validation_authorized_r2.errors.business_title}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-3 form-group">
                        <Col xs={6}>
                          <Label className="form-label">
                            <span className="red_star">*</span> Job Position
                          </Label>
                          <Select
                            name="job_position"
                            options={A2P_config.job_position}
                            className="select2-selection"
                            onChange={(s) =>
                              validation_authorized_r2.setFieldValue(
                                "job_position",
                                s
                              )
                            }
                            value={
                              validation_authorized_r2.values.job_position || ""
                            }
                            styles={{
                              menuList: (style) => ({
                                ...style,
                                backgroundColor: "white",
                              }),
                            }}
                          />
                        </Col>
                        <Col className="form-group mb-3" xs={6}>
                          <Label className="form-label">
                            <span className="red_star">*</span> Phone Number
                          </Label>
                          <InputMask
                            mask="+1 (999) 999-9999"
                            name="phone_number"
                            // value={name}
                            className="form-control"
                            placeholder=""
                            type="text"
                            onChange={validation_authorized_r2.handleChange}
                            onBlur={validation_authorized_r2.handleBlur}
                            value={
                              validation_authorized_r2.values.phone_number || ""
                            }
                            // invalid={
                            //   validation_authorized_r2.touched.phone_number &&
                            //   validation_authorized_r2.errors.phone_number
                            //     ? true
                            //     : false
                            // }
                          />
                          {/* {validation_authorized_r2.touched.phone_number &&
                          validation_authorized_r2.errors.phone_number ? (
                            <FormFeedback type="invalid">
                              {validation_authorized_r2.errors.phone_number}
                            </FormFeedback>
                          ) : null} */}
                        </Col>
                        <Col>
                          <Button
                            type="button"
                            onClick={() => {
                              validation_authorized_r2.handleSubmit();
                            }}
                            color="primary"
                            style={{ marginRight: 20 }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <h4 className="card-title">Notification settings</h4>
                  <p className="">
                    We will use these details to notify recipients about the
                    status of this Business Profile. To reach multiple
                    recipients, consider using a distribution email. You will
                    not be subscribed to marketing emails.
                  </p>
                  <Row className="mb-3 form-group">
                    <Col>
                      <Label className="form-label">
                        <span className="red_star">*</span> Email Address
                      </Label>
                      <Input
                        name="first_name"
                        // value={name}
                        className="form-control"
                        placeholder="*"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                        invalid={
                          validation.touched.first_name &&
                          validation.errors.first_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.first_name &&
                      validation.errors.first_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.first_name}
                        </FormFeedback>
                      ) : null}

                      <Button
                        type="button"
                        onClick={() => {}}
                        color="primary"
                        className="mt-3"
                      >
                        Save
                      </Button>
                    </Col>
                    <Col className="form-group">
                      <Label className="form-label">
                        Status Callback Webhook
                      </Label>
                      <Input
                        name="last_name"
                        // value={name}
                        className="form-control"
                        placeholder="*"
                        type="text"
                        onChange={validation.handleChange}
                        value={validation.values.last_name || ""}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    Terms of Service and Privacy Policy
                  </h4>

                  <Row className="mb-3 form-group">
                    <Col>
                      <FormGroup check className="mt-1">
                        <Input type="checkbox" />
                        <Label check>
                          I declare that the information provided is accurate. I
                          acknowledge that Cool Cloud will be processing the
                          information provided for the purpose of identity
                          verification, and that Cool Cloud reserves the right
                          to retain the Business Profile information after
                          account closure in the case of a traceback from a
                          regulatory authority or equivalent.{" "}
                          <a href="#">Cool Cloud Statement</a>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Button
                    type="button"
                    onClick={handleApplyAllData}
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    Submit for review
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      console.log(props);
                      props.router.navigate(-1);
                    }}
                    outline
                  >
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>

      <Modal isOpen={modalPBA.isOpenModal} toggle={onToggle}>
        <ModalHeader toggle={onToggle} tag="h4">
          Physical Business Address
        </ModalHeader>
        {error && (
          <Alert color={error.type || "danger"}>{error?.message}</Alert>
        )}

        <Alert color="info">
          We currently only accept physical addresses for Business Profiles.P.O.
          box addresses are not accepted
        </Alert>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation_modalPBA.handleSubmit();
            }}
          >
            <Row className="mb-2">
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Customer Name
                </Label>
                <Input
                  name="customer_name"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.customer_name || ""}
                  invalid={
                    validation_modalPBA.touched.customer_name &&
                    validation_modalPBA.errors.customer_name
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.customer_name &&
                validation_modalPBA.errors.customer_name ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.customer_name}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  The full name to associate with the new address
                </p>
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star"></span> Friendly Name (optional)
                </Label>
                <Input
                  name="friendly_name"
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.friendly_name || ""}
                  invalid={
                    validation_modalPBA.touched.friendly_name &&
                    validation_modalPBA.errors.friendly_name
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.friendly_name &&
                validation_modalPBA.errors.friendly_name ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.friendly_name}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  A descriptive string that you create to describe the new
                  address.
                </p>
              </Col>
              <Col xs={12} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Country
                </Label>
                <Input
                  name="iso_country"
                  disabled
                  className="form-control"
                  placeholder="*"
                  type="text"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.iso_country || ""}
                />
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Street Address
                </Label>
                <Input
                  name="street"
                  autoComplete=""
                  type="address"
                  placeholder="*"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.street || ""}
                  invalid={
                    validation_modalPBA.touched.street &&
                    validation_modalPBA.errors.street
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.street &&
                validation_modalPBA.errors.street ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.street}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  Enter the first line of the business address as it appears in
                  the EIN listing
                </p>
              </Col>
              <Col xs={6} className="mb-2">
                <Label className="form-label mb-0">
                  <span className="red_star"></span> Street Address Line 2
                </Label>
                <Input
                  name="street_secondary"
                  autoComplete=""
                  type="address"
                  placeholder="*"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.street_secondary || ""}
                  invalid={
                    validation_modalPBA.touched.street_secondary &&
                    validation_modalPBA.errors.street_secondary
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.street_secondary &&
                validation_modalPBA.errors.street_secondary ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.street_secondary}
                  </FormFeedback>
                ) : null}
                <p className="m-0 font-size-12">
                  If application, enter the second line of the business address
                  as it appears in the EIN listing
                </p>
              </Col>
              <Col xs={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> City
                </Label>
                <Input
                  name="city"
                  autoComplete=""
                  type="text"
                  placeholder="City"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.city || ""}
                  invalid={
                    validation_modalPBA.touched.city &&
                    validation_modalPBA.errors.city
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.city &&
                validation_modalPBA.errors.city ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.city}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xs={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> State
                </Label>
                <Select
                  onChange={(s) =>
                    validation_modalPBA.setFieldValue("region", s)
                  }
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.region || ""}
                  options={USA_states}
                  className="select2-selection"
                  styles={{
                    menuList: (style) => ({
                      ...style,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </Col>
              <Col xs={4}>
                <Label className="form-label mb-0">
                  <span className="red_star">*</span> Postal/Zip Code
                </Label>
                <Input
                  name="postal_code"
                  autoComplete=""
                  maxLength={5}
                  type="number"
                  placeholder="Zip"
                  onChange={validation_modalPBA.handleChange}
                  onBlur={validation_modalPBA.handleBlur}
                  value={validation_modalPBA.values.postal_code || ""}
                  invalid={
                    validation_modalPBA.touched.postal_code &&
                    validation_modalPBA.errors.postal_code
                      ? true
                      : false
                  }
                />
                {validation_modalPBA.touched.postal_code &&
                validation_modalPBA.errors.postal_code ? (
                  <FormFeedback type="invalid">
                    {validation_modalPBA.errors.postal_code}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup check className="mt-1">
                  <Input
                    type="checkbox"
                    id="Whether to enable emergency"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      validation_modalPBA.setFieldValue(
                        "emergency_enabled",
                        checked
                      );
                    }}
                  />
                  <Label check htmlFor="Whether to enable emergency">
                    Whether to enable emergency calling on the new address.
                  </Label>
                </FormGroup>
                <FormGroup check className="mt-1">
                  <Input
                    type="checkbox"
                    id="Whether we should automatically correct the address"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      validation_modalPBA.setFieldValue(
                        "auto_correct_address",
                        checked
                      );
                    }}
                  />
                  <Label
                    check
                    htmlFor="Whether we should automatically correct the address"
                  >
                    Whether we should automatically correct the address. We will
                    correct the address you provide if necessary.
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                {modalPBA.loading ? (
                  <Spinner className="ms-1 mb-3" color="primary" />
                ) : (
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={onToggle}
                      className="btn btn-error save-user"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(A2P_10DLC_Profile);
