import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import InputMask from "react-input-mask";

import {
  Name,
  FirstName,
  LastName,
  Email,
  Tags,
  Projects,
  Phone,
  Img,
  Address,
} from "./contactlistCol";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  addNewCustomerContact,
  updateCustomerContact,
  deleteCustomerContact,
  apiErrorContacts,
} from "store/contacts/actions";
import { forIn, isEmpty } from "lodash";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createImageLink, formatBytes, isUseThisNumber } from "helpers/utility";

import USA_states from "assets/data/USA_states";

const ContactsList = (props) => {
  const activeModalData = useRef({});

  const dispatch = useDispatch();
  const { customers, error, conversations } = useSelector(
    (state) => ({
      customers: state.contacts.customers,
      error: state.contacts.error,
      conversations: state.chat.conversations,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    modal: false,
    deleteModal: false,
    isEdit: false,
    contactActive: {},
    image: { selectedFile: null, value: "", src: "" },
    localError: null,
    state: null,
  });

  const returnStateInit = () => {
    setState({
      modal: false,
      deleteModal: false,
      isEdit: false,
      contactActive: {},
      image: { selectedFile: null, value: "", src: "" },
      localError: null,
      state: null,
    });
    activeModalData.current = {};
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name:
        state.contactActive.first_name ||
        activeModalData.current?.first_name ||
        "",
      last_name:
        state.contactActive.last_name ||
        activeModalData.current?.last_name ||
        "",
      company:
        state.contactActive.company || activeModalData.current?.company || "",
      email: state.contactActive.email || activeModalData.current?.email || "",
      phone: state.contactActive.phone || activeModalData.current?.phone || "",
      address:
        state.contactActive.address || activeModalData.current?.address || "",
      address_2:
        state.contactActive.address_2 ||
        activeModalData.current?.address_2 ||
        "",
      city: state.contactActive.city || activeModalData.current?.city || "",
      // state: state.contactActive.state || "",
      zip: state.contactActive.zip || activeModalData.current?.zip || "",

      image: state.contactActive.image || activeModalData.current?.image || "",
    },
    validationSchema: Yup.object({
      first_name: "",
      last_name: "",
      company: "",
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ),
      zip: Yup.number(),
      phone: "",
      address: "",
    }),
    onSubmit: async (values) => {
      console.log(values, state);

      // return;
      if (state.isEdit) {
        const payload = { ...values };
        payload["state"] = state.state?.value;
        const image = await createImageLink(state.image.selectedFile);
        // console.log("image", image);
        if (image && image.link) {
          payload.image = image.link;
        }

        const id = state.contactActive.id;

        const newFriendlyName = `${values.first_name} ${values.last_name}`;

        ClientConversation.updateConvFriendlyName(
          values.phone,
          newFriendlyName
        );

        dispatch(updateCustomerContact(id, payload));
        returnStateInit();
      } else {
        if (values.phone === "" || values.password === "") {
          console.log("EMPTY phone or password");
          setState((pS) => ({
            ...pS,
            localError: { msg: "Please input Phone Number" },
          }));
          return;
        }
        activeModalData.current = { ...values };
        const payload = { ...values };
        const image = await createImageLink(state.image.selectedFile);
        // console.log("image", image);
        if (image && image.link) {
          payload.image = image.link;
        }
        payload["state"] = state.state?.value;
        // console.log("payload", payload);
        dispatch(addNewCustomerContact(payload));
        // returnStateInit();
      }
      validation.resetForm();
    },
  });

  useEffect(() => {
    if (!error) {
      returnStateInit();
    }
  }, [error, customers]);

  function handleSelectGroup(selectedGroup) {
    setState((pS) => ({ ...pS, state: selectedGroup }));
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      // {
      //   Header: "ID",
      //   accessor: "id",
      //   // filterable: true,
      //   // accessor: (cellProps) => <span>{cellProps.value}</span>,
      //   Cell: (cellProps) => {
      //     return cellProps.value;
      //   },
      // },
      {
        Header: "Avatar",
        accessor: "image",
        disableFilters: true,
        // filterable: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
        Cell: (cellProps) => {
          return <FirstName {...cellProps} />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
        Cell: (cellProps) => {
          return <LastName {...cellProps} />;
        },
      },
      {
        Header: "Company",
        accessor: "company",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        // filterable: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "address",
        // filterable: true,
        Cell: (cellProps) => {
          return <Address {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClicks(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Link
                to="/chat"
                // to="#"
                className="text-primary"
                onClick={() => {
                  const userData = cellProps.row.original;
                  const isUse = isUseThisNumber(conversations, userData.phone);
                  // console.log(userData, "isUse", isUse);

                  if (isUse) {
                    ClientConversation.changeConversation(
                      isUse,
                      userData.conversation_sid ? null : userData.id
                    );
                  } else {
                    ClientConversation.createConversation({
                      phone: userData.phone,
                      first_name: userData.first_name || "",
                      last_name: userData.last_name || "",
                      contactID: userData.id,
                    });
                  }
                }}
              >
                <i className="mdi mdi-send font-size-18" id="go_to_chat" />
                <UncontrolledTooltip placement="top" target="go_to_chat">
                  Go to Chat
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setState((pS) => ({
      ...pS,
      modal: !state.modal,
      isEdit: false,
      contactActive: {},
      localError: null,
    }));
    activeModalData.current = {};
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = (userData) => {
    setState((pS) => ({
      ...pS,
      deleteModal: true,
      contactActive: userData,
    }));
  };

  const handleDeleteUser = () => {
    if (state.contactActive && state.contactActive.id) {
      dispatch(deleteCustomerContact(state.contactActive.id));
      setState((pS) => ({
        ...pS,
        deleteModal: false,
        contactActive: {},
      }));
      activeModalData.current = {};
    }
    onPaginationPageChange(1);
  };

  const handleUserClicks = (userData) => {
    if (userData) {
      console.log(userData);
      setState((pS) => ({
        ...pS,
        modal: true,
        isEdit: true,
        contactActive: userData,
        state: customers.find(({ id }) => id === userData.id),
      }));
      activeModalData.current = userData;
    } else {
      toggle();
    }
  };

  let preview = { src: "", name: "", size: 0 };
  if (state.image.selectedFile) {
    preview.src = URL.createObjectURL(state.image.selectedFile);
    preview.name = state.image.selectedFile.name;
    preview.size = formatBytes(state.image.selectedFile.size);
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={state.deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() =>
          setState((pS) => ({ ...pS, deleteModal: !state.deleteModal }))
        }
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Customers" breadcrumbItems={props?.router?.location?.pathname} />
          {(error || state.localError) && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-block-helper me-2"></i>
              {error?.msg || state.localError?.msg}
            </UncontrolledAlert>
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={true}
                    isAddCustList={true}
                    handleCustomerClick={toggle}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={state.modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!state.isEdit ? "Edit customer" : "Add new customer"}
                    </ModalHeader>
                    {(error || state.localError) && (
                      <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        <i className="mdi mdi-block-helper me-2"></i>
                        {error?.msg || state.localError?.msg}
                      </UncontrolledAlert>
                    )}
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3 d-flex flex-row justify-content-between">
                              <div style={{ width: "49%" }}>
                                <Label className="form-label">First Name</Label>
                                <Input
                                  name="first_name"
                                  type="text"
                                  placeholder="Insert Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ""}
                                  invalid={
                                    validation.touched.first_name &&
                                    validation.errors.first_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.first_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div style={{ width: "49%" }}>
                                <Label className="form-label">Last name</Label>
                                <Input
                                  name="last_name"
                                  label="Last Name"
                                  placeholder="Insert Last Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    validation.touched.last_name &&
                                    validation.errors.last_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.last_name &&
                                validation.errors.last_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.last_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Company</Label>
                              <Input
                                name="company"
                                autoComplete=""
                                type="text"
                                placeholder="Insert company name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.company || ""}
                                invalid={
                                  validation.touched.company &&
                                  validation.errors.company
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.company &&
                              validation.errors.company ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.company}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Phone Number</Label>

                              <InputMask
                                mask="+1 (999) 999-9999"
                                name="phone"
                                value={validation.values.phone || ""}
                                className="form-control input-color"
                                onChange={validation.handleChange}
                              ></InputMask>
                            </div>
                            <div className="mb-3 d-flex flex-row justify-content-between">
                              <div style={{ width: "48%" }}>
                                <Label className="form-label">Address</Label>
                                <Input
                                  name="address"
                                  autoComplete=""
                                  type="address"
                                  placeholder="Insert address"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                    validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div style={{ width: "48%" }}>
                                <Label className="form-label">Address 2</Label>
                                <Input
                                  name="address_2"
                                  autoComplete=""
                                  type="address"
                                  placeholder="Insert address 2"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address_2 || ""}
                                  invalid={
                                    validation.touched.address_2 &&
                                    validation.errors.address_2
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address_2 &&
                                validation.errors.address_2 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address_2}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="mb-3 d-flex flex-row justify-content-between">
                              <div style={{ width: "50%" }}>
                                <Label className="form-label">City</Label>
                                <Input
                                  name="city"
                                  autoComplete=""
                                  type="address"
                                  placeholder="City"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.city || ""}
                                  invalid={
                                    validation.touched.city &&
                                    validation.errors.city
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.city &&
                                validation.errors.city ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.city}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div style={{ width: "30%", marginLeft: 5 }}>
                                <Label className="form-label">State</Label>
                                <Select
                                  value={state.state || ""}
                                  onChange={handleSelectGroup}
                                  options={USA_states}
                                  className="select2-selection"
                                  styles={{
                                    menuList: (style) => ({
                                      ...style,
                                      backgroundColor: "white",
                                    }),
                                  }}
                                />
                              </div>
                              <div style={{ width: "20%", marginLeft: 5 }}>
                                <Label className="form-label">Zip</Label>
                                <Input
                                  name="zip"
                                  autoComplete=""
                                  maxLength={5}
                                  type="number"
                                  placeholder="Zip"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.zip || ""}
                                  invalid={
                                    validation.touched.zip &&
                                    validation.errors.zip
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.zip &&
                                validation.errors.zip ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.zip}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group">
                              <Label for="exampleFile">
                                Add Customer Photo
                              </Label>
                              <Input
                                id="exampleFile"
                                name="image"
                                type="file"
                                onChange={(e) => {
                                  const selectedImage = e.target.files[0];
                                  console.log("selectedImage", selectedImage);
                                  setState((pS) => ({
                                    ...pS,
                                    image: {
                                      ...pS.image,
                                      value: e.target.value,
                                      selectedFile: selectedImage,
                                    },
                                  }));
                                }}
                                value={state.image?.value}
                              />

                              {state.image.selectedFile && (
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="150"
                                          className="avatar-lg rounded bg-light"
                                          alt={preview.name}
                                          src={preview.src}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {preview.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{preview.size}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}
                              {!state.image.selectedFile &&
                                !!state.contactActive?.image && (
                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="150"
                                            className="avatar-lg rounded bg-light"
                                            alt={state.contactActive?.image}
                                            src={state.contactActive?.image}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end mt-3">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
