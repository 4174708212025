import {
  GET_BILLING,
  GET_BILLING_FAIL,
  GET_BILLING_SUCCESS,
  GET_BILLING_DETAIL,
  GET_BILLING_DETAIL_FAIL,
  GET_BILLING_DETAIL_SUCCESS,
} from "./actionTypes"

export const getBillings = () => ({
  type: GET_BILLING,
})

export const getBillingSuccess = invoices => ({
  type: GET_BILLING_SUCCESS,
  payload: invoices,
})

export const getBillingFail = error => ({
  type: GET_BILLING_FAIL,
  payload: error,
})

export const getBillingDetail = invoiceId => ({
  type: GET_BILLING_DETAIL,
  invoiceId,
})

export const getBillingDetailSuccess = invoices => ({
  type: GET_BILLING_DETAIL_FAIL,
  payload: invoices,
})

export const getBillingDetailFail = error => ({
  type: GET_BILLING_DETAIL_SUCCESS,
  payload: error,
})
