import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import withRouter from "components/Common/withRouter";

import { getMyAIdata, changeOne } from "store/actions";

import { INIT_MY_AI_DATA } from "config";

import Breadcrumb from "../../components/Common/Breadcrumb";

import { optionsChart } from "helpers/chartHelper";

const MyAIchart = (props) => {
  const dispatch = useDispatch();
  const myRef = useRef();
  const timerRef = useRef();

  const [state, setState] = useState({
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 0,
        },
      ],
      labels: [],
    },
  });

  useEffect(() => {
    setState((pS) => {
      const newDat = { ...pS };
      newDat.data.datasets[0].data = [];
      newDat.data.datasets[0].backgroundColor = [];
      newDat.data.labels = [];

      INIT_MY_AI_DATA.forEach((element) => {
        newDat.data.datasets[0].data.push(element.value);
        newDat.data.datasets[0].backgroundColor.push(element.color);
        newDat.data.labels.push(element.label);
      });
      return newDat;
    });

    timerRef.current = setInterval(periodicCheckData, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const periodicCheckData = () => {
    if (myRef.current) {
      const updateConfig = {
        duration: 1000, // Animation duration for the update
        easing: "easeInOutQuad", // Easing function for the animation
      };

      const randomIndex = parseInt(Math.random() * 8);

      const randomValue = myRef.current.data.datasets[0].data[randomIndex] + 25;

      if (randomValue > 100) {
        return;
      }

      dispatch(changeOne(randomIndex, randomValue));
      myRef.current.data.datasets[0].data[randomIndex] = randomValue;

      // Update the chart with the specified animation configuration
      myRef.current.update({ duration: 0 }); // Disable animation for the entire chart
      myRef.current.update(updateConfig); // Re-enable animation only for the specified dataset
      // Reset the global animation configuration
      myRef.current.update({
        duration: myRef.current.options.animation.duration,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb title="My AI" breadcrumbItems={props?.router?.location?.pathname} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <PolarArea
                    ref={myRef}
                    width={800}
                    height={500}
                    data={state.data}
                    options={optionsChart()}
                    redraw={true}
                    updateMode="resize"
                    plugins={[ChartDataLabels]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MyAIchart);