import React from "react";
import {
  Modal,
  Alert,
  ModalBody,
  ModalHeader,
  Form,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { voiceCreate } from "./API_hendlers";

class Record_API extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      init: false,
      canRecordNew: true,
      loading: false,
    };

    this.audioCtx = null;
    this.soundCLipsRef = React.createRef();
    this.audioRef = React.createRef();
    this.canvasRef = React.createRef();
    this.canvasCtx = null;

    this.start = this.start.bind(this);
    this.submit = this.submit.bind(this);

    this.handleStartRecord = this.handleStartRecord.bind(this);
    this.handleStopRecord = this.handleStopRecord.bind(this);

    this.visualize = this.visualize.bind(this);
    // this.draw = this.draw.bind(this);
  }

  componentWillUnmount() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  componentDidMount() {
    this.canvasCtx = this.canvasRef.current.getContext("2d");
  }

  componentDidUpdate(prevProp, prevState) {
    // console.log(prevProp, prevState);
    if (this.props.isOpenRecordModal && !this.state.init) {
      this.setState((ps) => ({ ...ps, init: true }));
      this.start();
    }
  }

  handleStartRecord() {
    this.mediaRecorder.start();
  }
  handleStopRecord() {
    this.mediaRecorder.stop();
  }

  start() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.stream = stream;
          this.visualize(stream);
          let chunks = [];
          this.mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
          };
          this.mediaRecorder.onstart = () => {
            this.setState((ps) => ({ ...ps, isRecording: true }));
          };
          this.mediaRecorder.onstop = (e) => {
            console.log(
              "Last data to read (after MediaRecorder.stop() called)."
            );

            this.soundCLipsRef.current.classList.remove("hidden");

            this.blob = new Blob(chunks, {
              type: this.mediaRecorder.mimeType,
            });
            chunks = [];
            const audioURL = window.URL.createObjectURL(this.blob);
            this.audioRef.current.src = audioURL;
            console.log("recorder stopped");

            this.setState((ps) => ({
              ...ps,
              isRecording: false,
              canRecordNew: false,
            }));
          };
        })
        .catch((err) => {
          this.props.onError(
            `The following getUserMedia error occurred: ${err}`
          );
        });
    } else {
      this.props.onError(
        "mediaDevices API or getUserMedia method is not supported in this browser."
      );
    }
  }

  submit() {
    console.log("SUBMIT AUDIO");
    if (!this.blob) {
      return;
    }

    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", this.blob, "myVoiceExample.mpga");

    voiceCreate(formData)
      .then((r) => {
        console.log(r);
        this.props.onAudioSubmit();

      })
      .catch((e) => {
        console.log(e);
        this.props.onError(`Error on Submit Voice audio: ${e?.message()}`);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  visualize(stream) {
    if (!this.audioCtx) {
      this.audioCtx = new AudioContext();
    }

    const source = this.audioCtx.createMediaStreamSource(stream);

    const analyser = this.audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    const canvas = this.canvasRef.current;
    const canvasCtx = this.canvasCtx;

    draw();

    function draw() {
      if (!canvas || !canvasCtx) {
        return;
      }
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = "rgb(200, 200, 200)";
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.lineWidth = 1;
      canvasCtx.strokeStyle = "rgb(0, 0, 0)";

      canvasCtx.beginPath();

      let sliceWidth = (WIDTH * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * HEIGHT) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    }
  }

  render() {
    return (
      <div
        className={`wrapper${this.props.isOpenRecordModal ? "" : " hidden"}`}
      >
        <section className="main-controls">
          <p className="fs-6">Press "RECORD" and read text above</p>
          <canvas
            ref={this.canvasRef}
            className="visualizer"
            height="60px"
            width="100px"
          ></canvas>
          <div className="float-end">
            {this.state.isRecording ? (
              <button
                type="button"
                className="btn btn-dark "
                onClick={this.handleStopRecord}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-1"></i>{" "}
                Stop
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger "
                onClick={this.handleStartRecord}
                disabled={!this.state.canRecordNew}
              >
                <i className="bx bx-stop font-size-16 align-middle me-1"></i>{" "}
                Record
              </button>
            )}
          </div>
        </section>

        <section className="sound-clips hidden" ref={this.soundCLipsRef}>
          <article className="clip">
            <audio ref={this.audioRef} controls="true" src=""></audio>
            <p>My unnamed clip</p>
            <div className="float-end">
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.soundCLipsRef.current.classList.add("hidden");
                  this.setState((ps) => ({
                    ...ps,
                    canRecordNew: true,
                  }));
                }}
              >
                Delete
              </button>
              <button
                className="btn btn-primary"
                disabled={this.state.loading}
                onClick={this.submit}
                style={{ marginLeft: 20 }}
              >
                {this.state.loading ? (
                  <Spinner
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

export default Record_API;
