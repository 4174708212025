import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  GET_CUSTOMERS_CONTACT,
  ADD_NEW_CUSTOMERS_CONTACT,
  GET_CUSTOMERS_CONTACT_PROFILE,
  UPDATE_CUSTOMERS_CONTACT,
  DELETE_CUSTOMERS_CONTACT,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUserProfileSuccess,
  addUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  getCustomersContactSuccess,
  addNewCustomerContactSuccess,
  getCustomerContactProfileSuccess,
  updateCustomerContactSuccess,
  deleteCustomerContactSuccess,
  apiErrorContacts,
} from "./actions";

import {
  createUser,
  getUsers,
  getUserById,
  modifyUserById,
  deleteUserById,
  getCustomersContact,
  addNewCustomerContact,
  getCustomerContactProfile,
  updateCustomerContact,
  deleteCustomerContact,
} from "helpers/API_hendlers";

function* fetchUsers() {
  console.log("fetchUsers")
  try {
    const response = yield call(getUsers);
    console.log(response)
    yield put(getUsersSuccess(response.users));
  } catch (error) {
    console.log(error)
    yield put(apiErrorContacts(error));
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserById);
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const id = user.id;
    const data = { ...user };
    delete data.id;
    const response = yield call(modifyUserById, id, data);
    if (response.error) {
      yield put(apiErrorContacts(response.error));
      return;
    }
    yield put(updateUserSuccess(response.user));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* onDeleteUser({ payload }) {
  try {
    const response = yield call(deleteUserById, payload);
    yield put(deleteUserSuccess(payload));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(createUser, user);

    yield put(addUserSuccess(response.user));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}
//============================================
function* addNewCustomerContact_saga({ payload }) {
  try {
    const response = yield call(addNewCustomerContact, payload);
    yield put(addNewCustomerContactSuccess(response.contact));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}
function* getCustomersContact_saga() {
  try {
    const response = yield call(getCustomersContact);
    yield put(getCustomersContactSuccess(response.contacts));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* getCustomerContactProfile_saga() {
  try {
    const response = yield call(getCustomerContactProfile);
    yield put(getCustomerContactProfileSuccess(response));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* updateCustomerContact_saga({ payload: { id, newData } }) {
  try {
    const response = yield call(updateCustomerContact, id, newData);

    console.log("updateCustomerContact_saga", response);

    yield put(updateCustomerContactSuccess(response.contact));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* deleteCustomerContact_saga({ payload }) {
  try {
    const response = yield call(deleteCustomerContact, payload);
    yield put(deleteCustomerContactSuccess(payload));
  } catch (error) {
    yield put(apiErrorContacts(error));
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(DELETE_USER, onDeleteUser);

  yield takeEvery(GET_CUSTOMERS_CONTACT, getCustomersContact_saga);
  yield takeEvery(
    GET_CUSTOMERS_CONTACT_PROFILE,
    getCustomerContactProfile_saga
  );
  yield takeEvery(ADD_NEW_CUSTOMERS_CONTACT, addNewCustomerContact_saga);
  yield takeEvery(UPDATE_CUSTOMERS_CONTACT, updateCustomerContact_saga);
  yield takeEvery(DELETE_CUSTOMERS_CONTACT, deleteCustomerContact_saga);
}

export default contactsSaga;
